// Index
.main-wrapper {
  &.ownership {
    display: grid;
    grid-template-rows: auto auto 1fr;
    padding: 0;
    @media #{$md-and-up} {
      overflow: hidden;
      padding: $sizeThirty $sizeSixteen $sizeSixteen;
    }

    #tree {
      transition: opacity 300ms ease-in-out;
      opacity: 0;
    }


    .nodeOwnership {
      background-color: $greyLight;
      border-radius: $sizeThirtyTwo;
      color: $greyDark;
      cursor: pointer;
      font-size: $sizeFourteen;
      text-align: center;
      width: rem-calc(175);
      height: $sizeSixtyFour;
      display: flex;
      align-items: center;
      position: absolute;
      padding-right: $sizeThirty;
      padding-left: $sizeThirty;

      &:before {
        content: "";
        display: block;
        border-radius: $sizeEight;
        width: $sizeSixteen;
        height: $sizeSixteen;
        position: absolute;
        top: -$sizeEight;
        left: 50%;
        transform: translateX(-$sizeEight);
      }
      &.user {
        &:before {
          background-color: $blueMedium;
          top: initial;
          bottom: -$sizeEight;
        }
        color: $blueMedium;
        font-family: $fontAvertaBold;
      }
      &.beneficiary_accounts {
        &:before {
          background-color: $greyDarkMedium;
        }
      }
      &.investment_accounts {
        &:before {
          background-color: $orange;
        }
      }
      &.trusts {
        &:before {
          background-color: $blueLighter;
        }
      }
      &.corporations {
        &:before {
          background-color: $red;
        }
      }
      &.special {
        border: 1px solid $green;
        &:before {
          background-color: $green;
        }
      }
      &.special_investment {
        border: 1px solid #e08fff;
        &:before {
          background-color: #e08fff;
        }
      }
      &.secondary {
        &:before {
          background-color: $green;
        }
      }
      &.collapsed {
        .chevron {
          transform: rotate(0deg) translate(-50%, -50%);
        }
      }

      a {
        text-decoration: underline;
        color: $greyDark;
        cursor: pointer;
      }
      .chevron {
        transition: all 300ms ease-out;
        transform: translate(-50%, -50%) rotate(180deg);
        width: $sizeThirtySix;
        height: $sizeThirtySix;
        position: absolute;
        top: 50%;
        left: 50%;
        &-container {
          width: $sizeTwentyTwo;
          height: 100%;
          position: absolute;
          top: 0;
          right: $sizeEight;
        }
        #Rectangle-3 {
          fill: $blueLight;
        }
        #Rectangle-2 {
          fill: none;
        }
      }
      &__text {
        max-height: $sizeFourty;
        overflow: hidden;
        width: 100%;
      }
      .edit {
        position: absolute;
        top: -$sizeSix;
        right: $sizeTwentyTwo;
        z-index: 1;
        display: none;
      }
    }
    .tree {
      position: relative;
      overflow: hidden;
      padding: 0 !important;
      width: 100%;
      height: auto !important;
      min-height: 50vh !important;
      // max-height: rem-calc(500) !important;

      > svg {
        path {
          stroke: $greyDarkMedium;
          stroke-width: 2;
        }
      }

      .collapse-switch {
        width: 100%;
        height: 100%;
        border: none;
        position: absolute;
        top: rem-calc(1);
        right: rem-calc(1);
        cursor: none;
        display: none;
      }
      .node.collapsed {
        .collapse-switch {
          background: none;
        }
      }
    }
    .treeDetail {
      position: relative;
      padding: 0 !important;
      width: 100%;
      height: 100%;
      overflow-x: inherit !important;
      transform: scale(0.55);

      > svg {
        path {
          stroke: $greyDarkMedium;
          stroke-width: 2;
        }
      }

      .collapse-switch {
        width: 100%;
        height: 100%;
        border: none;
        position: absolute;
        top: rem-calc(1);
        right: rem-calc(1);
        cursor: pointer;
      }
      .node.collapsed {
        .collapse-switch {
          background: none;
        }
      }
      .nodeOwnership {
        width: rem-calc(100);
        height: $sizeSixtyFour;
        cursor: pointer;

        &.selected {
          border: rem-calc(2) solid $blueLight;
        }
        &:not(.selected) {
          > * {
            opacity: 0.5;
          }
          .truncate {
            opacity: 1;
          }
          &:hover {
            opacity: 1;
          }
        }
        &.user {
          .chevron {
            display: none;
          }
        }

        &:hover {
          .truncate {
            border: rem-calc(2) solid $blueLight;
            border-radius: $sizeThirtyTwo;
            overflow: hidden;
            position: absolute;
            width: rem-calc(145);
            height: $sizeSixtyFour;
            background-color: $greyLighter;
            font-size: $sizeEighteen;
            display: flex;
            align-items: center;
            justify-content: center;
            top: -1;
            opacity: 1;
            padding: $sizeFive;
          }
        }
        .edit {
          right: 0;
        }
        .show {
          display: none;
        }

        .truncate {
          transition: all 150ms ease-in-out;
          white-space: initial;
          z-index: 9999999999;
          cursor: pointer;
        }
      }
    }
  }

  &.ownership-show {
    .wrapper-content-block {
      margin-bottom: $sizeTwentyFour;
      .content {
        padding: $sizeTwenty;

        .mediaBlock-wrapper {
          cursor: pointer;
        }
      }
    }
  }
}
.ownership {
  &.ownership-index {
    .wrapper-pieGraphic-preLegend {
      padding-bottom: $sizeTwentyFour;
      flex-wrap: nowrap;
      margin-top: $sizeTwentyFour;
    }

    .alone-item {
      padding: 0 $sizeEight;
    }
  }
}
// @Index

// Show
.super-wrapper-ownership-show {
  display: flex;
  width: 100%;
  flex-direction: column;

  .container-ownership-show {
    display: flex;
  }

  .hashListingBlock-wrapper {
    // width: 30%;

    .title-main {
      margin-bottom: $sizeTwenty;
    }
  }

  .main-wrapper {
    &.detail.ownership {
      width: 75%;
      padding: $sizeSixteen $sizeSixteen 0 0;
    }
  }
}
// @Show

// .ownership_structure {
//   .block__box-item {
//     padding-bottom: 0;
//   }
// }
