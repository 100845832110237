.contact-form {
  height: rem-calc(500);
  padding: 0 $sizeTwentyFour;
  height: 100%;
  margin-bottom: $sizeFourty;

  &__item{
    display: flex;
    margin-bottom: $sizeFourty;
    margin-top: $sizeTwenty;
    padding-right: $sizeTwentyFour;
    padding-left: $sizeTwentyFour;

    &-image {
      width: 30%;
      display: flex;
      //justify-content: center;
    }

    &-data {
      width: 100%;
      input {
        height: $sizeThirty;
      }

      &-title {
        margin-bottom: $sizeFourteen;
        font-size: $sizeSixteen;
        color: $blueDark;
        font-family: $fontAvertaSemiBold; 
      }
    }
  }

  &__wrapper {
    background-color: #f5f5f7; 
    border-radius: $sizeTwentyFour; 
    padding: $sizeTen 0;
    margin-bottom: $sizeFourty;

    &-label {
      margin-left: $sizeTwenty;
      margin-top: $sizeTwenty;
    }
  }

  &__btn {
    display: flex;
    max-width: 50%;
    margin: auto;
  }
}