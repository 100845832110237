.wrapper-pieGraphic {
  padding: $sizeSixteen;
  margin-top: $sizeFourty;
}

.container-pieGraphic {

  &.min-height {
    min-height: rem-calc(390);
  }
}

.wrapper-svg-pieGraphic {
  display: flex;
  justify-content: center;
  width: 100%;
  margin: 0 auto;
  cursor: pointer;
  position: relative;
  top: -$sizeTwenty;

  @media #{$lg-and-up} {
   // width: 80%;
  }
}

.wrapper-pieGraphic-preLegend {
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto;
  width: 100%;
  justify-content: center;

  @media #{$lg-and-up} {
    width: 80%;
  }
}

.legend-item-color {
  min-width: $sizeTen;
  height: $sizeTen;
  margin-right: $sizeEight;
  border-radius: 100%;
}

.alone-item {
  width: 40%;
  padding: $sizeFour 0;
  display: flex;
  align-items: center;

  @media #{$lg-and-up} {
    width: 36%;
  }
}

.wrapper-pieGraphic-legend {
  padding-top: $sizeFourtyEight;
}

.wrapper-pieGraphic-legend-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: rem-calc(1) dashed $greyLight;
  width: calc(100% + 32px);
  left: -$sizeSixteen;
  position: relative;
  padding: 0 $sizeSixteen;

  .title2 {
    font-size: $sizeSixteen;
    max-width: rem-calc(100);
    min-height: $sizeTwenty;

    @media #{$md-and-up} {
      max-width: rem-calc(250);
    }
  }
}

.legend-text {
  width: 100%;
  padding: $sizeTen 0;
}

.wrapper-main-legend-text {
  width: 100%;
  display: flex;

  .wrapper-icon  {
      cursor: pointer;
  }
}

.main-legend-text,
.wrapper-main-legend-list {
  width: 100%;
  justify-content: space-between;
}

.main-legend-text {
  display: flex;
  padding-left: $sizeFour;
  padding-right: $sizeSixteen;
}

.wrapper-main-legend-list {
  padding-left: $sizeFourtyEight;
  padding-right: $sizeSixteen;
}

.subcategories-wrapper,
.quantities-wrapper {
  display: flex;
  flex-direction: column;
}

.subcategories-wrapper {
  max-width: rem-calc(140);
}

.quantities-wrapper {
  align-items: flex-end;
}
