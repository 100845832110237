/* ==========================================================================
#COLOR
========================================================================== */

html {
  body {
    .o-color {
      &--blue-dark {
        color: $blueDark;
      }
      &--orange {
        color: $orange;
      }
      &--grey-dark {
        color: $greyDark;
      }
    }
  }
}
