.detailTypeOne-header {
  padding-top: $sizeSixteen;
  padding-bottom: $sizeSixteen;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.detailTypeOne-content {
  display: flex;
  justify-content: space-between;
  max-width: 100%;
}

.detailTypeOne-wrapper-map {
  width: 100%;
}

.detailTypeOne-wrapper-nestedList {
  overflow: hidden auto;
  margin: $sizeTwentyFour auto 0px;
  max-width: 90%;
  min-height: rem-calc(400);
}


.one-column {
  width: 100%;
  display: flex;
  justify-content: space-between;
  &-left{
    width: 49%;
  }
  &-right{
    width: 49%;
  }
}

.two-columns {
    width: 48%;

  &.center {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .wrapper-slider {
    margin-top: $sizeTwentyTwo;
  }

}

.one-column {
  width: 100%;
}
