$widthCellAlternatives: rem-calc(182);
$widthFirstCellAlternatives: rem-calc(300);

%cell-style-alternatives {
  min-width: $widthCellAlternatives;
  width: $widthCellAlternatives;
  min-height: $sizeFourtyEight;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: $fontAvertaSemiBold;
  font-size: $sizeFourteen;
  padding: $sizeFour $sizeEight;
  position: relative;
}

.c-table-alternatives {
  transition: all 500ms ease-in-out;
  opacity: 0;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  $b: &;

  &__head {
    position: relative;
    &-row {
      display: flex;
      border-top-left-radius: $sizeEight;
      border-top-right-radius: $sizeEight;
      background-color: $blueDark;
    }
    &-cell {
      @extend %cell-style-alternatives;
      color: $white;

      &:first-child {
        background-color: $blueDark;
        min-width: $widthFirstCellAlternatives;
        width: $widthFirstCellAlternatives;
        position: sticky;
        left: 0;
        z-index: 2;
        border-top-left-radius: $sizeEight;
        border-top-right-radius: $sizeEight;
      }

      &--tag {
        align-items: flex-end;
      }
    }
  }

  &__tag {
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    color: $white;
    width: $widthCellAlternatives * 3;
    right: $widthCellAlternatives;
    top: $sizeEight;
    font-family: $fontAvertaSemiBold;

    &::before {
      position: absolute;
      width: 98%;
      content: "";
      border-bottom: 1px solid $white;
      bottom: -$sizeSix;
    }

    &__text {
      text-align: center;
    }
  }

  &__text {
    width: 100%;
    text-align: center;

    &--left {
      text-align: left;
    }

    &--right {
      text-align: right;
    }

    &--highlighted {
      color: $orange;
    }

    &--tooltip {
      max-width: rem-calc(102px);
      text-align: center;
    }
  }

  &__icon {
    &-link {
      cursor: pointer;
    }

    &-arrow {
      transition: all 300ms ease-in-out;
      width: $sizeTwentyFour;
      margin-right: $sizeEight;
      &.is-rotate {
        transform: rotate(180deg);
      }
    }
  }

  &__row {
    transition: all 300ms ease-in-out;
    display: flex;

    &[data-has-parent] {
      height: 0;
      opacity: 0;
      overflow: hidden;
      &.is-shown {
        height: $sizeFourtyEight;
        opacity: 1;
        overflow: visible;
      }
    }

    &.preselected {
      border: 2px $blueDark solid;
    }

    &--main {
      background-color: $greyMedium;
      color: $blueDark;
      #{$b}__cell {
        &:first-child {
          background-color: $greyMedium;
        }
      }
    }

    &--totals:last-child {
      background-color: $blueLight;
      border-top: 2px solid $blueDark;
      color: $white;
      border-bottom-left-radius: $sizeEight;
      border-bottom-right-radius: $sizeEight;
      #{$b}__cell {
        &:first-child {
          background-color: $blueLight;
          border-bottom-left-radius: $sizeEight;
          border-bottom-right-radius: $sizeEight;
        }
      }
    }

    &--highlighted {
      background-color: $greyMedium !important;
      color: $blueDark;
    }
    #{$b}.has-row-selected & {
      opacity: 0.3;
      &.is-selected {
        opacity: 1;
      }
    }
  }

  &__cell {
    @extend %cell-style-alternatives;

    &:first-child {
      min-width: $widthFirstCellAlternatives;
      width: $widthFirstCellAlternatives;
      position: sticky;
      left: 0;
      background-color: white;
      z-index: 2;
    }
  }
}
