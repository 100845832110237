/**
 * A very simple reset that sits on top of Normalize.css.
 */

html ,
* {
  box-sizing: border-box;
}



/**
 * Remove outline on focused element 
 */

* 
*:focus,
*:active{
  outline: 0 !important;
}


html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}


/** 
 * HTML5 display-role reset for older browsers 
 */

article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}


/**
 * Remove the margin in all browsers (opinionated).
 */

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
  margin: 0;
}


/**
 * Remove links underline 
 */

a {
  color: inherit;
  text-decoration: none;
}

/**
 * Remove list decoration 
 */

ol ,
ul {
    list-style: none;
}

/**
 * Ordered list with styles
 */
ol {
    li {
        &:before {
            counter-increment: main-counter;
            content: counter(main-counter);
            padding-right: .8rem;
        }
    }
}


/**
 * Remove trailing margins from nested lists.
 */

li > {

  ol,
  ul {
    margin-bottom: 0;
  }

}



/**
 * Remove default table spacing.
 */

table {
  border-collapse: collapse;
  border-spacing: 0;
}



/**
 * 1. Reset Chrome and Firefox behaviour which sets a `min-width: min-content;`
 *    on fieldsets.
 */

fieldset {
  min-width: 0; /* [1] */
  border: 0;
}


/**
 * 1. Fluid images for responsive purposes.
 * 2. Offset `alt` text from surrounding copy.
 * 3. Setting `vertical-align` removes the whitespace that appears under `img`
 *    elements when they are dropped into a page as-is. Safer alternative to
 *    using `display: block;`.
 */

img {
  max-width: 100%; /* [1] */
  font-style: italic; /* [2] */
  vertical-align: middle; /* [3] */
}


/**
 * Remove default button
 */
[type="button"],
button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: none;
  border: 0;
  cursor: pointer;
  color: inherit;
  outline: none;

  &[disabled] {
    opacity: 0.27;
    pointer-events: none;
  }
}
