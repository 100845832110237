.c-header {
  display: flex;

  &__content {
    width: 100%;

    &-top {
      background-color: $greyLighter;
      padding-top: $sizeTwelve;
      padding-bottom: $sizeTwelve;
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }

    &-bottom {
      background-color: $greyLighter;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      min-height: $sizeThirtySix;
    }

    &-tools {
      width: calc(50% + 130px);
      display: flex;
      justify-content: space-between;

      &-center {
        display: flex;
        align-items: center;

        &-info-user {
          cursor: pointer;
          display: flex;
          flex-direction: column;
          padding-left: $sizeFifteen;
          padding-top: $sizeTen;
        }

        &-info-date {
          padding-right: $sizeTwentySix;
          color: $blueDark;
        }
      }

      &-left {
        display: flex;
        align-items: center;
      }
    }

    &-block {
      display: flex;
      align-items: center;
    }

    &-navigation {
      position: relative;

      &-tooltip {
        transition: all 300ms ease-out;
        background-color: $greyDark;
        border-radius: $sizeFour;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: nowrap;
        position: absolute;
        top: 50%;
        left: calc(50% + 10px);
        transform: translateX(-50%);
        padding: $sizeFour;
        pointer-events: none;
        opacity: 0;
        z-index: -1;

        $b: &;

        &:before {
          content: "";
          border-left: $sizeEight solid transparent;
          border-right: $sizeEight solid transparent;
          border-bottom: $sizeEight solid $greyDark;
          width: 0;
          height: 0;
          position: absolute;
          top: rem-calc(-7);
          left: 50%;
          transform: translateX(-50%);
        }

        &-text {
          color: white;
          font-size: $sizeTwelve;
          font-family: $fontAvertaSemiBold;
          text-align: center;
        }
      }

      &-alert-point {
        background-color: $red;
        border-radius: rem-calc(6);
        width: rem-calc(12);
        height: rem-calc(12);
        position: absolute;
        top: rem-calc(-2);
        right: rem-calc(18);
        z-index: 1;
      }

      .wrapper-icon {
        margin-left: $sizeEighteen;
        cursor: pointer;
        &:hover {
          ~ .c-header__content-navigation-tooltip {
            opacity: 1;
            top: calc(100% + 12px);
            z-index: $zIndexTooltip;
          }
        }
      }
    }
  }
}

