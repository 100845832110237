// $widthCell: rem-calc(182);
// $widthFirstCell: rem-calc(206);

:root {
  --width-cell: 11.375rem;
  --width-first-cell: 12.875rem;
}

%table-dropdown-cell-style {
  // width: $widthCell;
  // min-width: $widthCell;
  width: var(--width-cell);
  min-width: var(--width-cell);
  min-height: $sizeFourtyEight;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: $fontAvertaSemiBold;
  font-size: $sizeFourteen;
  padding: $sizeFour 14px;
  position: relative;
}

.c-table-dropdown {
  transition: all 500ms ease-in-out;
  opacity: 0;
  margin: auto;

  $b: &;
  &.is-statement {
    --width-cell: 14.5rem;
    --width-first-cell: 19rem;
  }

  &.has-searcher {
    margin-top: 5.8rem;
  }

  &__wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow: auto;
  }

  &__head {
    position: relative;
    &-row {
      transition: all 300ms ease-in-out;
      background-color: $blueDark;
      border-top-left-radius: $sizeEight;
      border-top-right-radius: $sizeEight;
      display: flex;
    }
    &-cell {
      @extend %table-dropdown-cell-style;
      color: $white;

      &--first {
        background-color: $blueDark;
        border-top-left-radius: $sizeEight;
        border-top-right-radius: $sizeEight;
        // min-width: $widthFirstCell;
        // width: $widthFirstCell;
        min-width: var(--width-first-cell);
        width: var(--width-first-cell);

        #{$b}.is-first-column-sticky & {
          position: sticky;
          left: 0;
          z-index: 3;
        }
      }

      &--tag {
        padding-top: rem-calc(42);
      }

      &-text {
        display: flex;
        align-items: center;
        min-height: $sizeThirtyTwo;
        justify-content: center;
      }
    }
  }

  &__tag {
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    color: $white;
    width: calc(var(--width-cell) * 3);
    right: var(--width-cell);
    top: $sizeEight;
    font-family: $fontAvertaSemiBold;

    &::before {
      position: absolute;
      width: 100%;
      content: "";
      border-bottom: 1px solid white;
      bottom: -$sizeSix;
    }

    &__text {
      text-align: center;
    }
  }

  &__text {
    width: 100%;
    text-align: center;

    &--left {
      text-align: left;
    }

    &--right {
      text-align: right;
    }

    &--highlighted {
      color: $orange;
    }

    &--tooltip {
      // max-width: rem-calc(102px);
      text-align: center;
    }
  }

  &__icon {
    &-link {
      height: rem-calc(16);
      cursor: pointer;
      margin-left: $sizeEight;
    }

    &-arrow {
    visibility: hidden;
      transition: all 300ms ease-in-out;
      width: $sizeTwentyFour;
      margin-right: $sizeEight;
      &.is-rotate {
        transform: rotate(180deg);
      }
    }

    // &-eye {
    //   height: rem-calc(16);
    // }
  }

  &__row {
    transition: all 300ms ease-in-out;
    display: flex;

    &[data-has-parent] {
      max-height: 0;
      opacity: 0;
      overflow: hidden;
      &.is-shown {
        max-height: rem-calc(300);
        opacity: 1;
        overflow: visible;
      }
    }
    &.preselected {
      border: 2px $blueDark solid;
    }

    &--main {
      background-color: $greyMedium;
      color: $blueDark;
      height: auto;
      #{$b}__cell {
        &:first-child {
          background-color: $greyMedium;
        }
      }
    }

    &--totals:last-child {
      background-color: $blueLight;
      border-top: 2px solid $blueDark;
      color: $white;
      border-bottom-left-radius: $sizeEight;
      border-bottom-right-radius: $sizeEight;
      height: auto;


      #{$b}.is-first-column-sticky & #{$b}__cell {
        &--first {
          background-color: $blueLight;
          border-bottom-left-radius: 0.5rem;
          border-bottom-right-radius: 0.5rem;
          position: sticky;
          left: 0;
          z-index: 2;
        }
      }

    }

    &--highlighted {
      background-color: $blueLight;
      color: $white;
    }

    #{$b}.has-row-selected & {
      opacity: 0.3;
      &.is-selected {
        opacity: 1;
      }
    }
  }

  &__cell {
    @extend %table-dropdown-cell-style;

    &--first {
      background-color: white;
      // min-width: $widthFirstCell;
      min-width: var(--width-first-cell);

      #{$b}.is-first-column-sticky & {
        position: sticky;
        left: 0;
        z-index: 2;
      }
    }
    &--last-icon {
      width: rem-calc(48);
      min-width: rem-calc(48);
      &.is-statement {
        width: rem-calc(182);
      }
    }
  }
}
