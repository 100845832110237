.super-wrapper-content-block {
  display: flex;
  flex-wrap: wrap;
}

.wrapper-content-block {
  background-color: white;
  border-radius: $sizeTwentyFour;
  display: flex;
  flex-direction: column;
  margin-bottom: $sizeSixteen;

  @media #{$lg-and-up} {
    width: calc(50% - 7px);
  }

  &:nth-child(even) {
    margin-left: rem-calc(7);
  }

  &:nth-child(odd) {
    margin-right: rem-calc(7);
  }

  &.centered-element {
    display: flex;
    align-items: center;
    .header {
      width: 100%;
    }
  }

  &.centered-and-full {
    width: 100%;
    margin-left: 0;

    .content {
      @media #{$xl-and-up} {
        overflow: auto;
        display: flex;
        align-items: center;
        flex-direction: column;
      }

      &.max-height {
        max-height: rem-calc(400);
      }
    }

    .wrapper-title {
      justify-content: center;
    }

    .headingH2 {
      flex: initial;
    }
  }

  &.fr-wrapper {
    ol {
      li {
        a {
          color: $orange;
        }
      }
    }
    ul {
      list-style: none;
      li {
        position: relative;
        padding-left: $sizeTwentyFour;

        &:before {
          content: "";
          background-color: $orange;
          border-radius: $sizeFour;
          width: $sizeEight;
          height: $sizeEight;
          position: absolute;
          top: 50%;
          left: 0;
          transform: translateY(-50%);
        }

        a {
          color: $orange;
        }
      }
    }
  }

  &.only-text {
    width: 100%;
    .content {
      align-items: flex-start;
      padding: $sizeSixteen;

      p {
        margin-bottom: $sizeSixteen;
      }

      .list {
        .list-item {
          position: relative;
          padding-left: $sizeTwentyFour;

          &:before {
            content: "";
            background-color: $orange;
            border-radius: $sizeFour;
            width: $sizeEight;
            height: $sizeEight;
            position: absolute;
            top: 50%;
            left: 0;
            transform: translateY(-50%);
          }

          .link {
            display: block;
            margin-bottom: $sizeSixteen;
          }
        }
      }
    }
  }

  .header {
    display: flex;
    padding: rem-calc(9);

    &.pointer {
      cursor: pointer;
    }

    &.small {
      padding: $sizeSixteen;
    }

    .extra-big {
      font-size: $sizeFourtyEight;
      height: rem-calc(41);
      line-height: $sizeFourtyEight;
    }
  }

  .date {
    padding-left: $sizeSixteen;
  }

  .wrapper-title {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    flex: 1;

    &.center {
      justify-content: center;
    }

    &.start {
      justify-content: start;
    }
  }

  .headingH2 {
    flex: 1;
  }

  .content {
    flex: 1;
    overflow-x: auto;
    overflow-x: hidden;

    &.max-height {
      max-height: rem-calc(366);
    }

    &.centered {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .content-fake {
      position: relative;

      .content-fake__icon {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: 1;
        .wrapper-icon {
          width: $sizeSeventyTwo;
          height: rem-calc(105);
        }
        .content-fake__icon--text {
          color: $blueDark;
        }
      }
      .content-fake__image {
        max-width: 100%;
        margin: 0 auto;
      }
    }
  }

  .footer {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: $sizeSixteen;

    &.multiple-buttons {
      width: 100%;
      .btn {
        margin: 0 $sizeTwelve;
      }
    }
  }
}
