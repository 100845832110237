/* ==========================================================================
#MARGIN
========================================================================== */

html {
  body {

    .o-margin {
      &--zero {
        margin: 0;
      }
      // Bottom
      &--bottom {
        &-size-s {
          margin-bottom: $sizeTwelve;
        }

        &-size-xs {
          margin-bottom: $sizeEight;
        }
        &-size-s {
          margin-bottom: $sizeTwelve;
        }
        &-size-m {
          margin-bottom: $sizeEighteen;
        }

        &-size-l {
          margin-bottom: $sizeTwentyFour
        }

        &-fourtyEight {
          margin-bottom: $sizeFourtyEight;
        }
      }

      // Top
      &--top {
        &-size-s {
          margin-top: $sizeTwelve;
        } 
        &-size-m {
          margin-top: $sizeEighteen;
        }
        &-twentyFour {
          margin-top: $sizeTwentyFour;
        }
      }
      // @Top

      // Left
      &--left {
        &-eight {
          margin-left: $sizeEight;
        }
        &-fourteen {
          margin-left: $sizeFourteen;
        }
      }
      // @Left

      // Right
      &--right {
        &-fourteen {
          margin-right: $sizeFourteen;
        }
      }
      // @Right

      // Horizontal
      &--hor-eight {
        margin-right: $sizeEight;
        margin-left: $sizeEight;
      }
      &--hor-fourteen {
        margin-right: $sizeFourteen;
        margin-left: $sizeFourteen;
      }
      // @Horizontal
    }
  }
}
