.container-info-assets {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: $sizeThirty;
  padding-left: $sizeTwenty;
  padding-bottom: $sizeTwenty;
  padding-right: $sizeTwenty;
  background-color: white;

  &.height {
    min-height: rem-calc(395);
  }


  .text {
    text-align: center;
    font-size: $sizeTwentyTwo;
    
  }

  .btn {
    text-transform: uppercase;
  }
}
