.wrapper-imageGrid {
  display: flex;
  padding: $sizeTwelve 0;
  overflow-x: auto;
}

.item-imageGrid {
  position: relative;
}

.image-imageGrid {
  width: $sizeFiftySix;
  height: $sizeFiftySix;
  margin-right: $sizeEight;
  object-fit: contain;
}

.delete-icon {
  position: absolute;
  top: -$sizeTwelve;
  right: -$sizeEight;
  z-index: $zIndexTooltip;
}
