.wrapper-pagination {
    transition: all 300ms ease-in-out;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: $sizeSixteen;
    padding-bottom: $sizeSixteen;
    opacity: 0;
    &.active {
        opacity: 1;
    }
    .container-pagination {
        display: flex;
        align-items: center;
        justify-content: center;
        .pagination__item {
            background-color: $greyMedium;
            color: white;
            cursor: pointer;
            width: $sizeThirtySix;
            height: $sizeThirtySix;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-left: $sizeEight;
            &:first-child {
                margin-left: $sizeEight;
            }
            &.active {
                background-color: white;
                border: 1px solid $blueLight;
                color: $greyDark;
            }
        }
    }
}
