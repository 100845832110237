.nestedlist-ownership {
  padding: 0 $sizeTwentyFour;
  position: relative;
  max-height: rem-calc(500);
  overflow: hidden;

  &--full-heigth {
    max-height: 100%;
  }

  #first {
    > .border {
      display: none;
    }
  }

  &__item-wrapper {
    position: relative;

    &:before {
      content: "";
      display: block;
      background-color: white;
      position: absolute;
      top: rem-calc(-41);
      left: rem-calc(-2);
      height: rem-calc(60);
      width: 0.125rem;
      z-index: 99;
    }

    &.has-hook {
      .has-hook__border {
        transition: all 150ms ease-in-out;
        bottom: $sizeTwentyFour;
        border-bottom: rem-calc(2) solid $greyDarkMedium;
        border-left: rem-calc(2) solid $greyDarkMedium;
        border-bottom-left-radius: $sizeTen;
        position: absolute;
        width: $sizeEighteen;
        height: rem-calc(68);
        left: -$sizeTwenty;
      }
    }
  }

  &__item-sibling {
    // First item of the nestedlist without account parent
    &[data-first="true"] {
      > .nestedlist-ownership__item-parent {
        > .nestedlist-ownership__item-wrapper {
          &.has-hook {
            .has-hook__border {
              display: none;
            }
          }
        }
      }
    }
    &.is-visible {
      .nestedlist-ownership__item {
        height: $sizeFourtyEight;
      }
    }
  }

  &__item-level-color {
    transition: all 300ms ease-out;
    position: absolute;
    width: $sizeEight;
    height: $sizeEight;
    border-radius: 100%;
    left: rem-calc(-3);
    top: rem-calc(19);
    z-index: 101;
  }

  &__item {
    position: relative;
    transition: all linear 0.2s;
    overflow: hidden;
    border-radius: $sizeTwentyFour;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: $sizeFourteen;
    cursor: pointer;
    background-color: $greyLight;
    margin-bottom: $sizeTwelve;
    height: 0;
    position: relative;
    z-index: 100;

    &.is-visible {
      height: $sizeFourtyEight;
    }

    &.special {
      border: 1px solid $green;
    }
    &.special_investment {
      border: 1px solid #e08fff;
    }
    &--border-color {
      border: 1px solid $blueLight;
    }

    &-text {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      padding-left: $sizeFourteen;
      z-index: 1;

      &--color-blue {
        color: $blueMedium;
        font-family: $fontAvertaSemiBold;
      }
    }

    &-icon {
      transition: all linear 0.1s;
      pointer-events: none;
      display: flex;
      align-items: center;
      justify-content: center;
      width: rem-calc(48);
      height: 100%;

      &--up {
        transform: rotate(180deg);
      }
    }

    &-parent {
      position: relative;

      .border {
        position: absolute;
        width: 100%;
        height: calc(100% - 24px);
        height: 100%;

        &::before {
          position: absolute;
          content: "";
          border-left: rem-calc(2) solid $greyDarkMedium;
          height: calc(100% - 48px);
          left: rem-calc(-2);
          top: rem-calc(19);
        }
      }
    }
  }
}
