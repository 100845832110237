.c-modal__historical-advice {
  position: relative;

  &-container {
    max-width: 324px;
  }
  padding: rem-calc(24);
  h4 {
    border-bottom: 1px solid $orange;
    color: $orange;
    font-size: rem-calc(18);
    padding-bottom: rem-calc(8);
    margin-bottom: rem-calc(12);
  }

  p {
    color: $greyDark;
  }

  &-close {
    width: rem-calc(24);
    height: rem-calc(24);
    position: absolute;
    top: 0;
    right: 24px;
  }

}
