&__client {
  &-container {
    background-color: $white;
    border-radius: rem-calc(8);
    border: 1px solid $orange;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: rem-calc(8);
    padding: rem-calc(12);
    margin-bottom: rem-calc(12);
  }
  &-content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  &-number {
    font-size: $sizeEighteen;
  }
}
