/* ==========================================================================
   #WIDTH
   ========================================================================== */

.u-width {
  &--full {
    width: 100%;
  }
  &--75\% {
    width: 75%;
  }
}
