/* ==========================================================================
#DISPLAY
========================================================================== */

.o-position {
  &--fixed {
    position: fixed;

    &-bottom {
      bottom: 0;
    }
  }
  &--vertical-center {
    top: 50%;
    transform: translateY(-50%);
  }
  &--horizontal-center {
    left: 50%;
    transform: translateX(-50%);
  }
}
