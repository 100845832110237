.wrapper-sidebar {
  transition: all 800ms cubic-bezier(0.22, 0.04, 0.05, 1);
  width: rem-calc(460);
  background-color: white;
  height: 100vh;
  position: fixed;
  right: 0;
  top: -100vh;
  z-index: 999999;
  border: rem-calc(1) solid $greyLight;
  

  @media #{$xxl-and-up} {
    right: calc((100vw - 1378px) / 2);
  }

  .container {
    width: 100%;
    position: relative;
    padding-top: $sizeTwelve;
    padding-left: $sizeSixteen;
    padding-right: $sizeSixteen;
    transition: all 1200ms cubic-bezier(0.22, 0.04, 0.05, 1);
    opacity: 0;

    height: 100vh;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow-y: auto;

    .sidebar-header {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      padding-top: $sizeTwelve;

      .wrapper-close {
        cursor: pointer;
      }
    }

    .sidebar-items {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      &.prefooter {
        margin-top: $sizeTwenty;
      }

      &.info-settings {
        // position: absolute;
        // bottom: $sizeFourty;
        width: 100%;
      }
      .wrapper-item {
        display: flex;
        align-items: center;
        align-self: flex-start;
        padding-top: $sizeEight;
        padding-bottom: $sizeEight;

        &.pro-item > .wrapper-icon{
          padding-top: 5px;
        }

        .item-text {
          font-family: $fontAvertaSemiBold;
          //text-transform: uppercase;
          padding-left: $sizeEight;
          color: $greyDark;
        }
      }

      .item-separator {
        background-color: $blueDark;
        height: rem-calc(2);
        width: 80%;
      }
    }

    .sidebar-bottom {
      position: absolute;
      bottom: $sizeThirtyTwo;
      width: 100%;
      padding-left: $sizeSixteen;
      padding-right: $sizeSixteen;

      .wrapper-legal_links {
        display: flex;
        justify-content: space-around;
        margin-bottom: $sizeTen;

        .legal-link {
          font-family: $fontAvertaRegular;
          color: $blueLight;
          font-size: $sizeTen;
        }
      }

      .copy-right {
        padding-left: $sizeTwelve;
      }
    }
  }
}
