.textBlock-wrapper-description {
  background-color: white; 
  padding: $sizeEight $sizeSixteen;
  border-radius: $sizeEighteen;
  height: $sizeThirtySix; 
  overflow: hidden;
  width: 100%;
  min-height: $sizeThirtySix;
  border: rem-calc(2) solid $greyMedium;

  &.grey {
    background-color: $greyLight;
    border: 0;
  }
}