.c-table-editable {
  margin-top: $sizeTwentyFour;
  margin-bottom: $sizeTwentyFour;

  $b: &;

  &__row {
    display: flex;
    &:nth-child(even) {
      background-color: $greyMedium;
    }
  }

  &__cell-wrap {
    max-width: rem-calc(160);
  }

  &__cell {
    background-color: transparent;
    color: $blueDark;
    max-width: rem-calc(160);
    flex: 1;
    height: $sizeFourtyEight;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: $sizeFour $sizeEight;
    #{$b}__row-header & {
      border-bottom: 2px solid $blueDark;
      font-family: $fontAvertaBold;
    }
  }
}

