/* ==========================================================================
#CAROUSEL
========================================================================== */

.wrapper-slider {
  position: relative;
  width: calc(100% + 16px);
  left: -$sizeEight;
  max-width: rem-calc(840);
  margin: auto;

  .slick-slider {
    button {
      display: none !important;
    }
    
    .slick-list {
      .slick-track {
        display: flex;
        align-items: center;
        height: 100%;
        .slick-slide {
          height: auto;
        }
      }
    }

    &:nth-child(2) {
      .slick-list {
        .slick-track {
          .slick-slide {
            opacity: 0.5;

            &.slick-current {
              opacity: 1;
            }
            .container-img-carousel{

              height:  rem-calc(150);
          
              .img-carousel {
                cursor: pointer;
                width: 100%;
                max-width: 100%;
                height: 100%;
                // object-fit: cover;
                object-fit: contain;
                padding: $sizeEight $sizeTen 0;
              }
            }
            
          }
        }
      }
    }

    &:first-child {
      cursor: pointer;
    }
  }
}

.wrapper-arrows {
  display: flex;
  width: calc(100% - 16px);
  justify-content: space-between;
  position: absolute;
  bottom: 8%;
  left: $sizeEight;
}

.arrow-container-left {
    background-color: $white;
    border-radius: rem-calc(18);
    cursor: pointer;
    width: rem-calc(36);
    height: rem-calc(36);
    position: absolute;
    top: 85%;
    left: $sizeTwelve;
}
.arrow-container-right {
    background-color: $white;
    border-radius: rem-calc(18);
    cursor: pointer;
    width: rem-calc(36);
    height: rem-calc(36);
    position: absolute;
    top: 85%;
    right: $sizeTwelve;
}

.img-carousel {
  //padding: $sizeEight $sizeTen 0;
  height: 28.125rem;
  object-fit: contain;
  object-position: top;
}
