.c-dropdown-menu {
  &__button {
    // position: absolute;
    // top: rem-calc(24);
    // right: rem-calc(24);
  }

  &__content {
    transition: all 500ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
    background-color: white;
    border-radius: 1.25rem 0 0 1.25rem;
    box-shadow: -14px 0 21px 3px rgba(0,0,0,0.1);
    // border: 1px solid $greyMedium;
    min-width: rem-calc(160);
      max-width: rem-calc(400);
    overflow: hidden;
    display: flex;
    flex-direction: column;
    position: absolute;
    top: rem-calc(96);
    right: -400px;
    z-index: 9;
    padding: 0;
    &.is-active {
      right: 0;
    }

    &-tab {
      transition: all 300ms cubic-bezier(0.08, 0.63, 0.31, 1);
      border-bottom: 1px solid $greyMedium; 
      cursor: pointer;
      height: $sizeFourtyEight;
      display: flex;
      align-items: center;
      justify-content: center;
      padding-right: 0;

      $tab: &;

      &:last-child {
        border-bottom: 0;
      }

      &-text {
        transition: all 750ms cubic-bezier(0.08, 0.63, 0.31, 1);
        width: max-content;
        font-family: $fontAvertaRegular;
        color: $greyDark;
        height: 100%;
        display: flex;
        align-items: center;
        font-size: $sizeSixteen;
        position: relative;
        &:before {
          transition: all 750ms cubic-bezier(0.08, 0.63, 0.31, 1);
          background-color: $blueDark;
          content: "";
          width: 100%;
          height: 3px;
          position: absolute;
          left: 0;
          bottom: 0;
          opacity: 0;
        }
        #{$tab}.is-active & {
          font-family: $fontAvertaBold;
          color: $blueDark;
        }
        #{$tab}.is-active &:before {
          opacity: 1;
        }
      }
    }
  }

}

