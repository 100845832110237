
.wrapper-avatar {
  width: rem-calc(45); 
  height: rem-calc(45);
  position: relative;
  cursor: pointer;

  &.little {
    width: $sizeThirtySix;
    height: $sizeThirtySix;
  }

  &.medium {
    width: $sizeSeventyTwo;
    height: $sizeSeventyTwo;
  }

  &.big {
    width: rem-calc(100);
    height: rem-calc(100);
  }


  &.space {
    margin-top: $sizeSixteen;
    margin-bottom: $sizeSixteen;
  }

  .avatar {
    width: 100%; 
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
    
    &.bordered {
      border: rem-calc(2) solid $blueLight;
    }
  }

  .wrapper-icon-avatar {
    width: $sizeTwentyFour; 
    height: $sizeTwentyFour;
    @include flex-column-total-centered;
    @include absolute(initial, -$sizeSix, -$sizeEight, initial);
  }

  .avatar-button {
    position: absolute;
    top: -$sizeTen;
    right: -$sizeTen;
    background-color: $blueLight;
    width: $sizeThirtySix;
    height: $sizeThirtySix;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
  }

  .temp-img {
    width: 100%;
    height: 100%;
    background-color: $orange;
    border-radius: 100%;
  }
}

.select-image {
  width: rem-calc(300);
  display: flex;
  flex-direction: column;
  align-items: center;

  &__buttons {
    margin-top: $sizeFourty;
    width: 100%;
    text-align: center;
  }

  &__item {
    width: rem-calc(100);
    height: rem-calc(100);
    border-radius: 100%;

    &-img {
      width: 100%;
      height: 100%;
      border-radius: 100%;
      object-fit: cover;
    }
  }
}

.wrapper-avatar-selection {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: $sizeTwelve;

  .default-avatars-container {
    display: flex;
    flex-direction: row;
    margin-top: $sizeSixteen;
    margin-bottom: $sizeSixteen;
    width: 20%;
    justify-content: space-around;
    width: 50%;

    .default-avatar-button {
      padding: $sizeFive;
      
      &.is-select {
        border: 2px solid $blueLight;
        border-radius: $sizeSix;
      }
    }
  
    .default-avatar {
      width: $sizeFourtyEight;
      height: $sizeFourtyEight;
    }
  }
}