.super-wrapper-video-block {
  display: flex;
  border-bottom: rem-calc(1) dashed $greyLight;
}

.wrapper-video-block {
  padding: $sizeSixteen;
  width: 100%;

  &:first-child {
    border-right: rem-calc(1) dashed $greyLight;
  }
}

.wrapper-video {
  margin-bottom: $sizeTen;
  display: block;
  width: 100%;
  position: relative;
  height: 0;
  padding: 56.25% 0 0 0;
  overflow: hidden;

  .wrapper-icon {
    position: absolute;
    @include absolute(50%, initial, initial, 50%);
    transform: translate(-50%, -50%);
    z-index: $zIndexTooltip;
  }

  &:before {
    content: '';
    background-color: #00000033;
    @include absolute(0, 0, 0, 0);
    z-index: $zIndexNormal;
  }
}

.video {
  position: absolute;
  display: block;
  max-width: 100%;
  max-height: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
}

.wrapper-pre-title {
  display: flex;
  align-items: center;
  margin-bottom: $sizeEight;
  margin-top: $sizeEight;

  .date {
    margin-right: $sizeEight;
  }

  .headingH4 {
    line-height: $sizeTwenty;
  }
}

.wrapper-video-title {
  min-height: rem-calc(90);
}