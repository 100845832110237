.wrapper-tree-graphic {
  width: 100%;
  max-width: rem-calc(830);
  padding-right: $sizeTwentyFour;
  padding-left: $sizeTwentyFour;
  margin: 0 auto;

  .text-total {
    font-size: $sizeFiftySix;
    line-height: $sizeFiftySix;
    padding-top: $sizeTwentyFour;
    padding-bottom: $sizeFourtyEight;
  }

  .wrapper-graphic {
    display: flex;
  }

  .first-graphic-block {
    flex: 1;
    margin-right: $sizeTwelve;
  }

  .second-graphic-block {
    flex: 1;
    margin-left: $sizeTwelve;

    .wrapper-header-side {
      .title {
        .amount {
          color: $orange
        }
      }
    }

  }

  .wrapper-header-side {
    margin-bottom: $sizeTwentyFour;

    .title {
      color: $blueDark;
      font-family: $fontAvertaSemiBold;
      font-size: $sizeTwentyEight;
      line-height: $sizeTwentyEight;

      .amount {
        color: $blueLight;
      }
    }
  }

  .wrapper-body-side {

    .graphic-item {
      background-color: $greyLight;
      border-radius: $sizeTwentyFour;
      cursor: pointer;
      width: 100%;
      height: rem-calc(150);
      position: relative;
      overflow: hidden;
      padding-top: $sizeTwentyFour;
      margin-bottom: $sizeTwentyFour;

      &.empty {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding-top: 0;
      }

      &.pasive {
        .text-container {
          .amount {
            color: $orange;
          }
        }
        .progress-bar-container {
          .progress-bar {
            background-color: $orange;
            border-radius: $sizeFourteen 0 0 $sizeFourteen;
          }
        }
      }

      .text-container {
        padding-left: $sizeTwentyFour;
        padding-right: $sizeTwentyFour;

        .text {
          color: $blueDark;
          font-size: $sizeTwentyTwo;
          font-family: $fontAvertaBold;
          margin-bottom: $sizeFourteen;
        }
        .amount {
          color: $blueLight;
          font-size: $sizeTwentyTwo;
          font-family: $fontAvertaSemiBold;
          margin-bottom: $sizeFourteen;
        }

        .text-value {
          color: $blueLight;
          font-family: $fontAvertaSemiBold;
        }
      }

      .wrapper-icon {
        background-color: $orange;
        border-radius: 50%;
        width: $sizeFourtyEight;
        height: $sizeFourtyEight;
        margin-bottom: $sizeFourteen;
        z-index: 2;
      }

      .progress-bar-container {
        background-color: $greyMedium;
        width: 100%;
        height: $sizeEighteen;
        position: absolute;
        bottom: 0;
        left: 0;
        .progress-bar {
          background-color: $blueLight;
          border-radius: 0 $sizeFourteen $sizeFourteen 0;
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
        }
      }
    }

    .networth-container {
      cursor: default;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .text-container {
        .text {
          text-align: center;
        }
      }
    }

  }

}
