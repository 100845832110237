.signIn-wrapper {

  display: flex;
  justify-content: center;
  height: 100vh;
  padding-top: 20%;

  @media #{$lg-and-up} {
    padding-top: 0;
    height: 100%;
  }

  @media #{$iPadPro} {
    height: 100vh;
    padding-top: 20%;
  }

  .headingH2 {
    display: block;
    padding-bottom: $sizeEight;
  }

  .btn {

    &:first-child {
      margin-bottom: $sizeTwenty;
    }

    &:not(:first-child){
      margin-top: $sizeEighteen;
    }
  }

  &.recovery {

    .wrapper-icon {
      width: rem-calc(60);
      height: rem-calc(50);
    }
    .container-text {
      margin-top: $sizeTwenty;
    }
    .btn {
      width: auto;
    }

    .signIn-content {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }


  .signIn-linkblock{
    border:none;
    padding: 0;
    min-height: auto;

    .container-check {
      margin-bottom: $sizeFourteen;
    }
    .subwrapper-link-block{
      margin-left: rem-calc(26);
      padding: 0;
    }

  }

  .checkmark {
    background-color: $white;
    border: rem-calc(2) solid $greyMedium;
  }
}

.signIn-content {
  max-width: rem-calc(500);
  width: 100%;
}

.wrapper-signIn-logo {
  @include flex-column-horizontal-centered;
  margin-bottom: $sizeTwentyEight;
}

.signIn-wrapper-buttons {
  margin-top: $sizeTwenty;
  @include flex-column-horizontal-centered;
  margin-bottom: $sizeTwentyFour;
}
