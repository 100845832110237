.wrapper-barsGraphic {
  width: rem-calc(650);
  margin-right: auto;
  margin-left: auto;
  margin-bottom: $sizeFourtyTwo;
  @media #{$lg-and-up} {
      width: rem-calc(850);
  }

  &.is-blur {
    text ,
    .text-total {
      filter: blur(8px);
    }
  }

}

// .filter-buttons-wrapper {
//   display: flex;
//   justify-content: center;
// }

.filter-buttons {
  margin-right: $sizeTwelve;

  &:last-child {
    margin-right: 0;
  }

  &.btn {
    min-width: 0;
  }
}
