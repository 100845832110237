.permission,
.profile, 
.profile_edit {

  .flex {
    justify-content: space-around;
  }
}
.pro,
.profile , 
.permission, 
.profile_edit {

  $b: &;
  
  .wrapper-profile {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &--full-width {
      max-width: 100%;
    }

    &.modal {
      padding-bottom: $sizeTwenty;
    }

    .c-tabs__content {
      // max-width: rem-calc(590);
      margin: 0 auto;
      &--profile {
        max-width: rem-calc(590);
      }
    }

    .filter-buttons-wrapper {
      margin-bottom: $sizeFourtyTwo;
      justify-content: center;

      .btn {
        margin-left: $sizeEight;
        margin-right: $sizeEight;
      }
    }

    .container-info-profile {
      display: flex;
      align-items: center;

      .text {
        margin-right: $sizeFive;
      }
    }
    .wrapper-index-profile {
      transition: all 750ms ease-in-out;
      max-height: 0;
      height: 100%;
      overflow: hidden;
      margin-bottom: $sizeTwenty;
      &.is-active {
        max-height: rem-calc(800);
      }
    }
    .content-index-profile {
      display: flex;
      flex-direction: column;

      .separation {
        margin-bottom: $sizeTwenty;
      }

      .headingH5 {
        .color-text {
         color: $blueDark;
         margin-right: $sizeTen;
        }
      }
    }

    .wrapper-access {
      width: 100%;

      .wrapper-data {
          border-bottom: rem-calc(1) solid $greyDarkMedium;
          display: flex;
          padding-bottom: $sizeTwentyFour;
          margin-bottom: $sizeTwentyFour;
          .data {
              width: 50%;
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
          }
      }

      .container-form {
        position: relative;
        height: 0;
        overflow: hidden;
        opacity: 0;
        transition: all linear .3s;
        margin-top: $sizeThirty;
        padding-left: $sizeFive;
        padding-right: $sizeFive;

        &.open {
          height: 100%;
          opacity: 1;
        }

        .title1 {
          text-align: center;
        }
      }

      .flex {
        .wrapper-table {
          overflow: initial;
        }
      }

      .btn {
        margin-top: $sizeThirty;
      }
    }

    .wrapper-avatar-selection {
      width: 100%;

    }

    .wrapper-asstesList {
      width: 100%;
    }

    .profile--text-info {
      width: 100%;
      margin-bottom: $sizeTwenty;

      .text-info {
        color: $blueDark;
        margin-bottom: $sizeEight;
      }
    }

    .profile-edit-buttons {
      display: flex;
      width: 100%;

    }

    .settings {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;

      .settings__title {
        width: 100%;
      }
      .wrapper-link-block {
        border-bottom-color: $greyMedium;

        .container-check {
          top: rem-calc(-7);
        }
  
        
      }

      .btn {
        margin-top: $sizeFourty;
      }
    }
    
  }

  .container-check {
    margin-bottom: 0;
  }

  .wrapper-input-checkbox {
    margin-bottom: $sizeTwentyFour;
  }

  .table {
    margin-top: $sizeFourtyEight;
  }

  .headingH2 {
    // color: $orange;
    // font-size: $sizeTwentyFour;
    // font-weight: 300;
    // margin-bottom: $sizeEight;
    // margin-top: $sizeSixteen;

    &.blueSoft {
      color: $blueLight;
    }
  }

  .container-btn {
    width: 100%;
  }

  .margin {
    margin: $sizeThirty 0;
  }

  .margin-top {
    margin-top: $sizeFourty;
  }

  // Privacy

.block__privacy {
  margin-top: $sizeTwenty;

  .headingH4 {
    text-align: center;
    margin-bottom: $sizeTen;
  }
  .text {
    text-align: center;
  }
  .block__privacy--content {
    margin-top: $sizeFourtyEight;
    .btn {
      margin-top: $sizeTwenty;
    }
  }

  .wrapper-link-block {
    border:none;
    min-height: auto;
    padding-bottom: 0;
    .container-check {
      left: calc(100% - 320px);
    }
    .subwrapper-link-block {
      align-items: center;
      justify-content: center;
    }
  }
}

// @privacy
}

.permission {
  .btn {
    margin: 0;
  }
}


.react-phone-number-input {
  input {
    color:$blueDark;
    background-color: $greyLight;
  }
}

.requirements-access {
  display: flex;
  align-items: center;

  .text {
    font-size: $sizeTwelve;
  }
}



