.wrapper-invitations {
  .container-invitation {
    width: 50%;
    .wrapper-link-block {
      min-height: initial;
      border-bottom: 0;

      &.left {
        margin-left: $sizeTwenty;
      }
      .container-check {
        top: rem-calc(-7);
      }
    }
  }
}
