/* ==========================================================================
   #CORE
   ========================================================================== */
// Table of Contents:
//
//  1. #COLORS
//  2. #FONTS
//  3. #SIZES
//  4. #MEDIAQUERIES
//  5. #INDEX

/************************************************************
 * 1. #COLORS
 ************************************************************/
$white: white;
$blueDark: #005774;
$blueMedium: #408197;
$blueLight: #80abba;
$blueLighter: #a3c0e0;

$greenLight: #e6eb76;

$orange: #f3b01c;

$greyDark: #89898a;
$greyDarkMedium: #a2a5af;
$greyMedium: #e4e4e4;
$greyLight: #f5f5f7;
$greyLighter: #fbfbfb;

$green: #dce33f;
$red: #ff4d4d;

$blackOpacity70: rgba(0, 0, 0, 0.7);

/************************************************************
 * 2. #FONTS
 ************************************************************/
$fontAvertaRegular: "Averta-Regular", -apple-system, BlinkMacSystemFont,
  "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
  "Droid Sans", "Helvetica Neue", sans-serif;
$fontAvertaSemiBold: "Averta-Semibold", -apple-system, BlinkMacSystemFont,
  "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
  "Droid Sans", "Helvetica Neue", sans-serif;
$fontAvertaBold: "Averta-Bold", -apple-system, BlinkMacSystemFont, "Segoe UI",
  "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
  "Helvetica Neue", sans-serif;

/************************************************************
 * 3. #SIZES
 ************************************************************/
$sizeFour: rem-calc(4);
$sizeFive: rem-calc(5);
$sizeSix: rem-calc(6);
$sizeEight: rem-calc(8);
$sizeTen: rem-calc(10);
$sizeTwelve: rem-calc(12);
$sizeFourteen: rem-calc(14);
$sizeFifteen: rem-calc(15);
$sizeSixteen: rem-calc(16);
$sizeEighteen: rem-calc(18);
$sizeTwenty: rem-calc(20);
$sizeTwentyTwo: rem-calc(22);
$sizeTwentyFour: rem-calc(24);
$sizeTwentySix: rem-calc(26);
$sizeTwentyEight: rem-calc(28);
$sizeThirty: rem-calc(30);
$sizeThirtyTwo: rem-calc(32);
$sizeThirtyFive: rem-calc(35);
$sizeThirtySix: rem-calc(36);
$sizeFourty: rem-calc(40);
$sizeFourtyTwo: rem-calc(42);
$sizeFourtyEight: rem-calc(48);
$sizeFiftyTwo: rem-calc(52);
$sizeFiftySix: rem-calc(56);
$sizeSixty: rem-calc(60);
$sizeSixtyFour: rem-calc(64);
$sizeSeventyTwo: rem-calc(72);
$sizeSeventySix: rem-calc(76);
$sizeNinetySix: rem-calc(96);

/************************************************************
 * 4. #MEDIAQUERIES
 ************************************************************/
$xxs: rem-calc(320); // iphone4 / iphone5s
$xs: rem-calc(375); // iphone6-7-8
$sm: rem-calc(660); // tablet 7"
$md: rem-calc(768); // iPad
$lg: rem-calc(1024); // iPad landscape
$xl: rem-calc(1366); // iPad landscape
$xxl: rem-calc(1440); // desktops

$xxs-and-up: "only screen and (min-width : #{$xxs})" !default;
$xs-and-up: "only screen and (min-width : #{$xs})" !default;
$sm-and-up: "only screen and (min-width : #{$sm})" !default;
$md-and-up: "only screen and (min-width : #{$md})" !default;
$lg-and-up: "only screen and (min-width : #{$lg})" !default;
$xl-and-up: "only screen and (min-width : #{$xl})" !default;
$xxl-and-up: "only screen and (min-width : #{$xxl})" !default;

$xxs-and-down: "only screen and (max-width : #{$xxs})" !default;
$xs-and-down: "only screen and (max-width : #{$xs})" !default;
$sm-and-down: "only screen and (max-width : #{$sm})" !default;
$md-and-down: "only screen and (max-width : #{$md})" !default;
$lg-and-down: "only screen and (max-width : #{$lg})" !default;
$xl-and-down: "only screen and (max-width : #{$xl})" !default;

$xs-only: "only screen and (min-width : #{$xs}) and (max-width : #{$sm})" !default;
$sm-only: "only screen and (min-width : #{$sm}) and (max-width : #{$md})" !default;
$md-only: "only screen and (min-width : #{$md}) and (max-width : #{$lg})" !default;
$lg-only: "only screen and (min-width : #{$lg}) and (max-width : #{$xl})" !default;
$xl-only: "only screen and (min-width : #{$xl}) and (max-width : #{$xxl})" !default;

$iPadPro: "only screen and (min-width : #{$lg}) and (min-height : #{$lg})" !default;

/************************************************************
 * 5. #Z-INDEX
 ************************************************************/
$zIndexBack: -10;
$zIndexNormal: 1;
$zIndexTooltip: 10;
$zIndexFixed: 100;
$zIndexModal: 99999;
