/* ==========================================================================
#PADDING
========================================================================== */

#root {
  .o-padding {

    &--bottom {
      &-zero {
        padding-bottom: 0;
      }
      &-size-s {
        padding-bottom: $sizeTwelve;
      }
      &-size-m {
        padding-bottom: $sizeEighteen;
      }
      &-size-l {
        padding-bottom: $sizeTwentyFour;
      }
    }

    // Top
    &--top-twelve {
      padding-top: $sizeTwelve;
    }
    // @Top

    // Bottom
    &--bottom-sixteen {
      padding-bottom: $sizeSixteen;
    }
    // @Bottom

    // Left
    &--left-fourteen {
      padding-right: $sizeFourteen;
      padding-left: $sizeFourteen;
    }
    // @Left

    // Right
    &--right-fourteen {
      padding-right: $sizeFourteen;
    }
    // @Right

    // Horizontal
    &--hor-fourteen {
      padding-right: $sizeFourteen;
      padding-left: $sizeFourteen;
    }
    // @Horizontal

  }

}
