.block-news {
  cursor: pointer;
  width: 100%;
  height: 100%;
  position: relative;
  padding: $sizeSixteen;

  &.small {
    height: auto;
    .block-news--container {
      flex-direction: row;
      height: rem-calc(81);

      .block-news--text {
        width: 50%;
      }

      .block-news--img {
        width: rem-calc(122);
        height: 100%;
        margin-right: $sizeFourteen;
        margin-bottom: 0;
      }
    }
  }

  .block-news--container {
    display: flex;
    align-items: flex-start;
    margin-top: $sizeTwenty;
    flex-direction: column;

    .block-news--img {
      max-height: rem-calc(291);
      margin-bottom: $sizeFourteen;
      overflow: hidden;
      display: flex;
      align-items: center;

      .news-img {
        max-width: 100%;
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }

    .block-news__title {
      margin-top: -$sizeFour;
    }
    .block-news__legend {
      font-size: $sizeTwelve;
      position: absolute;
      bottom: -$sizeFour;
      left: 0;
      margin: 0;
    }

    .block-news--text {
      height: 100%;
      position: relative;

      &.full-width {
        width: 100%;
      }
    }

    .legend {
      position: absolute;
      bottom: $sizeSixteen;
      left: $sizeSixteen;
      margin-bottom: 0;
    }
  }
}
