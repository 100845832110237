button {
  -webkit-appearance: none !important;
  border: 0;
  padding: 0;
  cursor: pointer;
  color: white;
  font-size: $sizeFourteen;
  line-height: $sizeFifteen;
  font-family: $fontAvertaSemiBold;
}

.btn {
  @include flex-column-total-centered;
  transition: all 300ms ease-out;
  border-radius: $sizeEighteen;
  min-width: rem-calc(120);
  height: $sizeThirtySix;
  background-color: $blueLight;
  padding: 0 rem-calc(17);
  margin-left: auto;
  margin-right: auto;

  &--not-centered {
    margin-left: initial;
    margin-right: initial;
  }

  &.small {
    height: $sizeTwentyFour;
  }

  &.primary {
    background-color: $blueLight;
    color: white;
    &.border {
      background-color: transparent;
      border: rem-calc(1) solid $blueLight;
      .btn-text {
        color: $blueLight;
      }
    }
  }

  &.secondary {
    background-color: $orange;

    &.border {
      background-color: transparent;
      border: rem-calc(1) solid $orange;
      .btn-text {
        color: $orange;
      }
    }
  }

  &.tertiary {
    background-color: $blueDark;
    &.border {
      background-color: transparent;
      border: rem-calc(1) solid $blueDark;
      .btn-text {
        color: $blueDark;
      }
    }
  }

  &.success {
    background-color: $green;
    &.border {
      background-color: transparent;
      border: rem-calc(1) solid $green;
      .btn-text {
        color: $green;
      }
    }
  }

  &.alert {
    background-color: $red;
    &.border {
      background-color: transparent;
      border: rem-calc(1) solid $red;
      .btn-text {
        color: $red;
      }
    }
  }

  &.with-marginBottom {
    margin-bottom: $sizeSixteen;
  }

  &:disabled {
    opacity: 0.48;
    pointer-events: none;
  }

  &.not-centered {
    margin-right: $sizeEight;
    margin-left: $sizeEight;
  }

  &--back-modal {
    display: flex;
    align-items: center;
    margin-left: -$sizeFourteen;
  }

  &.link {
    min-width: rem-calc(200);
    min-height: $sizeThirty;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    .wrapper-icon {
      width: auto;
      height: auto;
    }
  }

  &.animation-color {
    animation-duration: 1s;
    animation-name: changeColor;
    animation-iteration-count: 3;

    @keyframes changeColor {
      0% {
        opacity: 1;
      }
      50% {
        opacity: 0.48;
      }
      100% {
        opacity: 1;
      }
    }
  }

}

.button-inside-tooltip {
  width: rem-calc(90);
  height: $sizeTwentyFour;
  margin: 0 auto;
  padding-right: 0;
  padding-left: 0;
}

.btn-icon-and-text {
  @include flex-row-horizontal-centered;
  height: $sizeThirty;
}

.btn-text {
  transition: all 300ms ease-out;
  color: white;
  font-family: $fontAvertaSemiBold;
  font-size: $sizeFourteen;

  &.bold {
    font-family: $fontAvertaBold;
  }

  &.grey-dark {
    color: $greyDark;
  }

  &.alert {
    font-size: $sizeTwenty;
    color: $blueLight;
    padding-top: $sizeTwenty;
    padding-bottom: $sizeTwenty;
  }
}

// tabs buttons

.wrapper-tabs {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: $sizeTwentyFour;

  .btn {
    margin-left: 0;
    margin-right: 0;
    &:first-child {
      margin-right: $sizeTwentyFour;
    }
  }
}

.btn-circle-icon {
  transition: all 250ms ease-in-out;
  background-color: $orange;
  border-radius: $sizeEighteen;
  color: white;
  cursor: pointer;
  width: rem-calc(36);
  height: rem-calc(36);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: $sizeTwelve;
  &[disabled],
  &.is-disabled {
    background-color: $greyDarkMedium;
    opacity: 0.15;
  }
}
