.wrapper-stackHeader {
  display: flex;
  flex-wrap: wrap;
  padding-bottom: $sizeTwenty;
}

.wrapper-stackHeader-title {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  flex: 1;
  justify-content: center;
}

.stackHeader-title {
  margin-left: $sizeEight;
}
