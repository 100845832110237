.wrapper-add-item {
  &.disabled {
    .content {
      .multiple-item {
        .text {
          color: $blueDark;
          font-size: $sizeEighteen;
          font-family: $fontAvertaSemiBold;
        }

        .wrapper-input {
          background-color: transparent;
          
          .input {
            background-color: transparent;
            padding-left: 0;
            font-size: $sizeFourteen;
            font-family: $fontAvertaRegular;
            color: $greyDark;
          }
        }
      }

      .add-item {
        display: none;
      }
    }
  }
  .content {
    .multiple-item {
      display: flex;
      position: relative;
      flex-direction: column;

      &:not(:first-child){
        .text {
          display: none;
        }
      }

      &.edit {

        .text {
          display: block;
        }

        &:not(:first-child){
          flex-direction: row;
          margin-top: $sizeFive;

          .text {
            opacity: 0;
          }
        }

      }

      .wrapper-input-multiple-items{
        width: 100%;
      }

      .text {
        font-family: $fontAvertaRegular;
        font-size: $sizeTwelve;
        color: $greyDark;

        
      }
      .wrapper-form-item {
        height: 100%;
        display: flex;
        align-items: center;
        flex-direction: column;
        align-items: flex-start;

        .icon-multiple-item {
          position: absolute;
          left: $sizeTwelve;
          border-radius: 50%;
          background-color: $red;
          cursor: pointer;
          top: $sizeTen;

          .wrapper-icon {
            width: $sizeSixteen;
            height: $sizeSixteen;
          }
        }

        .wrapper-input {
         // width: 95%;
         // margin-left: $sizeFourty;
           
        }

        .label {
          &.error {
            margin-left: $sizeFourty;
          }
        }
      }
    }
    .add-item {
      display: flex;
      padding-top: $sizeTen;
      padding-bottom: $sizeTen;
      margin-bottom: $sizeTwenty;
      .container-icon {
        cursor: pointer;
        .wrapper-icon {
          width: $sizeTwenty;
          height: $sizeTwenty;

          &.add-icon {
            border-radius: 50%;
            background-color: $blueLight;
          }
        }
      }

      .text {
        margin-left: $sizeTen;
        position: relative;
        font-family: $fontAvertaRegular;
        font-size: $sizeTwelve;
        color: $greyMedium;

        &.hidden{
          display: none;
        }

        .icon {
          color: $red;
          font-size: rem-calc(11);
          position: absolute;
          top: rem-calc(-2);
          right: rem-calc(-9);
        }
      }
    }
  }
}
