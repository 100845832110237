.wrapper-detailContentBlock {
  // margin-top: $sizeFourteen;
  // margin-bottom: $sizeTwentyFour;
}

.header-detailContentBlock {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.content-detailContentBlock {
  padding-top: $sizeTwelve;
  // padding-bottom: $sizeTwelve;

  &.one {
    display: block;

    .mediaBlock-wrapper {
      width: 100%;
    }
  }
}
