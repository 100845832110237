
  .main-wrapper {

    &.in-progress {
      display: flex;
      justify-content: center;
      align-items: center;
      
      .content {
        display: flex;
        flex-direction: column;
        align-items: center;

        .text {
          padding-bottom: $sizeSixteen;
        }

        .wrapper-icon {
          margin-left: $sizeTwenty;
          margin-bottom: $sizeEighteen;
        }
      }
    }
  }
