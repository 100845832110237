.wrapper-preheader {
  background-color: $greyLighter;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  min-height: $sizeThirtySix;

  .pre-header-block {
    display: flex;
    align-items: center;
  }

  .pre-header__item {
    position: relative;

    .pre-header__badge {
      background-color: $blueDark;
      border-radius: rem-calc(9);
      color: white;
      width: $sizeEighteen;
      height: $sizeEighteen;
      font-size: rem-calc(11);
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: -$sizeFour;
      right: -$sizeFour;
      z-index: 2;
      padding-top: rem-calc(1);
    }

    .pre-header__tooltip {
      transition: all 300ms ease-out;
      background-color: $greyDark;
      border-radius: $sizeFour;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: nowrap;
      position: absolute;
      top: 50%;
      left: calc(50% + 10px);
      transform: translateX(-50%);
      padding: $sizeFour;
      pointer-events: none;
      opacity: 0;
      z-index: -1;

      &::before {
        content: "";
        border-left: $sizeEight solid transparent;
        border-right: $sizeEight solid transparent;
        border-bottom: $sizeEight solid $greyDark;
        width: 0;
        height: 0;
        position: absolute;
        top: rem-calc(-7);
        left: 50%;
        transform: translateX(-50%);
      }

      .pre-header__tooltip__text {
        color: white;
        font-size: $sizeTwelve;
        font-family: $fontAvertaSemiBold;
        text-align: center;
      }
    }
    .wrapper-icon {
      margin-left: $sizeEighteen;
      cursor: pointer;
      &:hover {
        ~ .pre-header__tooltip {
          opacity: 1;
          top: calc(100% + 12px);
          z-index: $zIndexTooltip;
        }
      }
    }
  }
}
