.c-invited {
  &__selector {
    transition: all 300ms ease-in-out;
    display: grid;
    grid-template-rows: rem-calc(36) 0fr;
    $selector: &;

    &.is-active {
      grid-template-rows: rem-calc(36) 1fr;
    }

    &-label {
      transition: all 300ms ease-in-out;
      background-color: #f5f5f7;
      border-radius: 1.125rem;
      cursor: pointer;
      color: $blueDark;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-inline: 1rem;
      #{$selector}.is-active & {
        border-radius: 18px 18px 0 0 ;
      }
      &-chevron {
        transition: all 300ms ease-in-out;
        height: rem-calc(20);
      }
    }

    &-content {
      
      background-color: $greyLight;
      border-radius: 0 0 18px 18px;
      transition: all 300ms ease-in-out;
      visibility: hidden;
      overflow: hidden;
      min-height: 0;
      max-height: rem-calc(400);
      overflow-y: auto;
      padding-block: 0 rem-calc(12);
      #{$selector}.is-active & {
        visibility: visible;

      }
      &::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 3px;
        color: $greyDark;
        background-color: #f5f5f7;
        opacity: 1;
        visibility: visible;
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 4px;
        background-color: $greyDark;
      }

      .container-check .checkmark {
        background-color: white;
      }
    }

  }

}
