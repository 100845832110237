  .container-tooltip {
    background-color: white;
    box-shadow: $sizeFour 0 rem-calc(18) rgba(0,0,0,.2);
    border-radius: $sizeFourteen;
    cursor: pointer;
    width: rem-calc(200);
    min-height: fit-content;
    position: absolute;
    bottom: calc(100% + 4px);
    left: -$sizeSix;
    padding: $sizeEight;
    z-index: $zIndexTooltip;

    $b: &;

    &:before {
      content: "";
      background-color: white;
      width: $sizeTwelve;
      height: $sizeTwelve;
      display: block;
      position: absolute;
      bottom: -$sizeSix;
      left: $sizeEighteen;
      transform: rotate(45deg);

    }

    &.top {
      bottom: initial;
      top: calc(100% + 4px);
    }

    &.right {
      left: initial;
      right: -$sizeSix;

      &:before {
        left: initial;
        right: $sizeEighteen;
      }
    }


    &--no-triangle:before {
      display: none;
    }


    &.bottom {
      bottom: calc(100% + -100px);

      &:before {
        bottom: $sizeFiftyTwo;
      }
    }

    .tooltip-title {
      color: $blueDark;
      display: block;
      font-family: $fontAvertaBold;
      text-align: center;
      margin-bottom: $sizeEight;
      padding-bottom: 0;
    }

    .tooltip-text {
      font-family: $fontAvertaRegular;
      color: $greyDark;
      display: block;
      text-align: center;
      padding-bottom: 0;
      margin-bottom: $sizeSixteen;

      &:last-child {
        margin-bottom: 0;
      }
    }


    &-historical {
      width: max-content;
      max-width: rem-calc(320);
      color: $greyDark;
      text-align: left;
      padding: rem-calc(12);

      h4 {
        border-bottom: 1px solid $orange;
        color: $orange;
        display: block;
        font-family: $fontAvertaBold;
        font-size: 18px;
        margin-bottom: rem-calc(12);
        padding-bottom: rem-calc(4);
      }

      h5 {
        display: block;
        font-family: $fontAvertaBold;
        margin-bottom: rem-calc(6);
        padding-bottom: rem-calc(4);
      }

      .tooltip-text {
        text-align: left;
        margin-bottom: rem-calc(8);
      }

      ul {
        list-style: disc;
        margin-left: rem-calc(11);
      }

      li {
        font-family: $fontAvertaRegular;
        margin-bottom: rem-calc(6);
      }

    }

  }
  .tooltip-link {
    color: $orange;
    font-size: $sizeFourteen;
    cursor: pointer;
    position: relative;
    display: inline-block;
  }


    


// Tooltip
.has-tooltip {
  display: flex;
  width: 100%;
}
.wrapper-helper {
  min-width: $sizeThirtySix;
  height: $sizeThirtySix;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: $sizeTwelve;
  position: relative;

  &.height-auto {
    height: auto;
  }

  &.no-margin {
    margin: 0;
  }

  &.reset-to-center {
    margin-right: -$sizeThirtySix;
  }

  &.absolute {
    position: absolute;
    right: -2px;
  }

  .helper-text {
    cursor: pointer;
    font-size: 0;
    width: $sizeTwentyTwo; 
    height: $sizeTwentyTwo; 

    &.white {
      .filled {
        fill: currentColor;
      }
    }
  }

  }
// @Tooltip

