.box-wrapper-color {
  display: flex;
  flex-wrap: wrap;

  &__content {
    border-radius: 24px;
    background-color: $greyLight;
    cursor: pointer;
    width: rem-calc(180);
    height: rem-calc(130);
    //display: inline-block;
    display: block;
    padding: 10px;
    margin-right: $sizeTwentyFour;
    margin-bottom: $sizeTwentyFour;

    &--size-s{
      height: rem-calc(84);
    }

    .mediaBlock-wrapper {
      width: 100%;
    }

    .description {
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    
  }
}

.mediaBlock-wrapper {
  margin-bottom: $sizeSixteen;
  width: calc(50% - 24px);
  cursor: pointer;
  position: relative;

  .wrapper-detailContentBlock & {
    width: auto;
    //display: inline-block;
  }

  &--full-content {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
    

    &.center {
      justify-content: center;
    align-items: center;
    }
  }

  &.full-width {
    width: 100%;
  }

  .mediaBlock-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 100%;

    &--left {
      flex-direction: row;
      justify-content: flex-end;
    }
  }

  .mediaBlock-info-header {
    display: flex;
    justify-content: space-between;
    padding-left: $sizeFour;
    color: $blueDark;
    width: 100%;

    &--full-width {
      width: 100%;
      padding-left: 0;
      //font-size: $sizeSixteen;
      //font-family: $fontAvertaSemiBold;
    }

    &--margintop-ten {
      margin-top: $sizeTen;
    }

    .title2 {
     // @include text-shorten();
      flex: 1;
      color: $blueDark;

      ~ .body {
        margin-left: rem-calc(16);
      }
    }
  }

  .mediaBlock-info-header-text {
    text-align: center;
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    -moz-box-orient: vertical;
    /* autoprefixer: off */
    overflow-wrap: break-word;
    text-overflow: ellipsis;

    &--bold {
      font-family: $fontAvertaSemiBold;
    }

    &--left {
      text-align: left;
    }
  }

  .mediaBlock-info-header-icon {
    position: absolute;
    position: absolute;
    top: 0;
    right: $sizeTen;
  }

  .mediaBlock-documentTypeStyle {
    color: $greyMedium;
    font-size: $sizeTwelve;
  }

  .mediaBlock-content {
    max-width: 100%;
    padding-left: $sizeFourty;
    display: flex;
    flex-direction: column;

    &--full-width {
      padding-left: 0;
    }

  }

  .mediaBlock-content-text {
    text-align: center;
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    -moz-box-orient: vertical;
    /* autoprefixer: off */
    overflow-wrap: break-word;
    text-overflow: ellipsis;

    &--left {
      text-align: left;
    }
    &__subtitle {
      color: $orange;
    }
  }

  .mediaBlock-icon {
    padding-right: $sizeTen;
  }

  .mediaBlock-image {
    width: $sizeThirty;
    height: $sizeThirty;
    border-radius: 100%;
   // margin-right: $sizeTen;
    object-fit: cover;

    &.size-big {
      width: $sizeThirtyFive;
      height: $sizeThirtyFive;
    }
  }

  .mediaBlock-date {
    font-size: $sizeTwelve;
    padding-left: rem-calc(9);
  }
}
