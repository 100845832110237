.wrapper-cards {
  display: flex;
  width: calc(100% + 32px);
  position: relative;
  background-color: white;
  left: -$sizeSixteen;
  padding: $sizeSixteen;
  margin-bottom: $sizeTwentyFour;

    &--dashboard {
    @media (max-width: 1080px) {
      display: block;
    }
  }

  .eighty-percent {
    width: calc(70% - 7px);
    @media #{$lg-and-up} {
      width: calc(80% - 7px);
    }
  }

  .twenty-percent {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: calc(30% - 7px);

    @media #{$lg-and-up} {
      width: calc(20% - 7px);
    }
  }

  .container-cards {
    display: flex;
    width: 100%;
    justify-content: space-between;

    &.is-blur {
      .card .headingH3 {
        filter: blur(8px);
      }
    }

    &.grid {
      display: grid;
      grid-auto-flow: dense;
      column-gap: 8px;
      row-gap: rem-calc(8);
      &--col-3 {
        grid-template-columns: repeat(3, 1fr);
      }
      /* .card {
        // flex-wrap: wrap;

        .headingH3 {
          @media (max-width: 1440px) {
            font-size: 1.38888889vw;
          }
        }
      } */

    }

    &.small {
      flex-wrap: wrap;
      min-height: rem-calc(113);
      .cards-info {
        margin-top: 0;
      }

    }
  }

  .c-charts {
    transform: translateY(-37%);
    svg {
      rect {
        fill: transparent;
      }
    }
  }

  .cards-info {
    margin-top: $sizeEight;
    // font-family: $fontAvertaBold;
    font-size: $sizeTwelve;
    color: $greyMedium;
  }

  .card {
    background-color: $greyLight;
    // border: rem-calc(1) solid $orange;
    border-radius: $sizeTen;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;

    &.closed {
      .card-text {
        opacity: .35;
      }
    }

    &::before {
      content: attr(data-closed);
      position: absolute;
      top: -24px;
      left: 50%;
      transform: translateX(-50%);
      font-family: $fontAvertaBold;
      font-size: 14;
      color: $orange;
    }

    .card-text{
      font-size: $sizeSixteen;
      font-family: $fontAvertaBold;

      @media #{$lg-and-up} {
        font-size: $sizeEighteen;
      }
      
      &:not(:last-child) {
        margin-bottom: $sizeTen;
      }


      &.blue-light {
        color: $blueLight;
      }

      &.green {
        color: $green;
      }

      &.red {
        color: $red;
      }
    }

    &.big {
      padding: $sizeTen;
      max-height: rem-calc(113);
      min-height: rem-calc(113);

      &.four {
        width: calc((100% - 16px) / 4);
      }
      &.three {
        width: calc((100% - 16px) / 3);
      }
      &.two {
        width: calc((100% - 16px) / 2);
      }
      &.one {
        width: 100%;
      }
    }

    &.small {
      width: calc((100% - 5px) / 2);
      padding: $sizeFive;
      // max-height: $sizeFourtyEight;
      container-type: inline-size;
      &:nth-child(-n+2){
        margin-bottom: $sizeSixteen;
      }      

      .card-text {

        &.small {
          font-size: $sizeFourteen;
          display: flex;
          margin-bottom: 0;

          /* @media (max-width: 1440px) {
            font-size: 0.97222222vw;
          } */
        }
      }

      // .headingH3 {
      //   @media (min-width: 1080px) {
      //     font-size: 11cqw;
      //   }
      // }
    }

    &.in-grid {
      width: 100%;
      margin-bottom: 0;
      &:nth-child(-n+2){
        margin-bottom: 0;
      }      
      &:nth-child(1){
        grid-column: 1;
        grid-row: 1/3;
      }      
      &:nth-child(2){
        grid-column: 1;
      }      
      &:nth-child(3){
        grid-column: 2;
      }      
      &:nth-child(4){
      }      
      &:nth-child(5) {
        grid-column: 3;
        grid-row: 2 / 3;
        width: 48%;
        .headingH3 {
          @media (min-width: 1080px) {
            font-size: 23cqw;
          }
        }
      }
      &:nth-child(6) {
        grid-column: 3;
        grid-row: 2 / 3;
        width: 48%;
        margin-left: 52%;

        .headingH3 {
          @media (min-width: 1080px) {
            font-size: 23cqw;
          }
        }
      }      
      &:nth-child(7) {
        justify-self: end;
      }
    }

  }
}


.wrapper-cards {
  display: flex;
  display: flex;
  width: calc(100% + 32px);
  position: relative;
  background-color: white;
  left: -$sizeSixteen;
  padding: $sizeSixteen;
  margin-bottom: $sizeTwentyFour;

  .eighty-percent {

    width: calc(70% - 7px);

    @media #{$lg-and-up} {
      width: calc(80% - 7px);
    }
  }

  .twenty-percent {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: calc(30% - 7px);

    @media #{$lg-and-up} {
      width: calc(20% - 7px);
    }
  }

  .container-cards {
    display: flex;
    width: 100%;
    justify-content: space-between;

    &.small {
      flex-wrap: wrap;
      min-height: rem-calc(113);
      .cards-info {
        margin-top: 0;
      }
    }
  }

  .cards-info {
    margin-top: $sizeEight;
    font-family: $fontAvertaBold;
    font-size: $sizeTwelve;
    color: $greyMedium;
  }

  .card {
    border: rem-calc(1) solid $orange;
    border-radius: $sizeTen;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;

    &.closed {
      .card-text {
        opacity: .35;
      }
    }

    &::before {
      content: attr(data-closed);
      position: absolute;
      top: -24px;
      left: 50%;
      transform: translateX(-50%);
      font-family: $fontAvertaBold;
      font-size: 14;
      color: $orange;
    }

    .card-text{
      font-size: $sizeSixteen;
      font-family: $fontAvertaBold;

      @media #{$lg-and-up} {
        font-size: $sizeEighteen;
      }
      

      &:not(:last-child) {
        margin-bottom: $sizeTen;
      }


      &.blue-light {
        color: $blueLight;
      }

      &.green {
        color: $green;
      }

      &.red {
        color: $red;
      }
    }

    &.big {
      padding: $sizeTen;
      max-height: rem-calc(113);
      min-height: rem-calc(113);

      &.four {
        width: calc((100% - 16px) / 4);
      }
      &.three {
        width: calc((100% - 16px) / 3);
      }
      &.two {
        width: calc((100% - 16px) / 2);
      }
      &.one {
        width: 100%;
      }
    }

    &.small {
      width: calc((100% - 5px) / 2);
      padding: $sizeFive;
      max-height: $sizeFourtyEight;
      &:nth-child(-n+2){

        margin-bottom: $sizeSixteen;
 
      }      

      .card-text {

        &.small {
          font-size: $sizeFourteen;
          display: flex;
          margin-bottom: 0;

        }
      }
    }
  }
}
