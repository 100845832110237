.c-warning-banner {
  background-color: lighten($orange, 30%);
  border: 4px solid $orange;
  color: $blueDark;
  text-align: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  padding: 2vw;

  &__link {
    text-decoration: underline;
  }
}
