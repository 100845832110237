.hashListingBlock-wrapper {
  padding-top: $sizeThirtySix;
  padding-bottom: $sizeThirtySix;
  .hashListingBlock-container {
    display: flex;
    flex-direction: column;
    .hashListingBlock-content {
      display: flex;
      flex-direction: column;
      &.high {
        min-height: $sizeFourtyEight;
      }
      .description {
        font-size: $sizeFourteen;
        margin-top: $sizeFive;
        margin-bottom: $sizeTwenty;
      }
    }
  }
}
