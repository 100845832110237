.wrapper-label {
  position: relative;
  display: flex;
  align-items: center;
  margin-left: $sizeSixteen;
}

.label-arrow {
  @include absolute(initial, initial, initial, rem-calc(-33));
  z-index: $zIndexNormal; 
}

.label-close {
  @include absolute(initial, initial, initial, -$sizeTwelve);
  z-index: $zIndexNormal;
}

.wrapper-label-text {
  position: relative;
  height: $sizeTwentyEight;
  max-width: rem-calc(98); 
  background-color: $blueLight;
  border-top-right-radius: $sizeFour; 
  border-bottom-right-radius: $sizeFour;
  padding: 0 $sizeFourteen;
  @include flex-row-total-centered;
}