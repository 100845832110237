.modal-permission {
  margin-top: $sizeTwenty;
  overflow: hidden;
  
  @media #{$lg-and-up} {
    padding: 0 $sizeTwenty;
  }

  &__title {
    text-align: center;
    margin-bottom: $sizeSixteen;
  }

  &__content {
    overflow-y: scroll;
    max-height: rem-calc(400);
    padding: 0 $sizeFifteen;

    @media #{$lg-and-up} {
      height: 75vh;
      overflow-y: auto;
      max-height: 75vh;
      padding-bottom: $sizeSixtyFour;
    }

    &__text {
      margin-top: $sizeTwenty;
      margin-bottom: $sizeTwentyFour;
    }

    .slick-slider {
      @media #{$lg-and-up} {
        max-width: 60%;
        margin: auto;
      }
    }
  }

  .wrapper-icon {
    width: $sizeTwentyFour;
    height: $sizeTwentyFour;
    background-color: $blueLight;
    border-radius: 100%;
  }

  .slick-prev, .slick-next  {
    top: 50%;
    &::before {
      display: none;
    }
  }

  .slick-prev {
    right: -11px;
  }
  .slick-next {
    right: -9px;
  }
}
