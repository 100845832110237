.modal-change-user {
  width: auto;

  &__title {
    text-align: center;
    font-size: $sizeSixteen;
    margin-bottom: $sizeTwenty;
  }

  .options-multiple-select {
    max-height: rem-calc(340)
  }
}
