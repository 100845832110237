.wrapper-form {

  .wrapper-form__title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: $sizeThirty;
  }

}

.wrapper-form-item {
  margin-bottom: $sizeEight;
  height: rem-calc(80);
  width: 100%;

  &.has-requirementOptions {
      height: auto;
  }

  &.flex {
    display: flex;
    align-items: center;
  }

  &.textarea {
    height: rem-calc(140);

    &.disabled {
      .input-label {
        color: $blueDark;
        font-size: $sizeEighteen;
        font-family: $fontAvertaSemiBold;
      }
      .input {
        background-color: transparent;
        padding-left: 0;
        border-radius: 0;
        font-size: $sizeFourteen;
        font-family: $fontAvertaRegular;
        color: $greyDark;
      }

    }
  }

  &.auto {
    height: auto;
  }

  .btn {
    &.link {
      margin-top: $sizeTen;
      min-width: rem-calc(114);
    }

    &.left {
      margin-left: 0;
      margin-top: $sizeTen;
    }
  }

  .has-tooltip {
    .wrapper-input-radio {
      .container-radio-button {
        margin-bottom: 0;
      }
    }
  }

  &.disabled {
    .input-label {
      color: $blueDark;
      font-size: $sizeEighteen;
      font-family: $fontAvertaSemiBold;
    }

    
    .wrapper-input {
      background-color: transparent;
      
      .input, .input-number {
        background-color: transparent;
        padding-left: 0;
        font-size: $sizeFourteen;
        font-family: $fontAvertaRegular;
        color: $greyDark;
      }


      .react-datepicker-wrapper {

        .react-datepicker__input-container {
          input {
            background-color: transparent;
            padding-left: 0;
            font-size: $sizeFourteen;
            font-family: $fontAvertaRegular;
            color: $greyDark;
          } 
        }
      }
    }

    .wrapper-multiple-select {
      .container-multiple-select {
        background-color: transparent;
        padding-left: 0;
        font-size: $sizeFourteen;
        font-family: $fontAvertaRegular;
        color: $greyDark;
      }
    }
    
  }

  &.inviteds {
    min-height: 5rem;
    height: auto;
  }

}

.wrapper-input {
  transition: all 300ms ease-out;
  background-color: white;
  border-radius: $sizeEighteen;
  border: rem-calc(2) solid $greyMedium;
  position: relative;
  height: $sizeThirtySix;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: rem-calc(2);

  &.PhoneInput {
    padding-left: $sizeFourteen;
  }

  &.grey {
    background-color: $greyLight;
    border: 0;

    .PhoneInputInput {
        box-shadow: none;
        background-color: $greyLight;
    }

    .react-datepicker-wrapper {
      .react-datepicker-popper {
        .react-datepicker__input-container {
          input {
            background-color: $greyLight;
          }
        }
      }
    }
  }

  &.focus {
          box-shadow: 0 0 4px 1px rgba(118, 157, 199, 0.5);
  }

  &.success {

          background-color: $green !important;
          box-shadow: 0 0 4px 1px rgba(123, 169, 0, 0.5);
          border: rem-calc(1) solid $green;
          color: $green!important;
  }

  &.error {

          //background-color: $red !important;
          box-shadow: 0 0 4px 1px rgba(254, 64, 64, 0.5);
          border: rem-calc(1) solid $red;
          color: $red!important;
  }

  &.with-icon {
          padding-right: $sizeThirtySix;
  }

  &.with-icon-left {
          padding-left: $sizeThirtySix;
  }

  // &.with-border {
  //         border: rem-calc(1) solid $greyLight;
  // }

  &.transparent {
          //background-color: transparent;
  }

  .react-phone-number-input {
    width: 100%;
    margin-left: $sizeFourteen;
  }

  input {
    &.focus {
      box-shadow: none;
    }

    /*
     * &.error {
     *     &:-webkit-autofill ,
     *     &:-webkit-autofill:hover, 
     *     &:-webkit-autofill:active, 
     *     &:-webkit-autofill:focus {
     *         -webkit-box-shadow: 0 0 0px 1000px #fceff0 inset!important;
     *     }
     * }
     */
      
    &.error,
    &.succes {

      background-color: transparent;
      box-shadow: none;
      border: none;
      // color: transparent;
    }
  }
}

.wrapper-input-radio,
.wrapper-input-checkbox {
  display: flex;
  align-items: center;
  margin-right: $sizeTen;
}


.currency-wrapper {
  position: relative;

  &:before {
    position: absolute;
    z-index: 1;
    left: $sizeSixteen;
    top: 50%;
    transform: translateY(-50%);
    font-family: $fontAvertaRegular;
    font-size: $sizeFourteen;
    color: $blueDark;
    content: attr(data-mask-value);
  }

  &.transparent {
    &:before {
      color: transparent;
    }
  }

}

// Label
.input-label {
  color: $greyDark;
  font-family: $fontAvertaRegular;
  font-size: $sizeTwelve;
  margin-bottom: $sizeFour;

  .asterisk {
    color: $red;
    font-size: $sizeFourteen;
  }
}
// @Label

.label {
  font-family: $fontAvertaRegular;
  font-size: rem-calc(11);
  margin-top: rem-calc(1);

  &.error {
    color: $red;
    line-height: $sizeFourteen;
    display: block;
  }

  &.hint {
    color: $greyDark;
  }
  &--highlighted {
    color: $orange;
  }
}

.hint-special {
    color: $orange;
    display: flex;
    margin-top: 5px;
    align-items: center;
    .wrapper-icon-floatButton{
      box-shadow: none;
      margin-left: 5px;
    }
  }
// Search button
.input-button {
  width: $sizeTwentyFour;
  height: $sizeTwentyFour;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: $sizeFour;
  right: $sizeFourteen;
}
// @Search button


// titleAssets
  .line-separator {
    border-top: 1px solid $greyMedium;
    padding-top: $sizeTen;
    margin-top: $sizeTwenty;
  }
// @titleAssets

// assetToSelect
.block__box {
  display: flex;
  //overflow-x: auto;
  flex-wrap: wrap;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
  
   &--top {
    margin-top: $sizeThirty;
   }

   &--bottom {
    margin-bottom: $sizeThirty;
   }

    &-item {
      position: relative;
      margin: auto;
     // padding-bottom: $sizeSixteen;
      .delete-icon {
        top: -$sizeTen;
        right: rem-calc(-14);
      }

      &--padding-bottom-ten {
        padding-bottom: $sizeTen;
      }
    }
    
    &-item-content {
      min-width: rem-calc(100);
      max-width: rem-calc(100);
      height: rem-calc(100);
      margin: rem-calc(5);
      margin-top: $sizeEight;
      border-radius: 8px;
      @include flex-column-total-centered;
      padding: $sizeEight;
      position: relative;
      background-color: $greyLight;
      opacity: 0.5;
      justify-content: center;
      cursor: pointer;

    
      .truncate {
        width: rem-calc(84);
      }
      &.is-selected {
        //background-color: $blueLight;
        opacity: 1;
      }

      &--margin-top-zero {
        margin-top: 0;
      }
    }

    &-item-content-text {
      color: $greyDark;
      text-align: center;
      line-height: $sizeTwelve;
      margin-top: $sizeFive;
    }
    // .box-btn {
    //   background-color: $greyLight;
    //   opacity: 0.5;

    //   &.selected {
    //     background-color: $orange;
    //     opacity: 1;
    //   }
    // }

    &-item-icon {
      position: absolute;
      top: 1px;
      right: 0;
      z-index: 1;
    }

}
// @assetToSelect

// history files
.block__history {
  margin-bottom: $sizeThirty;
  &-title {
   font-size: $sizeEighteen;
   font-family: $fontAvertaSemiBold;
   color: $blueDark;
   margin-bottom: $sizeTen;
  }

  &-item {
    display: flex;
    &-label {
      margin-bottom: rem-calc(2);
    }
    &-info {
      margin-left: $sizeTen;
    }
  }
}
// @history files

// RequirementsOptions
.wrapper-requirements-options {
    margin-top: $sizeFour;
    .wrapper-requirements-options__list {
        position: relative;
        z-index: 2;
        .wrapper-requirements-options__list-item {
      
            flex-wrap: wrap;
            align-items: center;
            display: flex;
            min-height: $sizeTwenty;
            width: 100%;
            font-size: $sizeTwelve;
            .wrapper-requirements-options__list-icon {
                width: $sizeTwenty;
                height: $sizeTwenty;
                margin-top: rem-calc(-3);
            }
        }
    }
}
// @RequirementsOptions
