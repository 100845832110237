/* ==========================================================================
   #HIDE
   ========================================================================== */

// hide
.u-text-ellipsis{
    &-1 {
        display: -webkit-box;
        overflow: hidden;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        -moz-box-orient: vertical;
        /* autoprefixer: off */
    }
    &-2 {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
        /* autoprefixer: off */
    }
    &-3 {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
        overflow: hidden;
        /* autoprefixer: off */
    }
    &-4 {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 4;
        overflow: hidden;
        /* autoprefixer: off */
    }
    &-5 {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 5;
        overflow: hidden;
        /* autoprefixer: off */
    }
    &-6 {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 6;
        overflow: hidden;
        /* autoprefixer: off */
    }
}

