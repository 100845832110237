.home {
  .main-wrapper {
    background-color: transparent;
    padding: 0;

    //temp-styles
    .wrapper-temp-info {
      padding: $sizeTwelve;

      .content {
        display: flex;
        flex-direction: column;

        @media #{$lg-and-up} {
          flex-direction: row;
        }

        &:first-child {
          margin-bottom: $sizeEighteen;
          .container-text {
            padding-bottom: 0;
            @media #{$lg-and-up} {
              width: 50%;
              padding-top: 0;
            }
            display: block;
            .wrapper-news {
              width: 100%;
              margin-bottom: 0;
              @media #{$lg-and-up} {
                height: 100%;
              }
              &:first-child {
                border-bottom: 0;
              }
            }
          }
        }

        .img {
          max-width: 100%;
          object-fit: cover;
          height: 100%;
          max-height: rem-calc(180);

          @media #{$lg-and-up} {
            width: 50%;
          }
        }

        .container-text {
          width: 100%;
          display: flex;
          flex-wrap: wrap;

          &.padding {
            padding-top: $sizeTen;
            @media #{$lg-and-up} {
              padding-top: 0;
              padding-left: $sizeTen;
              padding-right: $sizeTen;
            }
          }

          .wrapper-news {
            width: calc(50% - 8px);
            min-height: rem-calc(70);
            overflow: hidden;
            position: relative;
            margin-right: $sizeEight;
            margin-bottom: $sizeTwelve;
            padding-bottom: $sizeTwentyFour;
            &:first-child,
            &:nth-child(2) {
              border-bottom: rem-calc(1) solid $greyDarkMedium;
            }

            .title {
              min-height: $sizeFourtyEight;
            }

            .text {
              margin-top: $sizeSix;
            }
            .legend {
              display: block;
              position: absolute;
              left: 0;
              bottom: 0;
            }
          }
        }
      }

      h2,
      h3,
      h4 {
        span {
          font-size: 18px !important;
        }
      }

      .headingH3 {
        &.orange {
          margin-top: 10px;
          font-size: 18px;
          color: $orange;
          font-style: italic;
          border-top: 1px solid $greyLighter;
          padding-top: 10px;
        }
      }

      .container {
        margin-bottom: 20px;
        .text {
          text-indent: 20px;
          margin-top: 10px;
          font-family: $fontAvertaRegular;

          .color {
            color: $blueLight;
            text-decoration: underline;
            font-family: $fontAvertaBold;
          }
        }
      }
    }

    .wrapper-content-block {
      width: 100%;

      .max-height {
        max-height: rem-calc(470);
        overflow: hidden;

        .nestedlist-ownership {
          max-height: initial;
        }
      }

      .content {
        &.scroll {
          overflow: auto;
        }
      }
    }

    .super-wrapper-content-block {
      display: flex;
      justify-content: space-between;

      .wrapper-content-block {
        width: calc(50% - 12px);
      }
    }

    .wrapper-barsGraphic {
      width: 100%;
      margin-right: auto;
      margin-left: auto;
    }

    .block-empty {
      display: flex;
      width: 100%;
      height: 100%;
      justify-content: center;
      align-items: center;
      
      &--margin-botom{
        margin-bottom: $sizeTwentyFour;
      }
      &__content {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $orange;
        border-radius: 50%;
        width: rem-calc(60);
        height: rem-calc(60);
        cursor: pointer;
      }
    }
  }
}
