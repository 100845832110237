$widthCell: rem-calc(182);
$widthFirstCell: rem-calc(206);

%table-cell-style {
  width: 100%;
  min-width: $widthCell;
  min-height: $sizeFourtyEight;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: $fontAvertaSemiBold;
  font-size: $sizeFourteen;
  padding: $sizeFour $sizeEight;
  position: relative;
}

.c-table {
  transition: all 500ms ease-in-out;
  opacity: 0;
  padding-bottom: 2px;
  margin: auto;
  $b: &;

  &__searcher {
    position: sticky;
    top: 0;
    left: 0;
    width: 100%;
    height: 5.8rem;
    margin-inline: auto;
  }

  &.has-searcher {
    margin-top: 5.8rem;
  }

  &__wrapper {
    width: 100%;
    overflow: auto;
    display: flex;
  }

  &__head {
    position: relative;

    &-row {
      display: flex;
      border-top-left-radius: $sizeEight;
      border-top-right-radius: $sizeEight;
      background-color: $blueDark;
    }

    &-cell {
      @extend %table-cell-style;
      color: $white;

      &--first {
        background-color: $blueDark;
        border-top-left-radius: $sizeEight;
        border-top-right-radius: $sizeEight;
        min-width: $widthFirstCell;
        width: $widthFirstCell;

        #{$b}.is-first-column-sticky & {
          position: sticky;
          left: 0;
          z-index: 2;
        }

      }

      // Using "first-child" better than "&--first" because of specifity in line 264
      &:first-child {
          #{$b}.is-first-column-big & {
            width: 100%;
            min-width: initial;
            flex: 1 1 100% ;
            max-width: rem-calc(509);
          }

          #{$b}.is-first-column-margin & {
            margin-right: 30px;
          }
      }

      #{$b}.is-first-column-big & {
        flex: 1;
      }


      &--last {
        max-width: rem-calc(96);
      }

      &--date-uploaded {
        max-width: rem-calc(152);
      }

      &--avatar {
        max-width: rem-calc(64);
        width: rem-calc(64);
        min-width: rem-calc(64);
      }

      &--tag {
        padding-top: rem-calc(42);
      }

      &-text {
        display: flex;
        align-items: center;
        min-height: $sizeThirtyTwo;
        justify-content: center;
      }
    }

    &-actions {
      min-width: max-content;
      height: rem-calc(36);
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      right: rem-calc(4);
      z-index: 1;
    }

    &-sort {
      width: rem-calc(16);
      margin-left: rem-calc(4);

      &--down,
      &--up {
        width: 100%;
        height: 50%;
      }
    }
  }

  &__tag {
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    color: $white;
    width: calc(#{$widthCell} * 3);
    right: $widthCell;
    top: $sizeEight;
    font-family: $fontAvertaSemiBold;

    &::before {
      position: absolute;
      width: 98%;
      content: "";
      border-bottom: 1px solid white;
      bottom: -$sizeSix;
    }

    &__text {
      text-align: center;
    }
  }

  &__text {
    text-align: center;

    &--left {
      text-align: left;
    }

    &--highlighted {
      color: $orange;
    }

    &--ellipsis {
      text-overflow: ellipsis;
      overflow: hidden;
    }

    &--tooltip {
      max-width: rem-calc(100px);
      width: auto !important;
    }
  }

  &__icon-link {
    cursor: pointer;
  }

  &__row {
    transition: opacity 350ms ease-in-out;
    display: flex;
    box-shadow: none;

    &:nth-child(odd) {
      background-color: $greyMedium;

      #{$b}.is-first-column-sticky & #{$b}__cell:first-child {
        background-color: $greyMedium;
        min-width: $widthFirstCell;
        position: sticky;
        left: 0;
        z-index: 3;
      }
    }

    &.preselected {
      border: 2px $blueDark solid;
    }

    &:last-child {
      box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
      border-bottom-left-radius: $sizeEight;
      border-bottom-right-radius: $sizeEight;
    }

    &--totals:last-child {
      background-color: $blueLight;
      border-top: 2px solid $blueDark;
      color: $white;
      box-shadow: none;

      #{$b}.is-first-column-sticky & #{$b}__cell:first-child {
        background-color: $blueLight;
        border-bottom-left-radius: 0.5rem;
        border-bottom-right-radius: 0.5rem;
        position: sticky;
        left: 0;
        z-index: 1;
      }
    }

    &--highlighted {
      background-color: $blueLight !important;
      color: $white;
    }

    #{$b}.has-row-selected & {
      opacity: 0.3;

      &.is-selected {
        opacity: 1;
      }
    }
  }

  &__cell {
    @extend %table-cell-style;

    &:first-child {
      min-width: $widthFirstCell;
      width: $widthFirstCell;

      #{$b}.is-first-column-sticky & {
        background-color: white;
        position: sticky;
        left: 0;
        z-index: 2;
      }

      #{$b}.is-first-column-big & {
        width: 100%;
        min-width: initial;
        flex: 1 1 100%;
        max-width: rem-calc(509);
      }
      #{$b}.is-first-column-margin & {
        margin-right: 30px;
      }
    }

    #{$b}.is-first-column-big & {
      flex: 1;
    }

    &--is-last {
      max-width: rem-calc(96);
    }

    &--date-uploaded {
      max-width: rem-calc(152);
    }

    &--avatar {
      max-width: rem-calc(64);
      width: rem-calc(64);

      &:first-child {
        min-width: rem-calc(64) !important;
      }
    }
  }

  &__avatar {
    object-fit: cover;
    width: rem-calc(48);
    min-width: rem-calc(48);
    height: rem-calc(48);
    border-radius: rem-calc(24);
  }
}
