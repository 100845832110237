.react-datepicker-popper {
  .react-datepicker {
    background: transparent;
    border: 0;
    font-family: $fontAvertaRegular;

    .react-datepicker__triangle {
      border-bottom-color: $blueLight;
      border-top-color: $blueLight;
      display: none;
    }
  }

  .react-datepicker__month-container {
    background: none;
  }

  .react-datepicker__header {
    background-color: $blueLight;
    border-top-right-radius: 24px;
    border-top-left-radius: 24px;
    color: $white;
  }

  .react-datepicker-time__header {
    color: $white;
  }

  /* .react-datepicker__navigation--previous {
    border-right-color: $white;
  }
  .react-datepicker__navigation--next {
    border-left-color: $white;
  } */

  .react-datepicker__day-name {
    color: $white;
  }

  .react-datepicker__month {
    background-color: $white;
    padding: 0.4rem;
    margin: 0;
  }

  .react-datepicker__current-month {
    color: $white;
    padding-bottom: $sizeTen;
    padding-top: $sizeFour;
  }

  .react-datepicker__day {
    line-height: 1.6rem;
    &:hover {
      border-radius: 50%;
      background-color: $greyMedium;
    }
    &--selected {
      border-radius: 50%;
      background-color: $blueDark;
    }
  }

  .react-datepicker__today-button {
    background-color: $blueLight;
    border-bottom-right-radius: 24px;
    border-bottom-left-radius: 24px;
    color: $white;
  }

  .react-datepicker__navigation-icon {
    &::before {
      border-color: $white;
    }
  }

}
