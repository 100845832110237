.services, .market {

    .content {
        overflow: visible;
    }

    .wrapper-tabs {
        display: flex;
        align-items: center;
        justify-content: center;
        padding-bottom: $sizeTwentyFour;

        .btn {
            margin-left: 0 ;
            margin-right: 0 ;
            &:first-child  {
                margin-right: $sizeTwentyFour;
            }
        }

    }

    .super-wrapper-content-block {
        .wrapper-content-block {
            margin-left: 0;
        }
    }

    .wrapper-content-block {
        .header {
            .wrapper-title {
                justify-content: flex-start;
            }
        }
    }


}
