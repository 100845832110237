/* ==========================================================================
   #FONTS AVERTA
   ========================================================================== */



@font-face {
  font-family: 'Averta-Regular';
  src: url("Averta-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Averta-Semibold';
  src: url("Averta-Semibold.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Averta-Bold';
  src: url("Averta-Bold.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }