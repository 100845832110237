.c-tabs {
  transition: all 300ms cubic-bezier(0.08, 0.63, 0.31, 1);
  cursor: pointer;
  height: $sizeFourtyEight;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 4.8rem;
  &:last-child {
    padding-right: 0;
  }

  $b: &;

  &__wrapper {
    border-bottom: 1px solid $greyMedium;
    width: 100%;
    position: relative;
    // margin-bottom: $sizeFourtyEight;
    margin-bottom: $sizeTwentyFour;
    overflow: hidden;

    &--parent {
      margin-bottom: $sizeThirtyTwo;
    }
    &--children {
      transition-delay: 250ms;
      margin-bottom: $sizeThirtyTwo;
      width: 100%;
    }
  }

  &__container {
    transition: all 500ms cubic-bezier(0.08, 0.63, 0.31, 1);
    width: 100%;
    display: flex;
    align-items: center;
    flex: 1;
    flex-wrap: nowrap;
    // overflow-x: auto;

    &.has-arrows {
      padding-right: rem-calc(24);
      padding-left: rem-calc(24);
    }
  }

  &__arrows {
    transition: all 500ms cubic-bezier(0.08, 0.63, 0.31, 1);
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: rem-calc(24);
    height: rem-calc(24);
    position: absolute;
    top: 0.8rem;
    z-index: 2;
    overflow: hidden;
    background: white;
    &--right {
      // background: linear-gradient(
      //   0deg,
      //   rgba(255, 255, 255, 1) 0,
      //   rgba(255, 255, 255, 0.75) 8px,
      //   rgba(255, 255, 255, 0) 100%
      // );
      right: 0;
      padding-right: .8rem;
    }
    &--left {
      // background: linear-gradient(
      //   90deg,
      //   rgba(255, 255, 255, 1) 0,
      //   rgba(255, 255, 255, 0.75) 8px,
      //   rgba(255, 255, 255, 0) 100%
      // );
      justify-content: flex-start;
      left: 0;
      padding-left: .8rem;
      opacity: 0;
      pointer-events: none;
    }
  }

  &__content {
    transition: all 500ms ease-in-out;
    // display: flex;
    // flex-direction: column;
    // justify-content: center;
    // align-items: center;
    // overflow: auto;
    width: 100%;
    opacity: 0;
    display: flex;
    flex-direction: column;
    position: relative;

    &.is-selected {
      animation-name: fadeIn;
      animation-duration: 0.5s;
      animation-delay: 0.4s;
      animation-fill-mode: forwards;
      margin-top: $sizeTwentyFour;
    }

    &-top {
      width: 100%;
      overflow: auto;
      display: flex;
      flex-direction: column;
      position: relative;
    }

    &-bottom {
      width: 100%;
      display: flex;
      flex-direction: column;
    }
  }

  &__title {
    text-align: center;
    margin-top: $sizeNinetySix;
    margin-bottom: $sizeTwentyFour;
  }

  &__text {
    width: max-content;
    height: 100%;
    display: flex;
    align-items: center;
    font-size: $sizeSixteen;
    position: relative;
    #{$b}.is-active & {
      font-family: $fontAvertaBold;
      color: $blueDark;
    }
    #{$b}__wrapper.is-highlighted #{$b}.is-active & {
      color: $orange;
    }

    #{$b}.is-active &:before {
      opacity: 1;
    }

    #{$b}__wrapper.is-highlighted &:before {
      background-color: $orange;
    }

    &:before {
      background-color: $blueDark;
      content: "";
      width: 100%;
      height: 3px;
      position: absolute;
      left: 0;
      bottom: 0;
      opacity: 0;
    }

    &-chevron {
      transition: all 750ms cubic-bezier(0.08, 0.63, 0.31, 1);
      &--open {
        transform: rotate(180deg);
      }
    }

  }

}



@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}


