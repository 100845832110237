.document,
.documents {
  &.document-detail {
    .main-wrapper {
      &.detail {
        width: 100%;
      }
    }
  }

  &.documents-show {
    .btn-back {
      display: flex;
      align-items: center;
    }
  }

  .wrapper-buttons {
    display: flex;
    justify-content: center;
    padding-top: $sizeThirtyTwo;
  }

  .mediaBlock-wrapper {
    .avatar {
      height: rem-calc(35);
      width: rem-calc(35);
      border-radius: 50%;
      border: rem-calc(1) solid black;
      margin-right: rem-calc(5);
      object-fit: cover;
    }
  }
}
