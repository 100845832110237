// Heading
.headingH1 {
  color: $blueDark;
  font-family: $fontAvertaBold;
  font-size: $sizeThirtyFive;
  line-height: $sizeThirtyFive;
}

.headingH2 {
  color: $blueDark;
  font-family: $fontAvertaBold;
  font-size: $sizeTwenty;
  line-height: $sizeTwenty;

  &.with-icon {
    margin-left: $sizeEight;
  }

}

.headingH3 {
  color: $blueDark;
  font-family: $fontAvertaBold;
  font-size: $sizeTwenty;
  line-height: $sizeTwenty;
}

.headingH4 {
  color: $blueDark;
  font-family: $fontAvertaBold;
  font-size: $sizeFourteen;
  line-height: $sizeEighteen;
}

.headingH5 {
  color: $blueDark;
  font-family: $fontAvertaSemiBold;
  font-size: $sizeFourteen;
  line-height: $sizeSixteen;
}
// @Heading

// Number
.numbers {
  color: $green;
  font-family: $fontAvertaBold;
  font-size: $sizeFourteen;
  line-height: $sizeFourteen;
}
// @Number

// Titles
.title1 {
  color: $blueDark;
  font-family: $fontAvertaRegular;
  font-size: $sizeEighteen;
  line-height: $sizeTwentyFour;

  &.blueSoft {
    color: $blueLight;
  }

  &.blue {
    color: $blueDark;
  }
}

.title2 {
  color: $blueDark;
  font-family: $fontAvertaRegular;
  font-size: $sizeFourteen;
  line-height: $sizeEighteen;

  &.blue {
    color: $blueLight;
  }
}
// @Titles

// Body
.body {
  &.blue-dark {
    color: $blueDark;
  }

  &.blue {
    color: $blueLight;
  }

  &.orangeMedium {
    color: $orange;
  }

  &.lime {
    color: $green;
  }

  &.coral {
    color: $red;
  }

  &.grey-dark {
    color: $greyDark;
  }

  &.grey-medium {
    color: $greyMedium;
  }

  &.white {
    color: white;
  }

  &.underlined {
    text-decoration: underline;
  }

  &.extra-small {
    font-size: rem-calc(11);
    line-height: $sizeSixteen;
  }

  &.small {
    font-size: $sizeTwelve;
    line-height: $sizeEighteen;
  }

  &.medium {
    font-size: $sizeFourteen;
    line-height: $sizeEighteen;
  }

  &.big {
    font-size: $sizeSixteen;
    line-height: $sizeTwenty;
  }

  &.bold {
    font-family: $fontAvertaBold;
  }
}
// @Body

// Date
.date {
  color: $greyDarkMedium;
  font-family: $fontAvertaRegular;
  font-size: $sizeTwelve;
  line-height: $sizeTwenty;
}
// @Date

// Balance
.balance {
  font-family: $fontAvertaRegular;
  font-size: $sizeFourteen;
  line-height: $sizeEighteen;

  &.blue {
    color: $blueLight;
  }

  &.orange {
    color: $orange;
  }
}
// @Balance

// Chart
.chart-text {
  color: $red;
  font-size: $sizeFourteen;
  line-height: $sizeTwenty;
  font-family: $fontAvertaSemiBold;

  &.bold {
    font-family: $fontAvertaBold;
  }
}

.chart-text-caption {
  color: $red;
  font-family: $fontAvertaRegular;
  font-size: $sizeTwelve;
  line-height: $sizeEighteen;

  &.blue {
    color: $blueDark;
  }

  &.grey {
    color: $greyDarkMedium;
  }
}

.chart-text-data {
  font-family: $fontAvertaRegular;
  font-size: $sizeSixteen;
  line-height: $sizeTwentyTwo;

  &.carmine {
    color: $red;
  }

  &.lime {
    color: $green;
  }

  &.blue {
    color: $blueLight;
  }
}
// @Chart
