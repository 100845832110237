/* ==========================================================================
#DISPLAY
========================================================================== */

.o-display {
  &--block {
    display: block;
  }
  &--grid {
    display: grid;
  }
  &--flex {
    display: flex;

    // Justify
    &-justify-center {
      justify-content: center;
    }
    // @Justify

    // Align-items
    &-align-center {
      align-items: center;
    }

    &-align-start {
      align-items: flex-start;
    }
    // @Align-items
  }
}
