$widthCell : rem-calc(148);
$widthCellHighighted: rem-calc(300);

.c-table-recent-orders {
  transition: all 500ms ease-in-out;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  opacity: 0;

  &.is-blur {
    p {
      filter: blur(8px);
    }
  }

  &__row {
    display: flex;
    width: 100%;

    &:not(:last-child){
      border-bottom: 1px solid $greyMedium;
    }
  }

  &__cell {
    width: 100%;
    min-width: $widthCell;
    min-height: $sizeFourtyEight;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: $sizeEight;

    &:first-child {
      max-width: rem-calc(230);
    }

    &:nth-child(2){
      flex: 1;
    }

    &--highlighted {
      min-width: $widthCellHighighted;
      justify-content: flex-start;
    }
    
    &--border {
      border-right: 1px solid $greyMedium;
    }

    &--link {
      width: rem-calc(48);
      min-width: rem-calc(48);
    }

    &--flex-start {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
  }

  &__cell-tag {
    min-width: $sizeFourtyEight;
    height: rem-calc(38);
    display: flex;
    justify-content: center;
    align-items: center;
    color: $white;
    font-family: $fontAvertaSemiBold;
    text-align: center;
    text-transform: uppercase;
    border-radius: $sizeEight;
    padding-right: $sizeEight;
    padding-left: $sizeEight;

    &--orange {
      background-color: $orange;
    }

    &--blue {
      background-color: $blueMedium;
    }

    &--gray {
      background-color: $greyDarkMedium;
    }
  }

  &__cell-text {
    &--bold {
      font-family: $fontAvertaSemiBold;
    }
  }
}
