.wrapper-floatButton {
  position: fixed;
  bottom: $sizeSeventySix;
  right: rem-calc(48);
  z-index: 500;

  @media #{$xxl-and-up} {
    right: calc((100vw - 1240px) / 2);
  }
}

.wrapper-icon-floatButton {
  background-color: $orange;
  border-radius: 50%;
  box-shadow: 0 2px 5px 4px rgba(0, 0, 0, 0.25);
}
