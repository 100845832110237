.detailTypeTwo-header {
  padding: $sizeSixteen 0;
}

.wrapper-summary {
  background-color: $blueLight;
  border-radius: $sizeEight;
  margin-bottom: $sizeTwentyFour;
  padding: $sizeEight;
  padding-bottom: 0;
}

.summary-item {
  padding-bottom: $sizeEight;
}

.summary-titles {
  color: $white;
  font-family: $fontAvertaBold;
  padding-right: $sizeFour;
}

.summary-values {
  color: $white;
  font-family: $fontAvertaRegular;
}

// Table Evolution
.header-table-evolution {
  background-color: $greyLight;
  border-radius: $sizeTwentyFour;
  display: flex;
  flex-wrap: wrap;
  padding: $sizeEighteen $sizeEighteen $sizeFour;
  margin: auto auto $sizeFourty;

  &__item {
    width: 50%;
    &:first-child {
      margin-right: $sizeTwentyFour;
    }

    &--align-right {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
    }
  }

  &__item-title {
    color: $blueDark;
    font-size: $sizeTwenty;
    min-width: 100%;
    flex: 1;
    margin-bottom: $sizeTwentyFour;
    font-size: $sizeTwenty;
  }

  &__item-text {
    color: $greyDark;
    font-size: $sizeSixteen;
    margin-bottom: $sizeTwelve;

    &-title {
      font-size: $sizeEighteen;
      color: $blueLight;
    }
  }
}
// @Table Evolution

