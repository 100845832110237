/* ==========================================================================
#TEXTALIGN
========================================================================== */

.o-text-align {
  // Center
  &--center {
    text-align: center;
  }
  // @Center
}
