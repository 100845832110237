.wrapper-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: $greyLighter;
  padding: $sizeThirty $sizeEighteen $sizeThirty $sizeTwentyFour;

  .title2 {
    font-size: $sizeTwelve;
    padding: 0 $sizeEight;

    &.middle {
      padding: 0 $sizeEight;
      border-right: rem-calc(1) solid $greyMedium;
      border-left: rem-calc(1) solid $greyMedium;
    }
  }
}
