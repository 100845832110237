.c-charts {
  width: 100%;
  position: relative;
  padding-top: $sizeFourtyEight;

    &.is-blur {
      text {
        filter: blur(8px);
      }
    }

  &__value-close-market {
    background-color: $greyLight; 
    border-radius: 8px 8px 0 0;
    padding: rem-calc(12);
    width: fit-content;
    position: absolute; 
    right: 0;
    top: rem-calc(40); 
    z-index: 2; 
  }

  &__filter-buttons {
    margin-top: $sizeTwentyFour;
    margin-bottom: $sizeTwelve;
    align-items: flex-end;
    flex-wrap: wrap;
    row-gap: rem-calc(12);
  }

  &__title {
    margin-bottom: $sizeFourtyEight;
    font-size: $sizeEighteen;
    font-family: $fontAvertaSemiBold;

    &__highlighted {
      color: $blueMedium;
    }

    &__centered {
      text-align: center;
    }
  }

  &__pie {
    overflow: visible !important;

    .highcharts-root {
      font-family: $fontAvertaRegular !important;
    }
    .highcharts-label text {
      color: $greyDark !important;
      fill: $greyDark !important;
      font-size: $sizeFourteen !important;
    }
    .highcharts-label {
      &-percentage {
        fill: $blueLight !important;
        color: $blueLight !important;
      }
    }
    .highcharts-credits {
      display: none;
    }

    .highcharts-tooltip > span {
      border-radius: 18px;
      font-family: $fontAvertaRegular;
    }

    .highcharts-label-percentage {
      tspan {
        display: none;
      }
    }
  }

  &__bottom-text {
    width: auto;
    &:first-child {
      margin-right: 56px;
    }
    &-title {
      color: $blueDark;
      font-family: $fontAvertaSemiBold;
      border-bottom: 1px solid $blueDark;
      margin-bottom: 24px;
    }
    &-wrapper {
      display: flex;
/*      margin-top: 32px;*/
      margin: auto;
    }
    &-paragraph {
      color: $blueDark;
      margin-bottom: 4px;
    }
  }
}
