.wrapper-filter {
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: $sizeTwenty;
  transition: all linear .2s;
  max-width: rem-calc(1292);
  width: calc(100% - 64px);
  height: rem-calc(70);


  &.fixed {
    position: fixed;
    top: 0;
    background-color: $white;
    padding-top: $sizeTwelve;
  }

  .container-filter-inputs {
    background-color: $white;
    width: 100%;
    display: flex;
    align-items: center;
    .wrapper-form-item{
      height: auto;
      margin-right: $sizeThirty;
      margin-bottom: 0;
      // input, select {
      //   background-color: white;
      // }
    }

    .container-multiple-select {
      height: $sizeFourty;
    }

    .wrapper-btn {
      height: $sizeFourtyEight;
      display: flex;
      align-items: flex-end;

      .icon-notification {
        cursor: pointer;
      }
    }
  }

  .container-filter-options {
    background-color: $white;
    display: flex;
    flex-wrap: wrap;
    padding-top: $sizeTen;
    padding: $sizeFive;
    .filter-option {
      font-size: $sizeTwelve;
      margin-right: $sizeEighteen;
      color: $greyDark;
      cursor: pointer;
      .filter-option-icon {
        margin-left: rem-calc(2);
      }
    }
  }
}
