/* ==========================================================================
   #GLOBAL
   ========================================================================== */

html,
body,
#root,
.main,
.wrapper {
  min-height: 100vh;
}

#root {
  display: flex;
  flex-direction: column;
}

body {
  background-color: $greyLighter;
  color: $greyDark;
  font-family: $fontAvertaRegular;
  font-size: $sizeFourteen;
  line-height: $sizeTwenty;
  overflow-x: hidden;
  user-select: none;

  &.open-sidebar {
    .wrapper-sidebar {
      position: fixed;
      top: 0;

      .container {
        opacity: 1;
      }
    }
  }

  &.has-modal {
    overflow: hidden;
  }

  .wrapper-main {
    transition: all 750ms cubic-bezier(0.22, 0.04, 0.05, 1);
    width: 100%;
    // position: absolute;
    // right: 0;
    // overflow-x: hidden;
    // display: flex;
    // flex-direction: column;
    background-color: $greyLighter;
    max-width: rem-calc(1360);
    margin: auto;
    padding-left: $sizeTwentyFour;
    padding-right: $sizeTwentyFour;
  }

  .full-height {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }
}

textarea {
  resize: none;
}

ul {
  &.inline {
    display: inline-flex;
    margin-bottom: $sizeThirtyTwo;

    li {
      margin-right: $sizeTwentyFour;
      &:last-child {
        margin-right: 0;
      }
    }
  }
  &.has-bullet {
    list-style-type: disc;
    margin-left: $sizeTwentyFour;
  }
}

ol {
  li {
    &:before {
      color: $orange;
    }
  }
}

a {
  color: $blueLight;

  &.link {
    color: $orange;
  }
}

strong {
  font-family: $fontAvertaBold;
}

button {
  -webkit-appearance: none !important;
  background-color: transparent;
  border: 0;
  font-size: 0;
  padding: 0;
}

img {
  display: block;
}

.main-wrapper {
  background-color: $white;
  border-radius: $sizeTwentyFour;
  padding: $sizeThirty $sizeSixteen $sizeTwentyFour;
  flex: 1;
  max-width: rem-calc(1360);
  margin: 0 auto;
  width: 100%;
  position: relative;

  &.has-dropdown-menu {
    overflow-x: hidden;
  }

  &.balance {
    padding-top: 0;
  }

  &.white {
    background-color: #fff;
    border-left: rem-calc(1) solid $greyLighter;
    border-right: rem-calc(1) solid $greyLighter;
  }

  .btn-back {
    display: flex;
    align-items: center;

    .wrapper-icon {
      width: 26px;
    }
  }

  .container-title {
    display: flex;
    justify-content: center;
    margin-bottom: $sizeTwenty;
  }

  .wrapper-fixed-btn {
    position: fixed;
    bottom: 0;
    width: calc(100% - 32px);
    background-color: white;
    height: rem-calc(80);
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.centered {
  text-align: center;
}

.flex {
  display: flex;
}

.truncate {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.wrapper-user {
  display: flex;
  align-items: center;
}

.user-data {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  padding-left: $sizeFifteen;
  padding-top: $sizeTen;
}

.text-total {
  font-family: $fontAvertaBold;
  font-size: $sizeFourty;
  line-height: $sizeFourty;
  display: block;
  text-align: center;
  padding-bottom: $sizeTwentyTwo;
  padding-top: $sizeFourteen;

  &.blue {
    color: $blueLight;
  }

  &.orange {
    color: $orange;
  }
}

.wrapper-separator {
  border-bottom: rem-calc(2) solid $greyLighter;
  max-width: rem-calc(1260px);

  &.overdue {
    opacity: 0.6;
  }
}

.centrate {
  display: flex;
}

.rotate {
  transform: rotate(180deg);
}

.filter-buttons-wrapper {
  display: flex;
  justify-content: center;
  width: 100%;

  .filter-buttons {
    margin: 0;

    &.btn {
      margin: 0 $sizeTwelve;
    }
  }
}

.twenty-percent {
  width: calc(20% - 7px);
  margin-left: rem-calc(7);
}

.fourty-percent {
  width: calc(40% - 7px);
  margin-left: rem-calc(7);
}

.sixty-percent {
  width: calc(60% - 7px);
  margin-right: rem-calc(7);
}

.eighty-percent {
  width: calc(80% - 7px);
  margin-left: rem-calc(7);
}

.wrapper-advertisement {
  background-color: white;
  width: 100%;
  height: calc(100% - 110px);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: fixed;
  top: rem-calc(110);
  left: 0;
  z-index: 2;
  .logo {
    position: absolute;
    top: $sizeThirtySix;
    left: 50%;
    transform: translateX(-50%);
  }
  .advertisement__icon {
    margin-top: $sizeTwentyFour;
    margin-bottom: $sizeTwentyFour;
  }
}

/* width */
::-webkit-scrollbar {
  width: $sizeSix;
}
::-webkit-scrollbar:horizontal {
  width: auto;
  height: $sizeSix;
}

/* Track */
::-webkit-scrollbar-track {
  background: $greyLighter;
}

/* Handle */
::-webkit-scrollbar-thumb {
  transition: all 300ms ease-out;
  background: $greyDark;
  border-radius: 3px;
}

// Modal timeout

.modal__time {
  text-align: center;
}

.modal__time--btn {
  margin-top: $sizeTwenty;
}

// @modal timeout

.highlight-text {
  font-family: $fontAvertaBold;
}

input {
  filter: none;

  &:-webkit-autofill {
    -webkit-text-fill-color: $blueDark !important;
    font-family: $fontAvertaRegular;
    -webkit-box-shadow: inset 0 0 0px 9999px white;
    -moz-box-shadow: inset 0 0 0px 9999px white;
  }

  &.grey {
    &:-webkit-autofill {
      -webkit-text-fill-color: $blueDark !important;
      font-family: $fontAvertaRegular;
      -webkit-box-shadow: inset 0 0 0px 9999px $greyLight;
      -moz-box-shadow: inset 0 0 0px 9999px $greyLight;
    }
  }
}

/* .fr-element span{
  font-family: 'Averta-Regular', sans-serif !important;
} */

.fr-element {
  //wysiwyg
  h1,
  h2,
  h3,
  h4, {
    span {
      font-family: $fontAvertaBold !important;
    }
  }

  p, 
  li, 
  a, 
  span, 
  iframe {
    font-family: 'Averta-Regular', sans-serif !important; 
    color: #89898a !important;
  } 

  li > p {
    margin-left: 0 !important;
  }

}

