.actionSheet-title {
  background-color: white;
  height: $sizeFourty;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: rem-calc(1);
}

.actionSheet-title-text {
  color: $blueLight;
  font-size: $sizeFourteen;
}

.actionSheet-button {
  background-color: white;
  height: rem-calc(50);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: rem-calc(1);
  width: 100%;
  border-bottom: rem-calc(1) solid $greyDarkMedium;

  &:first-child {
    border-top-right-radius: $sizeEight;
    border-top-left-radius: $sizeEight;
  }

  &:nth-last-child(2) {
    border-bottom-left-radius: $sizeEight;
    border-bottom-right-radius: $sizeEight;
  }
  
  &:last-child {
    border-radius: $sizeEight;
    border: none;
  }
}

.actionSheet-button-text {
  color: $greyDark;
  font-size: $sizeSixteen;

  &.alert {
    color: $orange;
  }
}

.actionSheet-cancel-text {
  color: $orange;
  font-size: $sizeSixteen;
}
