.c-calendar-datepicker {
  width: rem-calc(32);
  height: rem-calc(32);
  position: relative;
  margin-left: rem-calc(24);
  overflow: visible;

  &__button {
    width: rem-calc(32);
    height: rem-calc(32);
    margin-top: rem-calc(2);
  }

  &__info {
    border-radius: rem-calc(100);
    background-color: $blueLighter;
    width: rem-calc(20);
    height: rem-calc(20);
    position: absolute;
    top: 0;
    right: -24px;
    z-index: 5;
  }

  .react-datepicker__navigation {
    border: 0;
  }
  .react-datepicker__navigation-icon {
    top: rem-calc(-4);
    &::before,
    &::after {
      border-color: $white;
    }
  }

  .react-datepicker__triangle {
    display: none;
  }

  .react-datepicker-popper {
    z-index: 9;
  }

  .react-datepicker-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1;
    .react-datepicker__input-container {
      input {
        opacity: 0;
        border-radius: 0;
        height: 32px;
        cursor: pointer;
      }
    }

  }

  .react-datepicker__tab-loop {
    .react-datepicker-popper {
      width: 230px;
      transform: translate3d(-30px,40px,0) !important;

      .react-datepicker__month-container {
        border-radius: 0 0 rem-calc(24) rem-calc(24);
        overflow: hidden;
      }
    }
  }


}
