.wrapper-search {
  padding: $sizeTen 0;
  margin-bottom: $sizeSixteen;

  .wrapper-input {
    display: flex;
    padding-right: $sizeTwenty;
  }

  .btn-clear {
    position: relative;

    .wrapper-icon {
      left: $sizeFourteen;
    }
  }

  .btn-icon-right {
    color: $greyMedium;
    .wrapper-icon {
      position: absolute;
      left: 0;
    }
  }
}
