/* Blink -- begin */
.o-animation-blink {
  animation: blink 2s 2s 1.5 both;
}

@keyframes blink {
  0%,
  50%,
  100% {
    opacity: 1;
  }
  25%,
  75% {
    opacity: 0;
  }
}
/* Blink -- end */
