/* ==========================================================================
#FONT
========================================================================== */

html {
  body {
    .o-font {
      &--bold {
        font-family: $fontAvertaBold;
      }
    }
  }
}
