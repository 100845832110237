.nestedlist {
  width: 100%;

  &--padding {
    padding: $sizeSixteen;
  }

  &--top {
    margin-top: $sizeTwentyFour;
  }

  // > .nestedlist__container.open > .nestedlist__container .nestedlist__item,
  // > .nestedlist__container > .nestedlist__item.nestedlist__item {
  //   height: 48px;
  //   margin-bottom: 12px;
  // }
  .nestedlist__container {


    &--first {
      > .nestedlist__item {
        height: $sizeFourtyEight;
        margin-bottom: $sizeTwelve;
      }
    }

    // &.open > .nestedlist__container > .nestedlist__item {
    //     height: 48px;
    //     margin-bottom: 12px;
    //   }
  }

  .nestedlist__item {
    position: relative;
    transition: all linear 0.2s;
    overflow: hidden;
    border-radius: $sizeTwentyFour;
    height: 0;
    border-radius: $sizeTwentyFour;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: $sizeFourteen;
    padding-right: $sizeFourteen;
    cursor: pointer;

    &--white {
      background-color: white;
    }

    &--grey {
      background-color: $greyLight;
    }

    &--square {
      border-radius: 0;

    }

    // .open & {
    //   height: 48px;
    //   margin-bottom: 12px;
    // }

    // .open &-arrow {
    //   transition: all linear .1s;
    //   transform: rotate(180deg);
    // }
    &.is-open {
      height: $sizeFourtyEight;
      margin-bottom: $sizeTwelve;

      .nestedlist__item-arrow {
        transform: rotate(180deg);
      }
    }

    &.is-open
      ~ .nestedlist__container-sibling
      > .nestedlist__container
      > .nestedlist__item {
      height: $sizeFourtyEight;
      margin-bottom: rem-calc(9);
    }

    &-arrow {
      transition: all linear .2s;
      pointer-events: none;
      display: flex;
      align-items: center;
    }

    &-level-color {
      position: absolute;
      width: $sizeEight;
      height: $sizeEight;
      border-radius: 100%;
    }

    &-title {
      //pointer-events: none;
      font-size: $sizeSixteen;
      font-family: $fontAvertaSemiBold;
      color: $blueDark;

      &--blueLight {
        color: $blueLight;
      }

      &--grey {
        color: $greyDark;
      }

      &--orange {
        color: $orange;
      }
    }

    &-infoDocs {
      width: $sizeTwentyTwo;
      height: $sizeTwentyTwo;
      background-color: $blueLight;
      color: $white;
      font-size: $sizeFourteen;
      font-family: $fontAvertaSemiBold;
      border-radius: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      padding-top: 1px;
    }
  }
}
