.add-data {

  padding: $sizeSixteen  $sizeTwenty;
  //margin-bottom: 30px;
  transition: all linear 1s;

  &__datepicker {
    display: flex;
    flex: 1;
  }

  &__icon {
    display: flex;
    pointer-events: none;
  }

  &__item {
    height: $sizeFourtyEight;
    display: flex;
    align-items: center;
    background-color: $white;
    padding: 0 $sizeSixteen;
    position: relative;
  
    &--border {
      border-bottom: 1px solid $greyMedium;
    }
    &--full-height {
      height: 100%;
      padding-right: 0;
      padding-top: rem-calc(14);
    }

    &-icon {
      width: $sizeTwenty;
      height: $sizeTwenty;
      border-radius: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: $sizeTen;
      cursor: pointer;

      &--color-blue {
        background-color: $blueLight;
      }

      &--color-orange {
        background-color: $orange;
      }
    }

    &-label {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      
      &-text {
        width: rem-calc(142);
        border-right: 1px solid $greyMedium;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-right: $sizeTen;
        cursor: pointer;

        &--width {
          width: rem-calc(274);
        }
      }

      &-input-wrapper {
        position: relative;
        width: 100%;
      }

      &-input {
        height: 100%;
        flex: 1;

        &--height-fourty {
          height: $sizeFourty;
          border-radius: 0;

          &:not(:last-child){
            border-bottom: 1px solid $greyLight;
          }
        }
      }

      .react-phone-number-input input {
        background-color: white;
      }
    }

    &-icon-float {
      position: absolute;
      cursor: pointer;
      opacity: 0;
      height: 100%;
      background-color: white;
      width: 0px;
      display: flex;
      align-items: center;
      justify-content: center;
      right: 0px;
      visibility: hidden;
      transition: all linear .1s;
     

      &.is-visible {
        width: $sizeFourty;
        opacity: 1;
        visibility: visible;
      }

    }
  }

  &__phoneInput {
    flex: 1;
    margin-left: $sizeFourteen;
    margin-top: 0;
  }

  &__label {
    text-align: center;

    &-title {
      color: $blueDark;
      margin-bottom: $sizeTen;
    }
    &-list {

      &-item {
        padding: $sizeTen $sizeTen;
        cursor: pointer;
        &:last-child {
          padding-bottom: 0;
        }
      }
    }
  }
  
}
