.react-phone-number-input__input,
input,
select,
textarea {
  color: $blueDark;
  font-family: $fontAvertaRegular;
  font-size: $sizeFourteen;
  border: none;
  background-color: white;

  border-radius: $sizeEighteen;
  width: 100%;
  height: 100%;
  padding-right: $sizeSixteen;
  padding-left: $sizeSixteen;
  transition: all 300ms ease-out;

  &.grey {
    background-color: $greyLight;
    border: 0;
  }

  &.focus {
    box-shadow: 0 0 $sizeFour 1px rgba(118, 157, 199, 0.5);
  }

  &.success {
    background-color: $green;
    box-shadow: 0 0 $sizeFour 1px rgba(123, 169, 0, 0.5);
    border: rem-calc(1) solid $green;
    color: $green;
  }

  &.error {
    background-color: $red;
    box-shadow: 0 0 $sizeFour 1px rgba(254, 64, 64, 0.5);
    border: rem-calc(1) solid $red;
    color: $red;
  }

  &.background-transparent {
    background-color: transparent;
  }
}

input {
  &.search {
    padding-right: $sizeThirtySix;
  }

  &[type="file"] {
    display: none;
  }

  &[type="radio"] {
    display: flex;
    width: $sizeThirty;
    margin-bottom: 0;
  }

  &[type="checkbox"] {
    display: flex;
    width: $sizeThirty;
    margin-bottom: 0;
  }

  &[type="date"] {
    position: relative;
    color: $red;

    &:before {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: $sizeFour;
      color: $greyDark;
      font-family: $fontAvertaRegular;
      font-size: $sizeTwelve;
      content: attr(data-date);
      display: inline-block;
    }

    &::-webkit-datetime-edit,
    &::-webkit-inner-spin-button,
    &::-webkit-clear-button {
      display: none;
    }

    &::-webkit-calendar-picker-indicator {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 0;
      color: black;
      opacity: 1;
    }
  }

  &.red-placeholder {
    &::placeholder {
      color: $red;
    }
  }

  /*
   * &.aquamarine-placeholder {
   *   &::placeholder {
   *     color: aquamarine;
   *   }
   * }
   */

  &.grey-placeholder {
    &::placeholder {
      color: $greyDarkMedium;
    }
  }

  &.transparent {
    color: transparent;
  }
}

// datepicker
.react-datepicker-wrapper {
  width: 100%;

  .react-datepicker-popper {
    z-index: 9999;

  }
}


// @datepicker

// textarea
textarea {
  margin-top: rem-calc(2);
  min-height: rem-calc(104);
  padding-top: $sizeTen;
  border: rem-calc(2) solid $greyMedium;
  height: auto;

  &.grey {
    background-color: $greyLight;
    border: 0;
  }
}
// @textarea

.container-check {
  position: relative;
  cursor: pointer;
  user-select: none;
  margin-bottom: $sizeEighteen;

  input {
    position: absolute;
    opacity: 0;
    height: 0;
    width: 0;
    
  }

  .checkmark {
    position: absolute;
    top: -$sizeFour;
    left: 0;
    height: $sizeEighteen;
    width: $sizeEighteen;
    border-radius: $sizeFour;
    background-color: $greyLight;
    
    &::before {
      content: "";
      position: absolute;
      display: block;
      left: $sizeFour;
      top: -$sizeEight;
      width: rem-calc(9);
      height: rem-calc(21);
      border: solid $blueLight;
      border-width: 0 rem-calc(3) rem-calc(3) 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg) scale(0);
      z-index: 1;
      border-radius: rem-calc(2);
      transition: all 100ms ease-out;
      opacity: 0;
    }

  }

  input:checked ~ .checkmark:before {
    display: block;
    transform: rotate(45deg) scale(1);
    opacity: 1;
    
  }
}

.checkbox-label {
  position: relative;
  left: $sizeThirtySix;
}


//radiobutton

.container-radio-button {
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
  user-select: none;
  margin-bottom: $sizeEighteen;

  input {
    position: absolute;
    opacity: 0;
    height: 0;
    width: 0;
  }

  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: $sizeEighteen;
    width: $sizeEighteen;
    background-color: #eee;
    border-radius: 50%;

    &::before {
      content: "";
      position: absolute;
      display: none;
      left: 50%;
      top: 50%;
      width: rem-calc(14);
      height: rem-calc(14);
      background-color: $blueLight;
      z-index: 1;
      border-radius: 50%;
      transform: translate(-50%, -50%);
      
    }

    &::after {
      content: "";
      position: absolute;
      display: none;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      border-radius: $sizeFour;
      //background-color: $blueLight;
    }
  }

  input:checked ~ .checkmark:after,
  input:checked ~ .checkmark:before {
    display: block;
  }

  .radio-label {
    margin-left: $sizeThirty;
  }
}

//@radiobutton
