.drag-and-drop {
  $b: &;

  &.isOver {
    background: $greyLight;
  }

  &__box {
    border: 2px solid $blueDark;
    border-style: dashed;
    height: rem-calc(250);
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    padding: rem-calc(20);
  }

  &__box-wrapper-icon {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &__box-title {
    color: $blueDark;
    font-size: $sizeEighteen;
    font-family: $fontAvertaSemiBold;
    text-align: center;
  }

  &__box-subtitle {
    font-family: $fontAvertaSemiBold;
    text-align: center;
  }

  &__content {
    padding: rem-calc(10);
  }
  &__item {
    display: flex;
    align-items: center;
    padding-top: rem-calc(14);
    flex-direction: column;
  }

  &__item-wrapper {
    display: flex;
    align-items: center;
    width: 100%;
    margin-top: rem-calc(18);
    margin-bottom: rem-calc(16);
  }

  &__item-input-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    border-radius: rem-calc(18);
    padding: 0px 4px 0px 0;
    background-color: $greyLight;
    margin-left: rem-calc(10);
  }

  &__item-input {
    background-color: $greyLight;
    border: none;
    display: none;
  }

  &__item-title-text {
    margin: rem-calc(10) rem-calc(10);
  }

  &__item-icon {
    cursor: pointer;
  }

  &__item-status {
    margin-left: rem-calc(10);
    margin-right: rem-calc(10);
    width: rem-calc(28);

    .isDisabled {
      display: none;
    }
  }

  &__button {
    margin-top: rem-calc(24);
  }
}
