// Legend
.legend {
  font-size: $sizeTwelve;
  color: $greyDarkMedium;
  margin-top: rem-calc(42);

  &-text {
    font-size: $sizeSixteen;
  }
  
  &__item {
    display: flex;
    align-items: center;
    margin-bottom: $sizeTen;

    &-color {
      display: block;
      width: $sizeTwelve;
      height: $sizeTwelve;
      border-radius: $sizeEighteen;
    }
    &-text {
      font-size: $sizeSixteen;
      margin-left: $sizeTen;
    }
  }
}
// @Legend
