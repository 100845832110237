.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  min-width: rem-calc(28);
  min-height: rem-calc(28); 
  border-radius: 50%;
  border-left-color: $blueDark;
  animation: spin 1s ease infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
