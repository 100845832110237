.main-wrapper {
  &.contact {
    position: relative;

    .modal-header {

      &.contact {
        position: fixed;
        padding-top: $sizeTwenty;
        padding-bottom: $sizeTwenty;
        width: calc(100% - 32px);
        top: 0;
        border-radius: initial;
      }
    }

    .wrapper-search {
      margin-top: rem-calc(50);
    }

    .wrapper-floatButton {
      right: rem-calc(96);
    }

    .wrapper-content-block {
      margin-bottom: rem-calc(70);
      .content {
        .section-list-list {
          .section-list-item {
            .item-title {
              .wrapper-icon {
                display: none;
              }
            }
          }
        }
      }
    }

    .menu {
      position: fixed;
      bottom: 0;
      width: calc(100% - 32px);
      padding-top: $sizeTwenty;
      padding-bottom: $sizeTwenty;
      background-color: white;
    }
  }
}