.wrapper-table {
  position: relative;
  margin-bottom: $sizeFourtyEight;

  $b: &;

  &.center {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &.min-height {
    height: rem-calc(521);
  }

  &.min-height-md {
    min-height: rem-calc(842);
    height: rem-calc(521);
  }

  &.full-height {
    height: 100%;
  }

  // Table Evolution False Header
  &-false {
    transition: opacity 300ms ease-out, top 100ms ease-in-out;
    overflow: hidden !important;
    opacity: 0;
    max-height: 0;
    margin-bottom: 0;

    &-parent {
      transition: all 300ms ease-in-out;
      position: fixed;
      top: 0;
      overflow: hidden;
      z-index: 3;
    }

    .table {
      border-radius: 0;
      padding-top: rem-calc(126) !important;
      margin-top: 0;
    }
  }
  // @Table Evolution False Header
}

.table {
  background-color: $white;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
  border-radius: $sizeEight;
  display: inline-flex;
  position: relative;
  overflow: hidden;
  margin: 0 auto 0;

  .animated-handPointer {
    .handPointer {
      animation: animationFrames ease 1s 1s 2;
    }
  }

  &-preheader__wrapper {
    width: min-content;
    margin: rem-calc(40) auto 0;
  }

  &-preheader {
    display: inline-block;
    margin: rem-calc(12) 0 rem-calc(24);
    p {
      font-size: rem-calc(18);
      strong {
        color: $blueDark;
        font-family: $fontAvertaBold;
        span {
          color: $blueLight;
        }
      }
      span {
        color: $blueDark;
      }
    }

    &.active {
      p {
        strong {
          color: $orange;
        }
      }
    }
  }

  &.has-row-selected {
    .table-content,
    .rest-column {
      .td {
        .text {
          opacity: 0.3;
        }
        &.is-selected {
          .text {
            opacity: 1;
          }
        }
      }
    }
  }

  .handPointer {
    position: absolute;
    top: rem-calc(75);
    left: rem-calc(250);
    z-index: 9;
  }

  .td {
    color: $blueDark;
    width: rem-calc(200);
    min-height: $sizeFourtyEight;
    display: flex;
    font-size: $sizeFourteen;
    text-align: center;
    justify-content: center;
    align-items: center;
    padding: $sizeFour $sizeEight;
    position: relative;

    &--input {
      background-color: transparent;
    }

    &.width-s {
      width: rem-calc(105);
      min-width: rem-calc(105);
    }

    &.width-xs {
      width: rem-calc(96);
      min-width: rem-calc(96);
    }

    &.bg-light {
      &:before {
        background-color: rgba($blueLight, 0.3);
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }

    &.td-summatory {
      border-top: 2px solid $blueDark;
      border-bottom: 2px solid $blueDark;
    }
    &.td-highlighted {
      background-color: $blueLight;
      color: $white;
      font-family: $fontAvertaBold;
    }

    &.td-total_return_row {
      background-color: $greyDarkMedium;
      color: $white;
      font-family: $fontAvertaBold;
    }

    &.font-bold {
      font-family: $fontAvertaBold;
    }

    &.tds-first-row {
      color: $white;
      font-family: $fontAvertaSemiBold;
      font-size: $sizeFourteen;
      justify-content: center;
      align-items: center;
      .title {
        span {
          display: block;
          font-size: rem-calc(12);
        }
      }
    }

    &.with-link {
      cursor: pointer;
    }

    &.tooltip {
      position: relative;
      padding-right: $sizeThirtySix;

      .tooltip-wrapper {
        position: absolute;
        visibility: hidden;
        display: flex;
        justify-content: center;
        width: 100%;
        height: 100%;

        .tooltip-text {
          background-color: $blueDark;
          border-radius: $sizeFourteen;
          color: $white;
          text-align: center;
          position: absolute;
          bottom: 100%;
          left: 50%;
          visibility: hidden;
          transform: translateX(-50%);
          transition: opacity 0.3s;
          opacity: 0;
          padding: $sizeEight;
          z-index: $zIndexModal;

          &::after {
            content: "";
            position: absolute;
            top: 100%;
            left: 50%;
            margin-left: -$sizeFive;
            border-width: $sizeFive;
            border-style: solid;
            border-color: $blueDark transparent transparent transparent;
          }
        }
      }

      &:hover {
        .tooltip-text {
          visibility: visible;
          opacity: 1;
          z-index: 99999;
        }
      }
    }

    .text {
      transition: all 300ms ease-in-out;
    }

    .wrapper-helper {
      position: absolute;
      right: 0;
      top: 0;
      height: 100%;
    }

    .td-action-icon {
      &__tooltip {
        transition: all 300ms ease-out;
        background-color: $greyDark;
        border-radius: $sizeFour;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: nowrap;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%);
        padding: $sizeFour;
        pointer-events: none;
        opacity: 0;
        z-index: -1;

        &::before {
          content: "";
          border-left: $sizeEight solid transparent;
          border-right: $sizeEight solid transparent;
          border-bottom: $sizeEight solid $greyDark;
          width: 0;
          height: 0;
          position: absolute;
          top: rem-calc(-7);
          left: 50%;
          transform: translateX(-50%);
        }

        &-text {
          color: white;
          font-size: $sizeTwelve;
          font-family: $fontAvertaSemiBold;
          text-align: center;
        }
      }

      .wrapper-icon {
        cursor: pointer;
        &:hover {
          ~ .td-action-icon__tooltip {
            opacity: 1;
            top: 100%;
            z-index: $zIndexTooltip;
          }
        }
      }
    }
  }

  .first-column {
    position: relative;

    &.width-s {
      width: $sizeSixty;
      min-width: $sizeSixty;
    }

    &.table-height-initial {
      max-height: initial;
    }
    &.table-max-height {
      max-height: rem-calc(400);
    }

    .td {
      justify-content: flex-start;
      text-align: left;

      &:nth-child(odd) {
        background-color: $greyMedium;

        &.td-highlighted {
          background-color: $blueLight;
          color: $white;
          font-family: $fontAvertaBold;
        }

        &.td-total_return_row {
          background-color: $greyDarkMedium;
          color: $white;
          font-family: $fontAvertaBold;
        }
      }
      &.width-s {
        width: auto;
        min-width: $sizeNinetySix;
      }
    }

    > .td {
      &:first-child {
        background-color: $blueDark;
        border-bottom: 2px solid $blueDark;
        border-top-left-radius: 0.5rem;
        position: relative;
        justify-content: flex-start;
        z-index: 2;
      }
    }

    .rest-column {
      position: relative;
    }
  }

  .table-content {
    .first-row {
      border-top-right-radius: 0.5rem;
    }
  }

  .table-content,
  .rest-rows {
    &.table-height-initial {
      max-height: initial;
    }
    &.table-max-height {
      max-height: rem-calc(349);
    }

    &.show-overflow-y {
      overflow-y: auto;
    }

    &.hidden-overflow-y {
      overflow-y: hidden;
    }
  }

  .rest-rows {
    overflow: auto;
  }

  .wrapper-first-row {
    border-bottom: 2px solid $blueDark;
    height: $sizeFourtyEight;
  }

  .first-row {
    background-color: $blueDark;
    display: flex;
    align-items: center;
    position: relative;
    z-index: 1;
  }

  .each-row {
    display: flex;

    &:nth-child(odd) {
      background-color: $greyMedium;
    }

    &:last-child {
      .td {
        border-bottom: 0;
        &.td-summatory {
          border-top: 2px solid $blueDark;
          border-bottom: 2px solid $blueDark;
        }
      }
    }

    .td {
      &:last-child {
        border-right: 0;
      }
    }
  }

  .cell-icons {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    svg {
      cursor: pointer;
    }
  }

  // Table Evolution
  .title-evolution {
    position: absolute;
    top: rem-calc(-27);

    &__text {
      font-size: $sizeSixteen;
      font-family: $fontAvertaBold;
      color: $blueDark;
    }
  }
  .table-tag {
    position: absolute;
    width: rem-calc(1050);
    left: rem-calc(60);
    display: flex;
    height: rem-calc(90);
    top: rem-calc(-90);
    justify-content: space-between;

    &__item {
      font-size: $sizeSixteen;
      background-color: $blueLight;
      border-radius: $sizeEighteen $sizeEighteen 0 0;
      color: $white;
      font-family: $fontAvertaBold;
      text-align: center;

      display: flex;
      align-items: center;
      justify-content: center;
      padding: $sizeTen rem-calc(50);

      &--width-s {
        width: rem-calc(420);
      }

      &--width-m {
        width: rem-calc(625);
      }
    }
  }

  .icon-table-evolution {
    width: $sizeEighteen;
    height: $sizeEighteen;
    background-color: $blueLight;
    display: flex;
    align-items: center;
    border-radius: $sizeEighteen;
  }
  // @Table Evolution
}
