.c-logo {
  display: flex;
  &--big {
    align-items: center;
  }

  &--small {
    align-items: flex-start;
    padding-top: $sizeTwelve;
  }
}
