.tools-invitation {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: rem-calc(82);

  &--center {
    justify-content: center;
  }

  &__icon {
    cursor: pointer;
    width: $sizeThirtySix;
   &:not(:last-child){
     margin-right: $sizeSixteen;
   }
  }
}