/* ==========================================================================
#CALENDAR
========================================================================== */

#calendar {
  td.fc-today {
    background-color: $greyMedium;
  }

  .fc-toolbar {
    h2 {
      color: $blueDark;
    }
  }

  .fc-event-container {
    .fc-day-grid-event {
      background-color: $blueLight;
    }
  }

  .fc-button {
    transition: all 300ms ease-out;
    background-color: $blueLight;
    border: 0;
    box-shadow: none;
    border-radius: $sizeTwenty;
    margin-left: $sizeTwelve;

    &.fc-today-button {
      background-color: $greyMedium;
      font-family: $fontAvertaSemiBold;
      border: 0;

      &[disabled] {
        opacity: 0.3;
        color: $blueDark;
        cursor: default;
      }
    }

    .fc-icon {
      top: -1px;
      position: relative;
    }
  }
}
