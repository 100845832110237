.notifications {

  &.full-height {
    height: auto;
  }

  .wrapper-content-block {
    height: 100%;
  }

}
