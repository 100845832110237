.c-dashboard-hero {
  &__container {
    overflow-x: hidden;
  }
  &__cards {
    min-width: rem-calc(216);
    margin-right: rem-calc(12);
  }

  &__charts {
    display: flex;
    margin-bottom: rem-calc(20);

    .c-charts {
      padding-top: 0;
    }
  }

  &__chart {
    display: flex;
    align-items: center;
    height: rem-calc(300);
    // max-width: rem-calc(580);
    margin: 0 auto;
    overflow-y: hidden;
  }

  &__tooltip {
    transition: all 300ms ease-in-out;
    opacity: 0;
    width: fit-content;
    max-width: rem-calc(380);
  }

  @import "./_components.dashboard-hero-client.scss";
  @import "./_components.dashboard-hero-financial.scss";
}
