.wrapper-asstesList {
  padding-top: $sizeTwenty;
  max-width: rem-calc(500);
  margin: 0 auto;
}

.wrapper-asstesList-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: $sizeEight 0;
  cursor: pointer;
  margin-left: $sizeTwentyFour;
}
