.froala {
  .clearfix::after {
    clear: both;
    display: block;
    content: "";
    height: 0;
  }

  .hide-by-clipping {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
  }

  .fr-element,
  .fr-element:focus {
    outline: 0 solid transparent;
  }

  .fr-box.fr-basic {
    border-radius: 10px;
    -moz-border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-background-clip: padding;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
  }
  .fr-box.fr-basic .fr-element {
    font-family: sans-serif;
    color: #414141;
    font-size: 14px;
    line-height: 1.6;
    padding: 20px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    overflow-x: auto;
    min-height: 60px;
    text-align: left;
  }
  .fr-box.fr-basic.fr-rtl .fr-element {
    text-align: right;
  }

  .fr-element {
    background: transparent;
    position: relative;
    z-index: 2;
    -webkit-user-select: auto;

  }
  .fr-element a {
    user-select: auto;
    -o-user-select: auto;
    -moz-user-select: auto;
    -khtml-user-select: auto;
    -webkit-user-select: auto;
    -ms-user-select: auto;
  }
  .fr-element.fr-disabled {
    user-select: none;
    -o-user-select: none;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
  }
  .fr-element [contenteditable="true"] {
    outline: 0 solid transparent;
  }

  .fr-box a.fr-floating-btn {
    -webkit-box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2),
      0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
    -moz-box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2),
      0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
    box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2),
      0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
    border-radius: 100%;
    -moz-border-radius: 100%;
    -webkit-border-radius: 100%;
    -moz-background-clip: padding;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    height: 40px;
    width: 40px;
    text-align: center;
    background: #fff;
    color: #333333;
    -webkit-transition: background 0.2s ease 0s, color 0.2s ease 0s,
      transform 0.2s ease 0s;
    -moz-transition: background 0.2s ease 0s, color 0.2s ease 0s,
      transform 0.2s ease 0s;
    -ms-transition: background 0.2s ease 0s, color 0.2s ease 0s,
      transform 0.2s ease 0s;
    -o-transition: background 0.2s ease 0s, color 0.2s ease 0s,
      transform 0.2s ease 0s;
    outline: none;
    left: 0;
    top: 0;
    line-height: 40px;
    -webkit-transform: scale(0);
    -moz-transform: scale(0);
    -ms-transform: scale(0);
    -o-transform: scale(0);
    text-align: center;
    display: block;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    border: none;
  }
  .fr-box a.fr-floating-btn svg {
    -webkit-transition: transform 0.2s ease 0s;
    -moz-transition: transform 0.2s ease 0s;
    -ms-transition: transform 0.2s ease 0s;
    -o-transition: transform 0.2s ease 0s;
    fill: #333333;
  }
  .fr-box a.fr-floating-btn i,
  .fr-box a.fr-floating-btn svg {
    font-size: 14px;
    line-height: 40px;
  }
  .fr-box a.fr-floating-btn.fr-btn + .fr-btn {
    margin-left: 10px;
  }
  .fr-box a.fr-floating-btn:hover {
    background: #ebebeb;
    cursor: pointer;
  }
  .fr-box a.fr-floating-btn:hover svg {
    fill: #333333;
  }
  .fr-box .fr-visible a.fr-floating-btn {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
  }

  iframe.fr-iframe {
    width: 100%;
    border: none;
    position: relative;
    display: block;
    z-index: 2;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }

  .fr-wrapper {
    ol {
      li {
        a {
          color: $orange;
        }
      }
    }
    ul {
      margin-bottom: $sizeTwentyFour;
      li {
        position: relative;
        padding-left: $sizeTwentyFour;

        &:before {
          content: "";
          background-color: $orange;
          border-radius: $sizeFour;
          width: $sizeEight;
          height: $sizeEight;
          position: absolute;
          top: 50%;
          left: 0;
          transform: translateY(-50%);
        }

        a {
          color: $orange;
        }
      }
    }
  }

  .fr-wrapper {
    position: relative;
    z-index: 1;
    &::after {
      clear: both;
      display: block;
      content: "";
      height: 0;
    }
  }

  .fr-wrapper .fr-placeholder {
    position: absolute;
    font-size: 14px;
    color: #aaa;
    font-family: sans-serif;
    z-index: 1;
    display: none;
    top: 0;
    left: 0;
    right: 0;
    overflow: hidden;
  }
  .fr-wrapper.show-placeholder .fr-placeholder {
    display: block;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .fr-wrapper ::-moz-selection {
    background: #b5d6fd;
    color: #000;
  }
  .fr-wrapper ::selection {
    background: #b5d6fd;
    color: #000;
  }

  .fr-box.fr-basic .fr-wrapper {
    background: #fff;
    border: 1px solid #cccccc;
    border-bottom-color: #efefef;
    top: 0;
    left: 0;
  }

  .fr-box.fr-basic.fr-top .fr-wrapper {
    border-top: 0;
  }
  .fr-box.fr-basic.fr-bottom .fr-wrapper {
    border-bottom: 0;
    border-radius: 10px 10px 0 0;
    -moz-border-radius: 10px 10px 0 0;
    -webkit-border-radius: 10px 10px 0 0;
    -moz-background-clip: padding;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
  }

  @media (min-width: 992px) {
    .fr-box.fr-document {
      min-width: 21cm;
    }
    .fr-box.fr-document .fr-wrapper {
      text-align: left;
      padding: 30px;
      min-width: 21cm;
      background: #efefef;
    }
    .fr-box.fr-document .fr-wrapper .fr-element {
      text-align: left;
      background: #fff;
      width: 21cm;
      margin: auto;
      min-height: 26cm !important;
      padding: 1cm 2cm;
      overflow: visible;
      z-index: auto;
    }
    .fr-box.fr-document .fr-wrapper .fr-element hr {
      margin-left: -2cm;
      margin-right: -2cm;
      background: #efefef;
      height: 1cm;
      outline: none;
      border: none;
    }
    .fr-box.fr-document .fr-wrapper .fr-element img {
      z-index: 1;
    }
  }
  .fr-tooltip {
    position: absolute;
    top: 0;
    left: 0;
    padding: 0 8px;
    border-radius: 2px;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    -moz-background-clip: padding;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    background: #222222;
    color: #fff;
    font-size: 11px;
    line-height: 22px;
    font-family: Arial, Helvetica, sans-serif;
    -webkit-transition: opacity 0.2s ease 0s;
    -moz-transition: opacity 0.2s ease 0s;
    -ms-transition: opacity 0.2s ease 0s;
    -o-transition: opacity 0.2s ease 0s;
    -webkit-opacity: 0;
    -moz-opacity: 0;
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    left: -3000px;
    user-select: none;
    -o-user-select: none;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    z-index: 2147483647;
    text-rendering: optimizelegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  .fr-tooltip.fr-visible {
    -webkit-opacity: 1;
    -moz-opacity: 1;
    opacity: 1;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  }

  .fr-toolbar .fr-btn-wrap,
  .fr-popup .fr-btn-wrap,
  .fr-modal .fr-btn-wrap {
    float: left;
    white-space: nowrap;
    position: relative;
  }
  .fr-toolbar .fr-btn-wrap.fr-hidden,
  .fr-popup .fr-btn-wrap.fr-hidden,
  .fr-modal .fr-btn-wrap.fr-hidden {
    display: none;
  }
  .fr-toolbar .fr-command.fr-btn,
  .fr-popup .fr-command.fr-btn,
  .fr-modal .fr-command.fr-btn {
    background: transparent;
    color: #333333;
    -moz-outline: 0;
    outline: none;
    border: 0;
    line-height: 1;
    cursor: pointer;
    text-align: left;
    margin: 4px 2px;
    padding: 0;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -o-transition: all 0.5s;
    border-radius: 4px;
    -moz-border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-background-clip: padding;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    z-index: 2;
    position: relative;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    text-decoration: none;
    user-select: none;
    -o-user-select: none;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    float: left;
    height: 40px;
  }
  .fr-toolbar .fr-command.fr-btn.fr-dropdown.fr-options,
  .fr-popup .fr-command.fr-btn.fr-dropdown.fr-options,
  .fr-modal .fr-command.fr-btn.fr-dropdown.fr-options {
    border-radius: 0 4px 4px 0;
    -moz-border-radius: 0 4px 4px 0;
    -webkit-border-radius: 0 4px 4px 0;
    -moz-background-clip: padding;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
  }
  .fr-toolbar .fr-command.fr-btn.fr-btn-hover,
  .fr-popup .fr-command.fr-btn.fr-btn-hover,
  .fr-modal .fr-command.fr-btn.fr-btn-hover {
    border-radius: 4px 0 0 4px;
    -moz-border-radius: 4px 0 0 4px;
    -webkit-border-radius: 4px 0 0 4px;
    -moz-background-clip: padding;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
  }
  .fr-toolbar .fr-command.fr-btn::-moz-focus-inner,
  .fr-popup .fr-command.fr-btn::-moz-focus-inner,
  .fr-modal .fr-command.fr-btn::-moz-focus-inner {
    border: 0;
    padding: 0;
  }
  .fr-toolbar .fr-command.fr-btn.fr-btn-text,
  .fr-popup .fr-command.fr-btn.fr-btn-text,
  .fr-modal .fr-command.fr-btn.fr-btn-text {
    width: auto;
  }
  .fr-toolbar .fr-command.fr-btn i,
  .fr-toolbar .fr-command.fr-btn svg,
  .fr-popup .fr-command.fr-btn i,
  .fr-popup .fr-command.fr-btn svg,
  .fr-modal .fr-command.fr-btn i,
  .fr-modal .fr-command.fr-btn svg {
    display: block;
    text-align: center;
    float: none;
    margin: 8px 7px;
    width: 24px;
  }
  .fr-toolbar .fr-command.fr-btn svg.fr-svg,
  .fr-popup .fr-command.fr-btn svg.fr-svg,
  .fr-modal .fr-command.fr-btn svg.fr-svg {
    height: 24px;
  }
  .fr-toolbar .fr-command.fr-btn svg path,
  .fr-popup .fr-command.fr-btn svg path,
  .fr-modal .fr-command.fr-btn svg path {
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -o-transition: all 0.5s;
    fill: #333333;
  }
  .fr-toolbar .fr-command.fr-btn span.fr-sr-only,
  .fr-popup .fr-command.fr-btn span.fr-sr-only,
  .fr-modal .fr-command.fr-btn span.fr-sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
  }
  .fr-toolbar .fr-command.fr-btn span,
  .fr-popup .fr-command.fr-btn span,
  .fr-modal .fr-command.fr-btn span {
    font-size: 14px;
    display: block;
    line-height: 17px;
    min-width: 30px;
    float: left;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    height: 17px;
    font-weight: bold;
    padding: 0 4px;
  }
  .fr-toolbar .fr-command.fr-btn img,
  .fr-popup .fr-command.fr-btn img,
  .fr-modal .fr-command.fr-btn img {
    margin: 8px 7px;
    width: 24px;
  }
  .fr-toolbar .fr-command.fr-btn.fr-btn-active-popup,
  .fr-popup .fr-command.fr-btn.fr-btn-active-popup,
  .fr-modal .fr-command.fr-btn.fr-btn-active-popup {
    background: #d6d6d6;
  }
  .fr-toolbar .fr-command.fr-btn.fr-dropdown.fr-selection span,
  .fr-popup .fr-command.fr-btn.fr-dropdown.fr-selection span,
  .fr-modal .fr-command.fr-btn.fr-dropdown.fr-selection span {
    font-weight: normal;
  }
  .fr-toolbar .fr-command.fr-btn.fr-dropdown i,
  .fr-toolbar .fr-command.fr-btn.fr-dropdown span,
  .fr-toolbar .fr-command.fr-btn.fr-dropdown img,
  .fr-toolbar .fr-command.fr-btn.fr-dropdown svg,
  .fr-popup .fr-command.fr-btn.fr-dropdown i,
  .fr-popup .fr-command.fr-btn.fr-dropdown span,
  .fr-popup .fr-command.fr-btn.fr-dropdown img,
  .fr-popup .fr-command.fr-btn.fr-dropdown svg,
  .fr-modal .fr-command.fr-btn.fr-dropdown i,
  .fr-modal .fr-command.fr-btn.fr-dropdown span,
  .fr-modal .fr-command.fr-btn.fr-dropdown img,
  .fr-modal .fr-command.fr-btn.fr-dropdown svg {
    margin-left: 3px;
    margin-right: 11px;
  }
  .fr-toolbar .fr-command.fr-btn.fr-dropdown:after,
  .fr-popup .fr-command.fr-btn.fr-dropdown:after,
  .fr-modal .fr-command.fr-btn.fr-dropdown:after {
    position: absolute;
    width: 0;
    height: 0;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-top: 4px solid #333333;
    right: 2px;
    top: 18px;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -ms-transition: all 0.3s;
    -o-transition: all 0.3s;
    content: "";
  }
  .fr-toolbar .fr-command.fr-btn.fr-dropdown.fr-active,
  .fr-popup .fr-command.fr-btn.fr-dropdown.fr-active,
  .fr-modal .fr-command.fr-btn.fr-dropdown.fr-active {
    fill: #333333;
    background: #d6d6d6;
    -webkit-transition: 0.5s ease;
    -moz-transition: 0.5s ease;
    -ms-transition: 0.5s ease;
    -o-transition: 0.5s ease;
  }
  .fr-toolbar .fr-command.fr-btn.fr-dropdown.fr-active:hover,
  .fr-popup .fr-command.fr-btn.fr-dropdown.fr-active:hover,
  .fr-modal .fr-command.fr-btn.fr-dropdown.fr-active:hover {
    background: #ebebeb;
    fill: #333333;
  }
  .fr-toolbar .fr-command.fr-btn.fr-dropdown.fr-active:hover::after,
  .fr-popup .fr-command.fr-btn.fr-dropdown.fr-active:hover::after,
  .fr-modal .fr-command.fr-btn.fr-dropdown.fr-active:hover::after {
    border-top-color: #333333;
  }
  .fr-toolbar .fr-command.fr-btn.fr-dropdown.fr-active:after,
  .fr-popup .fr-command.fr-btn.fr-dropdown.fr-active:after,
  .fr-modal .fr-command.fr-btn.fr-dropdown.fr-active:after {
    border-top: 0;
    border-bottom: 4px solid #222222;
  }
  .fr-toolbar .fr-command.fr-btn.fr-disabled,
  .fr-popup .fr-command.fr-btn.fr-disabled,
  .fr-modal .fr-command.fr-btn.fr-disabled {
    color: #bdbdbd;
    cursor: default;
    -webkit-opacity: 0.3;
    -moz-opacity: 0.3;
    opacity: 0.3;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  }
  .fr-toolbar .fr-command.fr-btn.fr-disabled::after,
  .fr-popup .fr-command.fr-btn.fr-disabled::after,
  .fr-modal .fr-command.fr-btn.fr-disabled::after {
    border-top-color: #bdbdbd;
  }
  .fr-toolbar .fr-command.fr-btn.fr-hidden,
  .fr-popup .fr-command.fr-btn.fr-hidden,
  .fr-modal .fr-command.fr-btn.fr-hidden {
    display: none;
  }
  .fr-toolbar .fr-tabs .fr-command.fr-btn,
  .fr-popup .fr-tabs .fr-command.fr-btn,
  .fr-modal .fr-tabs .fr-command.fr-btn {
    margin: 0;
    width: 46px;
    height: 40px;
    border-radius: 0;
    -moz-border-radius: 0;
    -webkit-border-radius: 0;
    -moz-background-clip: padding;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
  }
  .fr-toolbar .fr-tabs .fr-command.fr-btn:not(:hover):not(:focus).fr-active,
  .fr-toolbar .fr-tabs .fr-command.fr-btn:not(:hover):not(:focus).fr-active-tab,
  .fr-popup .fr-tabs .fr-command.fr-btn:not(:hover):not(:focus).fr-active,
  .fr-popup .fr-tabs .fr-command.fr-btn:not(:hover):not(:focus).fr-active-tab,
  .fr-modal .fr-tabs .fr-command.fr-btn:not(:hover):not(:focus).fr-active,
  .fr-modal .fr-tabs .fr-command.fr-btn:not(:hover):not(:focus).fr-active-tab {
    background: #fff;
  }
  .fr-toolbar .fr-tabs .fr-command.fr-btn span,
  .fr-popup .fr-tabs .fr-command.fr-btn span,
  .fr-modal .fr-tabs .fr-command.fr-btn span {
    height: 27px;
  }
  .fr-toolbar .fr-tabs .fr-command.fr-btn img,
  .fr-toolbar .fr-tabs .fr-command.fr-btn svg,
  .fr-popup .fr-tabs .fr-command.fr-btn img,
  .fr-popup .fr-tabs .fr-command.fr-btn svg,
  .fr-modal .fr-tabs .fr-command.fr-btn img,
  .fr-modal .fr-tabs .fr-command.fr-btn svg {
    margin: 8px 11px;
    width: 24px;
    height: 24px;
  }
  .fr-toolbar
    .fr-btn-grp
    .fr-command.fr-btn.fr-active:not(.fr-dropdown)
    svg
    path,
  .fr-toolbar
    .fr-more-toolbar
    .fr-command.fr-btn.fr-active:not(.fr-dropdown)
    svg
    path,
  .fr-toolbar
    .fr-buttons:not(.fr-tabs)
    .fr-command.fr-btn.fr-active:not(.fr-dropdown)
    svg
    path,
  .fr-popup .fr-btn-grp .fr-command.fr-btn.fr-active:not(.fr-dropdown) svg path,
  .fr-popup
    .fr-more-toolbar
    .fr-command.fr-btn.fr-active:not(.fr-dropdown)
    svg
    path,
  .fr-popup
    .fr-buttons:not(.fr-tabs)
    .fr-command.fr-btn.fr-active:not(.fr-dropdown)
    svg
    path,
  .fr-modal .fr-btn-grp .fr-command.fr-btn.fr-active:not(.fr-dropdown) svg path,
  .fr-modal
    .fr-more-toolbar
    .fr-command.fr-btn.fr-active:not(.fr-dropdown)
    svg
    path,
  .fr-modal
    .fr-buttons:not(.fr-tabs)
    .fr-command.fr-btn.fr-active:not(.fr-dropdown)
    svg
    path {
    fill: #0098f7;
  }
  .fr-toolbar.fr-disabled .fr-btn,
  .fr-toolbar.fr-disabled .fr-btn.fr-active,
  .fr-popup.fr-disabled .fr-btn,
  .fr-popup.fr-disabled .fr-btn.fr-active,
  .fr-modal.fr-disabled .fr-btn,
  .fr-modal.fr-disabled .fr-btn.fr-active {
    color: #bdbdbd;
    -webkit-opacity: 0.3;
    -moz-opacity: 0.3;
    opacity: 0.3;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  }
  .fr-toolbar.fr-disabled .fr-btn.fr-dropdown::after,
  .fr-toolbar.fr-disabled .fr-btn.fr-active.fr-dropdown::after,
  .fr-popup.fr-disabled .fr-btn.fr-dropdown::after,
  .fr-popup.fr-disabled .fr-btn.fr-active.fr-dropdown::after,
  .fr-modal.fr-disabled .fr-btn.fr-dropdown::after,
  .fr-modal.fr-disabled .fr-btn.fr-active.fr-dropdown::after {
    border-top-color: #bdbdbd;
  }
  .fr-toolbar.fr-rtl .fr-btn-grp.fr-float-left,
  .fr-popup.fr-rtl .fr-btn-grp.fr-float-left,
  .fr-modal.fr-rtl .fr-btn-grp.fr-float-left {
    float: right;
  }
  .fr-toolbar.fr-rtl .fr-btn-grp.fr-float-right,
  .fr-popup.fr-rtl .fr-btn-grp.fr-float-right,
  .fr-modal.fr-rtl .fr-btn-grp.fr-float-right {
    float: left;
  }
  .fr-toolbar.fr-rtl .fr-command.fr-btn,
  .fr-toolbar.fr-rtl .fr-btn-wrap,
  .fr-popup.fr-rtl .fr-command.fr-btn,
  .fr-popup.fr-rtl .fr-btn-wrap,
  .fr-modal.fr-rtl .fr-command.fr-btn,
  .fr-modal.fr-rtl .fr-btn-wrap {
    float: right;
  }
  .fr-toolbar.fr-rtl .fr-command.fr-btn.fr-dropdown.fr-options,
  .fr-toolbar.fr-rtl .fr-btn-wrap.fr-dropdown.fr-options,
  .fr-popup.fr-rtl .fr-command.fr-btn.fr-dropdown.fr-options,
  .fr-popup.fr-rtl .fr-btn-wrap.fr-dropdown.fr-options,
  .fr-modal.fr-rtl .fr-command.fr-btn.fr-dropdown.fr-options,
  .fr-modal.fr-rtl .fr-btn-wrap.fr-dropdown.fr-options {
    border-radius: 4px 0 0 4px;
    -moz-border-radius: 4px 0 0 4px;
    -webkit-border-radius: 4px 0 0 4px;
    -moz-background-clip: padding;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
  }
  .fr-toolbar.fr-rtl .fr-command.fr-btn.fr-btn-hover,
  .fr-toolbar.fr-rtl .fr-btn-wrap.fr-btn-hover,
  .fr-popup.fr-rtl .fr-command.fr-btn.fr-btn-hover,
  .fr-popup.fr-rtl .fr-btn-wrap.fr-btn-hover,
  .fr-modal.fr-rtl .fr-command.fr-btn.fr-btn-hover,
  .fr-modal.fr-rtl .fr-btn-wrap.fr-btn-hover {
    border-radius: 0 4px 4px 0;
    -moz-border-radius: 0 4px 4px 0;
    -webkit-border-radius: 0 4px 4px 0;
    -moz-background-clip: padding;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
  }

  .fr-toolbar.fr-inline > .fr-command.fr-btn:not(.fr-hidden),
  .fr-toolbar.fr-inline > .fr-btn-wrap:not(.fr-hidden) {
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    float: none;
  }

  .fr-desktop .fr-command:hover,
  .fr-desktop .fr-command:focus,
  .fr-desktop .fr-command.fr-btn-hover,
  .fr-desktop .fr-command.fr-expanded {
    outline: 0;
    color: #333333;
  }
  .fr-desktop .fr-command:hover:not(.fr-table-cell),
  .fr-desktop .fr-command:focus:not(.fr-table-cell),
  .fr-desktop .fr-command.fr-btn-hover:not(.fr-table-cell),
  .fr-desktop .fr-command.fr-expanded:not(.fr-table-cell) {
    background: #ebebeb;
  }
  .fr-desktop .fr-command:hover::after,
  .fr-desktop .fr-command:focus::after,
  .fr-desktop .fr-command.fr-btn-hover::after,
  .fr-desktop .fr-command.fr-expanded::after {
    border-top-color: #333333;
  }
  .fr-desktop .fr-command.fr-selected:not(.fr-table-cell),
  .fr-desktop .fr-command:active {
    color: #333333;
    background: #d6d6d6;
  }
  .fr-desktop .fr-command.fr-active:hover,
  .fr-desktop .fr-command.fr-active:focus,
  .fr-desktop .fr-command.fr-active.fr-btn-hover,
  .fr-desktop .fr-command.fr-active.fr-expanded {
    background: #ebebeb;
  }
  .fr-desktop .fr-command.fr-active:active {
    background: #d6d6d6;
  }
  .fr-desktop .fr-command.fr-disabled:hover,
  .fr-desktop .fr-command.fr-disabled:focus,
  .fr-desktop .fr-command.fr-disabled.fr-selected {
    background: transparent;
  }
  .fr-desktop.fr-disabled .fr-command:hover,
  .fr-desktop.fr-disabled .fr-command:focus,
  .fr-desktop.fr-disabled .fr-command.fr-selected {
    background: transparent;
  }

  .fr-toolbar.fr-mobile .fr-command.fr-blink,
  .fr-popup.fr-mobile .fr-command.fr-blink {
    background: #d6d6d6;
  }

  .fr-command.fr-btn.fr-options {
    width: 16px;
    margin-left: -5px;
  }
  .fr-command.fr-btn.fr-options.fr-btn-hover,
  .fr-command.fr-btn.fr-options:hover,
  .fr-command.fr-btn.fr-options:focus {
    border-left: solid 1px #fafafa;
    -webkit-transition: border-left 0s, background-color 0.5s;
    -moz-transition: border-left 0s, background-color 0.5s;
    -ms-transition: border-left 0s, background-color 0.5s;
    -o-transition: border-left 0s, background-color 0.5s;
  }
  .fr-command.fr-btn + .fr-dropdown-menu {
    background: #fff;
    display: inline-block;
    position: absolute;
    right: auto;
    bottom: auto;
    height: auto;
    z-index: 4;
    border-radius: 4px;
    -moz-border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-background-clip: padding;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    -webkit-overflow-scrolling: touch;
    overflow: hidden;
    zoom: 1;
    -webkit-box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2),
      0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
    -moz-box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2),
      0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
    box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2),
      0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  }
  .fr-command.fr-btn + .fr-dropdown-menu.test-height .fr-dropdown-wrapper {
    -webkit-transition: none;
    -moz-transition: none;
    -ms-transition: none;
    -o-transition: none;
    height: auto;
    max-height: 275px;
  }
  .fr-command.fr-btn + .fr-dropdown-menu .fr-dropdown-wrapper {
    padding: 0;
    margin: auto;
    display: inline-block;
    text-align: left;
    position: relative;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-transition: height 0.3s;
    -moz-transition: height 0.3s;
    -ms-transition: height 0.3s;
    -o-transition: height 0.3s;
    margin-top: 0;
    float: left;
    height: 0;
    margin-top: 0 !important;
  }
  .fr-command.fr-btn
    + .fr-dropdown-menu
    .fr-dropdown-wrapper
    .fr-dropdown-content {
    position: relative;
  }
  .fr-command.fr-btn
    + .fr-dropdown-menu
    .fr-dropdown-wrapper
    .fr-dropdown-content
    ul.fr-dropdown-list {
    list-style-type: none;
    margin: 0;
    padding: 8px 0;
    min-width: 72px;
  }
  .fr-command.fr-btn
    + .fr-dropdown-menu
    .fr-dropdown-wrapper
    .fr-dropdown-content
    ul.fr-dropdown-list
    li {
    padding: 0;
    margin: 0;
    font-size: 15px;
  }
  .fr-command.fr-btn
    + .fr-dropdown-menu
    .fr-dropdown-wrapper
    .fr-dropdown-content
    ul.fr-dropdown-list
    li
    a {
    padding: 0 20px;
    line-height: 200%;
    display: flex;
    cursor: pointer;
    white-space: nowrap;
    color: inherit;
    text-decoration: none;
    border-radius: 0;
    -moz-border-radius: 0;
    -webkit-border-radius: 0;
    -moz-background-clip: padding;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
  }
  .fr-command.fr-btn
    + .fr-dropdown-menu
    .fr-dropdown-wrapper
    .fr-dropdown-content
    ul.fr-dropdown-list
    li
    a
    svg {
    width: 24px;
    height: 24px;
    margin: 3px 4px;
  }
  .fr-command.fr-btn
    + .fr-dropdown-menu
    .fr-dropdown-wrapper
    .fr-dropdown-content
    ul.fr-dropdown-list
    li
    a
    svg
    path {
    fill: #333333;
  }
  .fr-command.fr-btn
    + .fr-dropdown-menu
    .fr-dropdown-wrapper
    .fr-dropdown-content
    ul.fr-dropdown-list
    li
    a.fr-active {
    background: #d6d6d6;
  }
  .fr-command.fr-btn
    + .fr-dropdown-menu
    .fr-dropdown-wrapper
    .fr-dropdown-content
    ul.fr-dropdown-list
    li
    a.fr-disabled {
    color: #bdbdbd;
    cursor: default;
  }
  .fr-command.fr-btn
    + .fr-dropdown-menu
    .fr-dropdown-wrapper
    .fr-dropdown-content
    ul.fr-dropdown-list
    li
    a
    .fr-shortcut {
    margin-left: 20px;
    font-weight: bold;
    -webkit-opacity: 0.75;
    -moz-opacity: 0.75;
    opacity: 0.75;
  }
  .fr-command.fr-btn.fr-active + .fr-dropdown-menu {
    display: inline-block;
    -webkit-box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2),
      0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
    -moz-box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2),
      0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
    box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2),
      0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  }

  .fr-bottom > .fr-command.fr-btn + .fr-dropdown-menu {
    border-radius: 2px 2px 0 0;
    -moz-border-radius: 2px 2px 0 0;
    -webkit-border-radius: 2px 2px 0 0;
    -moz-background-clip: padding;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
  }

  .fr-toolbar.fr-rtl .fr-dropdown-wrapper,
  .fr-popup.fr-rtl .fr-dropdown-wrapper {
    text-align: right !important;
  }

  body.prevent-scroll {
    overflow: hidden;
  }
  body.prevent-scroll.fr-mobile {
    position: fixed;
    -webkit-overflow-scrolling: touch;
  }

  .fr-modal {
    color: #222222;
    font-family: Arial, Helvetica, sans-serif;
    position: fixed;
    overflow-x: auto;
    overflow-y: scroll;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    z-index: 2147483640;
    text-rendering: optimizelegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    line-height: 1.2;
  }
  .fr-modal.fr-middle .fr-modal-wrapper {
    margin-top: 0;
    margin-bottom: 0;
    margin-left: auto;
    margin-right: auto;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    position: absolute;
  }
  .fr-modal .fr-modal-wrapper {
    border-radius: 10px;
    -moz-border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-background-clip: padding;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    background-clip: padding-box;
    margin: 20px auto;
    display: inline-block;
    background: #fff;
    min-width: 300px;
    -webkit-box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2),
      0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12);
    -moz-box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2),
      0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12);
    box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2),
      0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12);
    border: 0;
    overflow: hidden;
    width: 90%;
    position: relative;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    .fr-modal .fr-modal-wrapper {
      margin: 30px auto;
      width: 70%;
    }
  }
  @media (min-width: 992px) {
    .fr-modal .fr-modal-wrapper {
      margin: 50px auto;
      width: 960px;
    }
  }
  .fr-modal .fr-modal-wrapper .fr-modal-head {
    background: #fff;
    overflow: hidden;
    position: absolute;
    width: 100%;
    min-height: 42px;
    z-index: 3;
    border-bottom: solid #efefef 1px;
    -webkit-transition: all 0.2s ease 0s;
    -moz-transition: all 0.2s ease 0s;
    -ms-transition: all 0.2s ease 0s;
    -o-transition: all 0.2s ease 0s;
  }
  .fr-modal .fr-modal-wrapper .fr-modal-head .fr-modal-head-line {
    height: 56px;
    padding: 0 10px;
  }
  .fr-modal .fr-modal-wrapper .fr-modal-head .fr-modal-close {
    margin: 10px;
    position: absolute;
    top: 0;
    right: 0;
  }
  .fr-modal .fr-modal-wrapper .fr-modal-head h4 {
    font-size: 20px;
    padding: 19px 10px;
    margin: 0;
    font-weight: 400;
    line-height: 18px;
    display: inline-block;
    float: left;
  }
  .fr-modal .fr-modal-wrapper div.fr-modal-body {
    height: 100%;
    min-height: 150px;
    overflow-y: auto;
    padding-bottom: 20px;
  }
  .fr-modal .fr-modal-wrapper div.fr-modal-body:focus {
    outline: 0;
  }
  .fr-modal .fr-modal-wrapper div.fr-modal-body button.fr-command {
    height: 36px;
    line-height: 1;
    color: #0098f7;
    padding: 10px;
    cursor: pointer;
    text-decoration: none;
    border: none;
    background: none;
    font-size: 16px;
    outline: none;
    -webkit-transition: background 0.2s ease 0s;
    -moz-transition: background 0.2s ease 0s;
    -ms-transition: background 0.2s ease 0s;
    -o-transition: background 0.2s ease 0s;
    border-radius: 2px;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    -moz-background-clip: padding;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
  }
  .fr-modal .fr-modal-wrapper div.fr-modal-body button.fr-command + button {
    margin-left: 24px;
  }
  .fr-modal .fr-modal-wrapper div.fr-modal-body button.fr-command:hover,
  .fr-modal .fr-modal-wrapper div.fr-modal-body button.fr-command:focus {
    background: #ebebeb;
    color: #0098f7;
  }
  .fr-modal .fr-modal-wrapper div.fr-modal-body button.fr-command:active {
    background: #d6d6d6;
    color: #0098f7;
  }
  .fr-modal .fr-modal-wrapper div.fr-modal-body button::-moz-focus-inner {
    border: 0;
  }

  .fr-desktop .fr-modal-wrapper .fr-modal-head i:hover {
    background: #ebebeb;
  }

  .fr-overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: #000;
    -webkit-opacity: 0.5;
    -moz-opacity: 0.5;
    opacity: 0.5;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    z-index: 2147483639;
  }

  .fr-popup {
    position: absolute;
    display: none;
    color: #222222;
    background: #fff;
    -webkit-box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2),
      0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12);
    -moz-box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2),
      0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12);
    box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2),
      0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12);
    font-family: Arial, Helvetica, sans-serif;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    user-select: none;
    -o-user-select: none;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    margin-top: 10px;
    z-index: 2147483635;
    text-align: left;
    border-radius: 6px;
    -moz-border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-background-clip: padding;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    text-rendering: optimizelegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    line-height: 1.2;
  }
  .fr-popup .fr-icon {
    text-align: center;
    vertical-align: middle;
    font-size: 20px;
    line-height: 1;
    cursor: pointer;
    font-weight: 400;
    box-sizing: content-box;
    padding: 6px;
  }
  .fr-popup .fr-icon-container {
    padding: 20px;
    max-height: 200px;
    overflow: auto;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }
  @supports not (-ms-high-contrast: none) {
    .fr-popup .fr-icon-container {
      grid-template-columns: repeat(auto-fill, minmax(36px, 36px));
      display: grid;
    }
  }
  @media (min-width: 768px) {
    .fr-popup .fr-icon-container {
      min-width: 276px;
    }
  }
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .fr-popup .fr-icon-container {
      display: inline-flex;
      -ms-flex-flow: row wrap;
    }
  }
  @media screen and (-ms-high-contrast: active) and (max-width: 768px),
    (-ms-high-contrast: none) and (max-width: 768px) {
    .fr-popup .fr-icon-container {
      width: 236px;
    }
  }
  .fr-popup .fr-input-focus {
    background: whitesmoke;
  }
  .fr-popup.fr-above {
    margin-top: -10px;
    border-top: 0;
  }
  .fr-popup.fr-active {
    display: block;
  }
  .fr-popup.fr-hidden {
    -webkit-opacity: 0;
    -moz-opacity: 0;
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  }
  .fr-popup.fr-empty {
    display: none !important;
  }
  .fr-popup .fr-hs {
    display: block !important;
  }
  .fr-popup .fr-hs.fr-hidden {
    display: none !important;
  }
  .fr-popup .fr-input-line {
    position: relative;
    padding: 15px 0;
  }
  .fr-popup .fr-input-line input[type="text"],
  .fr-popup .fr-input-line textarea {
    width: 100%;
    margin-bottom: 1px;
    border-radius: 2px;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    -moz-background-clip: padding;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    border: solid 1px #999999;
    color: #222222;
    font-size: 14px;
    background: #fff;
    position: relative;
    z-index: 2;
    padding: 4px 12px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-transition: border 0.5s, padding 0.5s;
    -moz-transition: border 0.5s, padding 0.5s;
    -ms-transition: border 0.5s, padding 0.5s;
    -o-transition: border 0.5s, padding 0.5s;
  }
  .fr-popup .fr-input-line input[type="text"]:hover,
  .fr-popup .fr-input-line textarea:hover {
    border: solid 1px #515151;
  }
  .fr-popup .fr-input-line input[type="text"]:focus,
  .fr-popup .fr-input-line textarea:focus {
    border: solid 2px #0098f7;
    padding: 3px 11px;
  }
  .fr-popup .fr-input-line input[type="text"] {
    height: 46px;
  }
  .fr-popup .fr-input-line input + label,
  .fr-popup .fr-input-line textarea + label {
    position: absolute;
    top: 29px;
    left: 12px;
    font-size: 14px;
    color: grey;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    z-index: 3;
    display: block;
    background: #fff;
    padding: 0;
    cursor: text;
  }
  .fr-popup .fr-input-line input.fr-not-empty + label,
  .fr-popup .fr-input-line textarea.fr-not-empty + label {
    color: gray;
    width: auto;
    left: 4px;
    padding: 0 4px;
    font-size: 11px;
    top: 9px;
  }
  .fr-popup input,
  .fr-popup textarea {
    user-select: text;
    -o-user-select: text;
    -moz-user-select: text;
    -khtml-user-select: text;
    -webkit-user-select: text;
    -ms-user-select: text;
    border-radius: 0;
    -moz-border-radius: 0;
    -webkit-border-radius: 0;
    -moz-background-clip: padding;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    outline: none;
  }
  .fr-popup textarea {
    resize: none;
  }
  .fr-popup .fr-buttons {
    white-space: nowrap;
    line-height: 0;
  }
  .fr-popup .fr-buttons .fr-btn {
    margin-left: 4px;
    margin-right: 4px;
    display: inline-block;
    float: none;
  }
  .fr-popup .fr-buttons .fr-btn i {
    float: left;
  }
  .fr-popup .fr-buttons .fr-separator {
    display: inline-block;
    float: none;
  }
  .fr-popup .fr-buttons.fr-tabs {
    border-radius: 6px 6px 0 0;
    -moz-border-radius: 6px 6px 0 0;
    -webkit-border-radius: 6px 6px 0 0;
    -moz-background-clip: padding;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    background-color: #f5f5f5;
    overflow: hidden;
  }
  @media (max-width: 768px) {
    .fr-popup .fr-buttons.fr-tabs.fr-tabs-scroll {
      overflow: scroll;
      overflow-y: hidden;
      width: 276px;
    }
  }
  .fr-popup .fr-buttons:not(.fr-tabs) {
    padding: 5px;
  }
  .fr-popup .fr-layer {
    border-radius: 6px;
    -moz-border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-background-clip: padding;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    width: 195px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    margin: 20px;
    display: none;
  }
  @media (min-width: 768px) {
    .fr-popup .fr-layer {
      width: 260px;
    }
  }
  .fr-popup .fr-layer.fr-active {
    display: inline-block;
  }
  .fr-popup .fr-action-buttons {
    z-index: 7;
    height: 36px;
    text-align: right;
  }
  .fr-popup .fr-action-buttons button.fr-command {
    height: 36px;
    line-height: 1;
    color: #0098f7;
    padding: 10px;
    cursor: pointer;
    text-decoration: none;
    border: none;
    background: none;
    font-size: 16px;
    outline: none;
    -webkit-transition: background 0.2s ease 0s;
    -moz-transition: background 0.2s ease 0s;
    -ms-transition: background 0.2s ease 0s;
    -o-transition: background 0.2s ease 0s;
    border-radius: 2px;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    -moz-background-clip: padding;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
  }
  .fr-popup .fr-action-buttons button.fr-command + button {
    margin-left: 24px;
  }
  .fr-popup .fr-action-buttons button.fr-command:hover,
  .fr-popup .fr-action-buttons button.fr-command:focus {
    background: #ebebeb;
    color: #0098f7;
  }
  .fr-popup .fr-action-buttons button.fr-command:active {
    background: #d6d6d6;
    color: #0098f7;
  }
  .fr-popup .fr-action-buttons button::-moz-focus-inner {
    border: 0;
  }
  .fr-popup .fr-checkbox {
    position: relative;
    display: inline-block;
    width: 18px;
    height: 18px;
    padding: 10px;
    border-radius: 100%;
    line-height: 1;
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
    vertical-align: middle;
  }
  .fr-popup .fr-checkbox svg {
    margin-left: 2px;
    margin-top: 2px;
    display: none;
    width: 10px;
    height: 10px;
  }
  .fr-popup .fr-checkbox span {
    border-radius: 2px;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    -moz-background-clip: padding;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    width: 18px;
    height: 18px;
    display: inline-block;
    position: relative;
    z-index: 1;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-transition: background 0.2s ease 0s, border-color 0.2s ease 0s;
    -moz-transition: background 0.2s ease 0s, border-color 0.2s ease 0s;
    -ms-transition: background 0.2s ease 0s, border-color 0.2s ease 0s;
    -o-transition: background 0.2s ease 0s, border-color 0.2s ease 0s;
  }
  .fr-popup .fr-checkbox input {
    position: absolute;
    z-index: 2;
    -webkit-opacity: 0;
    -moz-opacity: 0;
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    border: 0 none;
    cursor: pointer;
    height: 18px;
    margin: 0;
    padding: 0;
    width: 18px;
    top: 7px;
    left: 7px;
  }
  .fr-popup .fr-checkbox input:not(:checked) + span {
    border: solid 2px #999999;
  }
  .fr-popup .fr-checkbox input:not(:checked):active + span {
    background-color: #f5f5f5;
  }
  .fr-popup .fr-checkbox input:not(:checked):focus + span,
  .fr-popup .fr-checkbox input:not(:checked):hover + span {
    border-color: #515151;
  }
  .fr-popup .fr-checkbox input:checked + span {
    background: #0098f7;
    border: solid 2px #0098f7;
  }
  .fr-popup .fr-checkbox input:checked + span svg {
    display: block;
  }
  .fr-popup .fr-checkbox input:checked:active + span {
    background-color: #ecf5ff;
  }
  .fr-popup .fr-checkbox input:checked:focus + span,
  .fr-popup .fr-checkbox input:checked:hover + span {
    -webkit-opacity: 0.8;
    -moz-opacity: 0.8;
    opacity: 0.8;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  }
  .fr-popup .fr-checkbox-line {
    font-size: 14px;
    line-height: 1.4px;
    margin-top: 10px;
  }
  .fr-popup .fr-checkbox-line label {
    cursor: pointer;
    margin: 0 5px;
    vertical-align: middle;
  }
  .fr-popup.fr-rtl {
    direction: rtl;
    text-align: right;
  }
  .fr-popup.fr-rtl .fr-action-buttons {
    text-align: left;
  }
  .fr-popup.fr-rtl .fr-input-line input + label,
  .fr-popup.fr-rtl .fr-input-line textarea + label {
    left: auto;
    right: 0;
  }
  .fr-popup.fr-rtl .fr-buttons .fr-separator.fr-vs {
    float: right;
  }

  .fr-text-edit-layer {
    width: 250px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    display: block !important;
  }

  .fr-toolbar {
    color: #222222;
    background: #fff;
    position: relative;
    font-family: Arial, Helvetica, sans-serif;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    user-select: none;
    -o-user-select: none;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    border-radius: 2px;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    -moz-background-clip: padding;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    text-align: left;
    border: 1px solid #cccccc;
    text-rendering: optimizelegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    line-height: 1.2;
    padding-bottom: 0;
    -webkit-transition: padding-bottom 0.5s;
    -moz-transition: padding-bottom 0.5s;
    -ms-transition: padding-bottom 0.5s;
    -o-transition: padding-bottom 0.5s;
  }
  .fr-toolbar::after {
    clear: both;
    display: block;
    content: "";
    height: 0;
  }
  .fr-toolbar::after {
    height: 0;
  }
  .fr-toolbar .fr-newline {
    clear: both;
    display: block;
    content: "";
    margin-left: 9px;
    margin-right: 9px;
    background: #efefef;
    height: 1px;
    -webkit-transition: height 0.5s;
    -moz-transition: height 0.5s;
    -ms-transition: height 0.5s;
    -o-transition: height 0.5s;
  }
  .fr-toolbar.fr-toolbar-open {
    padding-bottom: 48px;
  }
  .fr-toolbar.fr-toolbar-open .fr-newline {
    height: 0;
  }
  .fr-toolbar .fr-float-right {
    float: right;
  }
  .fr-toolbar .fr-float-left {
    float: left;
  }
  .fr-toolbar .fr-more-toolbar {
    float: left;
    border-radius: 0;
    -moz-border-radius: 0;
    -webkit-border-radius: 0;
    -moz-background-clip: padding;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    background-color: #f5f5f5;
    -webkit-transition: height 0.5s;
    -moz-transition: height 0.5s;
    -ms-transition: height 0.5s;
    -o-transition: height 0.5s;
    height: 0;
    z-index: 2;
    overflow: hidden;
    position: absolute;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    width: 100%;
  }
  .fr-toolbar .fr-more-toolbar.fr-expanded {
    height: 48px;
  }
  .fr-toolbar .fr-more-toolbar.fr-overflow-visible {
    overflow: visible;
  }
  .fr-toolbar .fr-more-toolbar > .fr-command.fr-btn {
    margin-left: 4px;
    margin-right: 4px;
  }
  .fr-toolbar .fr-btn-grp {
    display: inline-block;
    margin: 0 17px 0 12px;
  }
  @media (max-width: 768px) {
    .fr-toolbar .fr-btn-grp {
      margin: 0 7px 0 6px;
    }
  }
  .fr-toolbar .fr-command.fr-btn.fr-open {
    margin-top: 10px;
    margin-bottom: -1px;
    border-radius: 4px 4px 0 0;
    -moz-border-radius: 4px 4px 0 0;
    -webkit-border-radius: 4px 4px 0 0;
    -moz-background-clip: padding;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
  }
  .fr-toolbar .fr-command.fr-btn.fr-open:not(:hover):not(:focus):not(:active) {
    background: #f5f5f5;
  }
  .fr-toolbar.fr-rtl {
    text-align: right;
  }
  .fr-toolbar.fr-inline {
    display: none;
    -webkit-box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2),
      0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12);
    -moz-box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2),
      0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12);
    box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2),
      0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12);
    border: 0;
    white-space: nowrap;
    position: absolute;
    margin-top: 5px;
    z-index: 2147483630;
  }
  .fr-toolbar.fr-inline.fr-above {
    margin-top: -10px;
    border-top: 0;
  }
  .fr-toolbar.fr-inline .fr-newline {
    height: 0;
  }
  .fr-toolbar.fr-top {
    top: 0;
    border-bottom: 0;
    border-radius: 10px 10px 0 0;
    -moz-border-radius: 10px 10px 0 0;
    -webkit-border-radius: 10px 10px 0 0;
    -moz-background-clip: padding;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
  }
  .fr-toolbar.fr-bottom {
    bottom: 0;
    border-top: 0;
    padding-bottom: 0;
    border-radius: 0 0 10px 10px;
    -moz-border-radius: 0 0 10px 10px;
    -webkit-border-radius: 0 0 10px 10px;
    -moz-background-clip: padding;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
  }
  .fr-toolbar.fr-bottom .fr-newline {
    padding-top: 0;
    -webkit-transition: padding-top 0.5s;
    -moz-transition: padding-top 0.5s;
    -ms-transition: padding-top 0.5s;
    -o-transition: padding-top 0.5s;
  }
  .fr-toolbar.fr-bottom.fr-toolbar-open .fr-newline {
    padding-top: 48px;
    background: #fff;
  }
  .fr-toolbar.fr-bottom .fr-command.fr-btn.fr-open {
    margin-top: -1px;
    margin-bottom: 10px;
    border-radius: 0 0 4px 4px;
    -moz-border-radius: 0 0 4px 4px;
    -webkit-border-radius: 0 0 4px 4px;
    -moz-background-clip: padding;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
  }
  .fr-toolbar.fr-sticky-on {
    border-radius: 0;
    -moz-border-radius: 0;
    -webkit-border-radius: 0;
    -moz-background-clip: padding;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
  }

  .fr-separator {
    background: #ebebeb;
    display: block;
    vertical-align: top;
    float: left;
  }
  .fr-separator + .fr-separator {
    display: none;
  }
  .fr-separator.fr-vs {
    height: 32px;
    width: 1px;
    margin: 8px 4px;
  }
  .fr-separator.fr-hs {
    clear: both;
    width: calc(100% - (2 * 4px));
    margin: 0 4px;
    height: 1px;
  }
  .fr-separator.fr-hidden {
    display: none !important;
  }

  .fr-rtl .fr-separator {
    float: right;
  }

  .fr-toolbar.fr-inline .fr-separator.fr-hs {
    float: none;
  }

  .fr-toolbar.fr-inline .fr-separator.fr-vs {
    float: none;
    display: inline-block;
  }

  .second-toolbar {
    border: 1px solid #cccccc;
    border-top: 0;
    background: #fff;
    line-height: 1.4;
    border-radius: 0 0 10px 10px;
    -moz-border-radius: 0 0 10px 10px;
    -webkit-border-radius: 0 0 10px 10px;
    -moz-background-clip: padding;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
  }
  .second-toolbar::after {
    clear: both;
    display: block;
    content: "";
    height: 0;
  }

  #logo {
    float: left;
    outline: none;
  }
  #logo > span {
    display: inline-block;
    float: left;
    font-family: sans-serif;
    padding: 11px 5px 10px 15px;
    font-size: 12px;
    font-weight: 500;
    color: #b1b2b7;
    -webkit-transition: color 0.3s;
    -moz-transition: color 0.3s;
    -ms-transition: color 0.3s;
    -o-transition: color 0.3s;
  }
  #logo > svg {
    height: 20px;
    width: 47px;
    margin: 7px 0;
    cursor: pointer;
  }
  #logo > svg * {
    -webkit-transition: fill 0.3s;
    -moz-transition: fill 0.3s;
    -ms-transition: fill 0.3s;
    -o-transition: fill 0.3s;
  }
  #logo:hover > span,
  #logo:focus > span {
    color: #0098f7;
  }
  #logo:hover > svg .fr-logo,
  #logo:focus > svg .fr-logo {
    fill: #0098f7;
  }

  .fr-visibility-helper {
    display: none;
    margin-left: 0 !important;
  }
  @media (min-width: 768px) {
    .fr-visibility-helper {
      margin-left: 1px !important;
    }
  }
  @media (min-width: 992px) {
    .fr-visibility-helper {
      margin-left: 2px !important;
    }
  }
  @media (min-width: 1200px) {
    .fr-visibility-helper {
      margin-left: 3px !important;
    }
  }

  .fr-opacity-0 {
    -webkit-opacity: 0;
    -moz-opacity: 0;
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  }

  .fr-box {
    position: relative;
  }

  /**
     * Postion sticky hacks.
     */
  .fr-sticky {
    position: -webkit-sticky;
    position: -moz-sticky;
    position: -ms-sticky;
    position: -o-sticky;
    position: sticky;
  }

  .fr-sticky-off {
    position: relative;
  }

  .fr-sticky-on {
    position: fixed;
    z-index: 10;
  }
  .fr-sticky-on.fr-sticky-ios {
    position: absolute;
    left: 0;
    right: 0;
    width: auto !important;
  }

  .fr-sticky-dummy {
    display: none;
  }

  .fr-sticky-on + .fr-sticky-dummy,
  .fr-sticky-box > .fr-sticky-dummy {
    display: block;
  }

  span.fr-sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
  }

  .clearfix::after {
    clear: both;
    display: block;
    content: "";
    height: 0;
  }

  .hide-by-clipping {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
  }

  .fr-box .fr-counter {
    padding: 10px;
    float: right;
    color: #999999;
    content: attr(data-chars);
    font-size: 14px;
    font-family: sans-serif;
    z-index: 1;
    border-radius: 2px 0 0 0;
    -moz-border-radius: 2px 0 0 0;
    -webkit-border-radius: 2px 0 0 0;
    -moz-background-clip: padding;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
  }
  .fr-box.fr-rtl .fr-counter {
    left: 0;
    right: auto;
    border-left: none;
    border-radius: 0 2px 0 0;
    -moz-border-radius: 0 2px 0 0;
    -webkit-border-radius: 0 2px 0 0;
    -moz-background-clip: padding;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
  }

  .fr-box.fr-code-view .fr-counter {
    display: none;
  }

  .clearfix::after {
    clear: both;
    display: block;
    content: "";
    height: 0;
  }

  .hide-by-clipping {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
  }

  textarea.fr-code {
    display: none;
    width: 100%;
    resize: none;
    -moz-resize: none;
    -webkit-resize: none;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    border: none;
    padding: 10px;
    margin: 0;
    font-family: "Courier New", monospace;
    font-size: 14px;
    background: #fff;
    color: #000;
    outline: none;
  }

  .fr-box.fr-rtl textarea.fr-code {
    direction: rtl;
  }

  .fr-box .CodeMirror {
    display: none;
  }

  .fr-box.fr-code-view textarea.fr-code {
    display: block;
  }
  .fr-box.fr-code-view .fr-element,
  .fr-box.fr-code-view .fr-placeholder,
  .fr-box.fr-code-view .fr-iframe {
    display: none;
  }
  .fr-box.fr-code-view .CodeMirror {
    display: block;
  }

  .fr-box.fr-inline.fr-code-view .fr-command.fr-btn.html-switch {
    display: block;
  }
  .fr-box.fr-inline .fr-command.fr-btn.html-switch {
    display: none;
    position: absolute;
    top: 0;
    right: 0;
    display: none;
    background: #fff;
    color: #333333;
    -moz-outline: 0;
    outline: 0;
    border: 0;
    line-height: 1;
    cursor: pointer;
    text-align: left;
    padding: 8px 7px;
    -webkit-transition: background 0.2s ease 0s;
    -moz-transition: background 0.2s ease 0s;
    -ms-transition: background 0.2s ease 0s;
    -o-transition: background 0.2s ease 0s;
    border-radius: 0;
    -moz-border-radius: 0;
    -webkit-border-radius: 0;
    -moz-background-clip: padding;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    z-index: 2;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    text-decoration: none;
    user-select: none;
    -o-user-select: none;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
  }
  .fr-box.fr-inline .fr-command.fr-btn.html-switch i {
    font-size: 24px;
    width: 24px;
    text-align: center;
  }
  .fr-box.fr-inline .fr-command.fr-btn.html-switch.fr-desktop:hover {
    background: #ebebeb;
  }

  .clearfix::after {
    clear: both;
    display: block;
    content: "";
    height: 0;
  }

  .hide-by-clipping {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
  }

  .fr-popup .fr-layer.fr-color-hex-layer {
    margin: 0;
    padding: 20px;
    padding-top: 0;
    float: left;
  }
  .fr-popup .fr-layer.fr-color-hex-layer .fr-input-line {
    float: left;
    width: calc(100% - 50px);
    padding: 15px 0 0;
  }
  .fr-popup .fr-layer.fr-color-hex-layer .fr-action-buttons {
    float: right;
    width: 38px;
    height: 40px;
    padding: 17px 0 0;
    margin: 0;
  }
  .fr-popup .fr-layer.fr-color-hex-layer .fr-action-buttons button.fr-command {
    border-radius: 2px;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    -moz-background-clip: padding;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    font-size: 13px;
    height: 40px;
    width: 38px;
  }
  .fr-popup .fr-separator + .fr-colors-tabs {
    margin-left: 2px;
    margin-right: 2px;
  }
  .fr-popup .fr-color-set {
    line-height: 0;
    display: none;
  }
  .fr-popup .fr-color-set.fr-selected-set {
    display: block;
    padding: 20px;
    padding-bottom: 0;
  }
  .fr-popup .fr-color-set > span {
    display: inline-block;
    width: 32px;
    height: 32px;
    position: relative;
    z-index: 1;
  }
  .fr-popup .fr-color-set > span > i,
  .fr-popup .fr-color-set > span > svg {
    text-align: center;
    line-height: 32px;
    height: 24px;
    width: 24px;
    margin: 4px;
    font-size: 13px;
    position: absolute;
    bottom: 0;
    cursor: default;
    left: 0;
  }
  .fr-popup .fr-color-set > span > i path,
  .fr-popup .fr-color-set > span > svg path {
    fill: #222222;
  }
  .fr-popup .fr-color-set > span .fr-selected-color {
    color: #fff;
    font-family: FontAwesome;
    font-size: 13px;
    font-weight: 400;
    line-height: 32px;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    text-align: center;
    cursor: default;
  }
  .fr-popup .fr-color-set > span:hover,
  .fr-popup .fr-color-set > span:focus {
    outline: 1px solid #222222;
    z-index: 2;
  }

  .fr-rtl
    .fr-popup
    .fr-colors-tabs
    .fr-colors-tab.fr-selected-tab[data-param1="text"]
    ~ [data-param1="background"]::after {
    -webkit-transform: translate3d(100%, 0, 0);
    -moz-transform: translate3d(100%, 0, 0);
    -ms-transform: translate3d(100%, 0, 0);
    -o-transform: translate3d(100%, 0, 0);
  }

  .clearfix::after {
    clear: both;
    display: block;
    content: "";
    height: 0;
  }

  .hide-by-clipping {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
  }

  .fr-drag-helper {
    background: #0098f7;
    height: 2px;
    margin-top: -1px;
    -webkit-opacity: 0.2;
    -moz-opacity: 0.2;
    opacity: 0.2;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    position: absolute;
    z-index: 2147483640;
    display: none;
  }
  .fr-drag-helper.fr-visible {
    display: block;
  }

  .fr-dragging {
    -webkit-opacity: 0.4;
    -moz-opacity: 0.4;
    opacity: 0.4;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  }

  .clearfix::after {
    clear: both;
    display: block;
    content: "";
    height: 0;
  }

  .hide-by-clipping {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
  }

  .fr-popup .fr-emoticon {
    width: 24px;
    height: 24px;
    font-family: "Apple Color Emoji", "Segoe UI Emoji", NotoColorEmoji,
      "Segoe UI Symbol", "Android Emoji", EmojiSymbols;
  }
  .fr-popup .fr-emoticon img {
    height: 24px;
    width: 24px;
  }
  .fr-popup .fr-command.fr-btn.fr-tabs-unicode {
    padding: 0 0 0 14px;
  }
  @media screen and (-ms-high-contrast: active) and (min-width: 768px),
    (-ms-high-contrast: none) and (min-width: 768px) {
    .fr-popup .fr-icon-container.fr-emoticon-container {
      width: 368px;
    }
  }

  .clearfix::after {
    clear: both;
    display: block;
    content: "";
    height: 0;
  }

  .hide-by-clipping {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
  }

  .fr-popup .fr-file-upload-layer {
    border: dashed 2px #bdbdbd;
    padding: 25px 0;
    margin: 20px;
    position: relative;
    font-size: 14px;
    letter-spacing: 1px;
    line-height: 140%;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    text-align: center;
  }
  .fr-popup .fr-file-upload-layer:hover {
    background: #ebebeb;
  }
  .fr-popup .fr-file-upload-layer.fr-drop {
    background: #ebebeb;
    border-color: #0098f7;
  }
  .fr-popup .fr-file-upload-layer .fr-form {
    -webkit-opacity: 0;
    -moz-opacity: 0;
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 2147483640;
    overflow: hidden;
    margin: 0 !important;
    padding: 0 !important;
    width: 100% !important;
  }
  .fr-popup .fr-file-upload-layer .fr-form input {
    cursor: pointer;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    width: 500%;
    height: 100%;
    margin: 0;
    font-size: 400px;
  }
  .fr-popup .fr-file-progress-bar-layer {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }
  .fr-popup .fr-file-progress-bar-layer > h3 {
    font-size: 16px;
    margin: 10px 0;
    font-weight: normal;
  }
  .fr-popup .fr-file-progress-bar-layer > div.fr-action-buttons {
    display: none;
  }
  .fr-popup .fr-file-progress-bar-layer > div.fr-loader {
    background: #b3e0fd;
    height: 10px;
    width: 100%;
    margin-top: 20px;
    overflow: hidden;
    position: relative;
  }
  .fr-popup .fr-file-progress-bar-layer > div.fr-loader span {
    display: block;
    height: 100%;
    width: 0%;
    background: #0098f7;
    -webkit-transition: width 0.2s ease 0s;
    -moz-transition: width 0.2s ease 0s;
    -ms-transition: width 0.2s ease 0s;
    -o-transition: width 0.2s ease 0s;
  }
  .fr-popup .fr-file-progress-bar-layer > div.fr-loader.fr-indeterminate span {
    width: 30% !important;
    position: absolute;
    top: 0;
    -webkit-animation: loading 2s linear infinite;
    -moz-animation: loading 2s linear infinite;
    -o-animation: loading 2s linear infinite;
    animation: loading 2s linear infinite;
  }
  .fr-popup .fr-file-progress-bar-layer.fr-error > div.fr-loader {
    display: none;
  }
  .fr-popup .fr-file-progress-bar-layer.fr-error > div.fr-action-buttons {
    display: block;
  }

  @keyframes loading {
    from {
      left: -25%;
    }
    to {
      left: 100%;
    }
  }
  @-webkit-keyframes loading {
    from {
      left: -25%;
    }
    to {
      left: 100%;
    }
  }
  @-moz-keyframes loading {
    from {
      left: -25%;
    }
    to {
      left: 100%;
    }
  }
  @-o-keyframes loading {
    from {
      left: -25%;
    }
    to {
      left: 100%;
    }
  }
  @keyframes loading {
    from {
      left: -25%;
    }
    to {
      left: 100%;
    }
  }
  .clearfix::after {
    clear: both;
    display: block;
    content: "";
    height: 0;
  }

  .hide-by-clipping {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
  }

  body.fr-fullscreen {
    overflow: hidden;
    height: 100%;
    width: 100%;
    position: fixed;
  }

  .fr-box.fr-fullscreen {
    margin: 0 !important;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 2147483630 !important;
    width: auto !important;
  }
  .fr-box.fr-fullscreen.fr-basic.fr-top .fr-wrapper {
    border-radius: 0;
    -moz-border-radius: 0;
    -webkit-border-radius: 0;
    -moz-background-clip: padding;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
  }
  .fr-box.fr-fullscreen.fr-basic.fr-bottom .fr-wrapper {
    border-radius: 0;
    -moz-border-radius: 0;
    -webkit-border-radius: 0;
    -moz-background-clip: padding;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
  }
  .fr-box.fr-fullscreen .fr-toolbar {
    border-radius: 0;
    -moz-border-radius: 0;
    -webkit-border-radius: 0;
    -moz-background-clip: padding;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
  }
  .fr-box.fr-fullscreen .fr-toolbar.fr-top {
    top: 0 !important;
  }
  .fr-box.fr-fullscreen .fr-toolbar.fr-bottom {
    bottom: 0 !important;
  }
  .fr-box.fr-fullscreen .second-toolbar {
    margin-top: 0;
    border-radius: 0;
    -moz-border-radius: 0;
    -webkit-border-radius: 0;
    -moz-background-clip: padding;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
  }

  .fr-fullscreen-wrapper {
    z-index: 2147483640 !important;
    width: 100% !important;
    margin: 0 !important;
    padding: 0 !important;
    overflow: visible !important;
  }

  .clearfix::after {
    clear: both;
    display: block;
    content: "";
    height: 0;
  }

  .hide-by-clipping {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
  }

  .fr-modal .fr-modal-wrapper .fr-modal-body .fr-help-modal {
    text-align: left;
    padding: 20px 20px 10px;
  }
  .fr-modal .fr-modal-wrapper .fr-modal-body .fr-help-modal table {
    border-collapse: collapse;
    font-size: 14px;
    line-height: 1.5;
    width: 100%;
  }
  .fr-modal .fr-modal-wrapper .fr-modal-body .fr-help-modal table + table {
    margin-top: 20px;
  }
  .fr-modal .fr-modal-wrapper .fr-modal-body .fr-help-modal table tr {
    border: 0;
  }
  .fr-modal .fr-modal-wrapper .fr-modal-body .fr-help-modal table th {
    text-align: left;
  }
  .fr-modal .fr-modal-wrapper .fr-modal-body .fr-help-modal table th,
  .fr-modal .fr-modal-wrapper .fr-modal-body .fr-help-modal table td {
    padding: 6px 0 4px;
  }
  .fr-modal .fr-modal-wrapper .fr-modal-body .fr-help-modal table tbody tr {
    border-bottom: solid 1px #ebebeb;
  }
  .fr-modal
    .fr-modal-wrapper
    .fr-modal-body
    .fr-help-modal
    table
    tbody
    td:first-child {
    width: 60%;
    color: #646464;
  }
  .fr-modal
    .fr-modal-wrapper
    .fr-modal-body
    .fr-help-modal
    table
    tbody
    td:nth-child(n + 2) {
    letter-spacing: 0.5px;
  }

  .clearfix::after {
    clear: both;
    display: block;
    content: "";
    height: 0;
  }

  .hide-by-clipping {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
  }

  .fr-element img {
    cursor: pointer;
    padding: 0 1px;
  }

  .fr-image-resizer {
    position: absolute;
    border: solid 1px #0098f7;
    display: none;
    user-select: none;
    -o-user-select: none;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
  }
  .fr-image-resizer.fr-active {
    display: block;
  }
  .fr-image-resizer .fr-handler {
    display: block;
    position: absolute;
    background: #0098f7;
    border: solid 1px #fff;
    border-radius: 2px;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    -moz-background-clip: padding;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    z-index: 4;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }
  .fr-image-resizer .fr-handler.fr-hnw {
    cursor: nw-resize;
  }
  .fr-image-resizer .fr-handler.fr-hne {
    cursor: ne-resize;
  }
  .fr-image-resizer .fr-handler.fr-hsw {
    cursor: sw-resize;
  }
  .fr-image-resizer .fr-handler.fr-hse {
    cursor: se-resize;
  }
  .fr-image-resizer .fr-handler {
    width: 12px;
    height: 12px;
  }
  .fr-image-resizer .fr-handler.fr-hnw {
    left: -6px;
    top: -6px;
  }
  .fr-image-resizer .fr-handler.fr-hne {
    right: -6px;
    top: -6px;
  }
  .fr-image-resizer .fr-handler.fr-hsw {
    left: -6px;
    bottom: -6px;
  }
  .fr-image-resizer .fr-handler.fr-hse {
    right: -6px;
    bottom: -6px;
  }
  @media (min-width: 1200px) {
    .fr-image-resizer .fr-handler {
      width: 10px;
      height: 10px;
    }
    .fr-image-resizer .fr-handler.fr-hnw {
      left: -5px;
      top: -5px;
    }
    .fr-image-resizer .fr-handler.fr-hne {
      right: -5px;
      top: -5px;
    }
    .fr-image-resizer .fr-handler.fr-hsw {
      left: -5px;
      bottom: -5px;
    }
    .fr-image-resizer .fr-handler.fr-hse {
      right: -5px;
      bottom: -5px;
    }
  }

  .fr-image-overlay {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 2147483640;
    display: none;
  }

  .fr-popup .fr-image-upload-layer {
    border: dashed 2px #bdbdbd;
    padding: 25px 0;
    margin: 20px;
    position: relative;
    font-size: 14px;
    letter-spacing: 1px;
    line-height: 140%;
    text-align: center;
  }
  .fr-popup .fr-image-upload-layer:hover {
    background: #ebebeb;
  }
  .fr-popup .fr-image-upload-layer.fr-drop {
    background: #ebebeb;
    border-color: #0098f7;
  }
  .fr-popup .fr-image-upload-layer .fr-form {
    -webkit-opacity: 0;
    -moz-opacity: 0;
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 2147483640;
    overflow: hidden;
    margin: 0 !important;
    padding: 0 !important;
    width: 100% !important;
  }
  .fr-popup .fr-image-upload-layer .fr-form input {
    cursor: pointer;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    width: 500%;
    height: 100%;
    margin: 0;
    font-size: 400px;
  }
  .fr-popup .fr-image-progress-bar-layer > h3 {
    font-size: 16px;
    margin: 10px 0;
    font-weight: normal;
  }
  .fr-popup .fr-image-progress-bar-layer > div.fr-action-buttons {
    display: none;
  }
  .fr-popup .fr-image-progress-bar-layer > div.fr-loader {
    background: #b3e0fd;
    height: 10px;
    width: 100%;
    margin-top: 20px;
    overflow: hidden;
    position: relative;
  }
  .fr-popup .fr-image-progress-bar-layer > div.fr-loader span {
    display: block;
    height: 100%;
    width: 0%;
    background: #0098f7;
    -webkit-transition: width 0.2s ease 0s;
    -moz-transition: width 0.2s ease 0s;
    -ms-transition: width 0.2s ease 0s;
    -o-transition: width 0.2s ease 0s;
  }
  .fr-popup .fr-image-progress-bar-layer > div.fr-loader.fr-indeterminate span {
    width: 30% !important;
    position: absolute;
    top: 0;
    -webkit-animation: loading 2s linear infinite;
    -moz-animation: loading 2s linear infinite;
    -o-animation: loading 2s linear infinite;
    animation: loading 2s linear infinite;
  }
  .fr-popup .fr-image-progress-bar-layer.fr-error > div.fr-loader {
    display: none;
  }
  .fr-popup .fr-image-progress-bar-layer.fr-error > div.fr-action-buttons {
    display: block;
  }

  .fr-image-size-layer .fr-image-group .fr-input-line {
    width: calc(50% - 5px);
    display: inline-block;
  }
  .fr-image-size-layer .fr-image-group .fr-input-line + .fr-input-line {
    margin-left: 10px;
  }

  .fr-uploading {
    -webkit-opacity: 0.4;
    -moz-opacity: 0.4;
    opacity: 0.4;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  }

  @-webkit-keyframes loading {
    from {
      left: -25%;
    }
    to {
      left: 100%;
    }
  }
  @-moz-keyframes loading {
    from {
      left: -25%;
    }
    to {
      left: 100%;
    }
  }
  @-o-keyframes loading {
    from {
      left: -25%;
    }
    to {
      left: 100%;
    }
  }
  @keyframes loading {
    from {
      left: -25%;
    }
    to {
      left: 100%;
    }
  }
  .clearfix::after {
    clear: both;
    display: block;
    content: "";
    height: 0;
  }

  .hide-by-clipping {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
  }

  .fr-modal .fr-modal-head .fr-modal-head-line::after {
    clear: both;
    display: block;
    content: "";
    height: 0;
  }
  .fr-modal .fr-modal-head .fr-modal-head-line .fr-modal-more {
    margin-top: 10px;
  }
  .fr-modal .fr-modal-head .fr-modal-head-line .fr-modal-more.fr-not-available {
    opacity: 0;
    width: 0;
    padding: 12px 0;
  }
  .fr-modal .fr-modal-head .fr-modal-tags {
    padding: 0 20px;
    display: none;
    text-align: left;
  }
  .fr-modal .fr-modal-head .fr-modal-tags a {
    display: inline-block;
    opacity: 0;
    padding: 6px 12px;
    margin: 8px 0 8px 8px;
    text-decoration: none;
    border-radius: 32px;
    -moz-border-radius: 32px;
    -webkit-border-radius: 32px;
    -moz-background-clip: padding;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    -webkit-transition: opacity 0.2s ease 0s, background 0.2s ease 0s;
    -moz-transition: opacity 0.2s ease 0s, background 0.2s ease 0s;
    -ms-transition: opacity 0.2s ease 0s, background 0.2s ease 0s;
    -o-transition: opacity 0.2s ease 0s, background 0.2s ease 0s;
    cursor: pointer;
    background-color: #f5f5f5;
  }
  .fr-modal .fr-modal-head .fr-modal-tags a:focus {
    outline: none;
    background-color: #ebebeb;
  }
  .fr-modal .fr-modal-head .fr-modal-tags a:hover {
    background-color: #ebebeb;
  }
  .fr-modal .fr-modal-head .fr-modal-tags a:active {
    background-color: #d6d6d6;
  }
  .fr-modal .fr-modal-head .fr-modal-tags a.fr-selected-tag {
    background-color: #ecf5ff;
    color: #0098f7;
  }
  .fr-modal .fr-modal-head .fr-modal-tags a.fr-selected-tag:focus {
    outline: none;
    background-color: #ebebeb;
  }
  .fr-modal .fr-modal-head .fr-modal-tags a.fr-selected-tag:hover {
    background-color: #ebebeb;
  }
  .fr-modal .fr-modal-head .fr-modal-tags a.fr-selected-tag:active {
    background-color: #d6d6d6;
  }

  .fr-show-tags .fr-modal-more svg path {
    fill: #0098f7;
  }

  div.fr-modal-body {
    -webkit-transition: background 0.2s ease 0s;
    -moz-transition: background 0.2s ease 0s;
    -ms-transition: background 0.2s ease 0s;
    -o-transition: background 0.2s ease 0s;
  }
  div.fr-modal-body .fr-preloader {
    display: block;
    margin: 50px auto;
  }
  div.fr-modal-body div.fr-image-list {
    text-align: center;
    margin: 0 20px;
    padding: 0;
  }
  div.fr-modal-body div.fr-image-list .fr-list-column {
    float: left;
    width: calc((100% - 20px) / 2);
  }
  @media (min-width: 768px) and (max-width: 1199px) {
    div.fr-modal-body div.fr-image-list .fr-list-column {
      width: calc((100% - 40px) / 3);
    }
  }
  @media (min-width: 1200px) {
    div.fr-modal-body div.fr-image-list .fr-list-column {
      width: calc((100% - 60px) / 4);
    }
  }
  div.fr-modal-body div.fr-image-list .fr-list-column + .fr-list-column {
    margin-left: 20px;
  }
  div.fr-modal-body div.fr-image-list div.fr-image-container {
    position: relative;
    width: 100%;
    display: block;
    border-radius: 2px;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    -moz-background-clip: padding;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    overflow: hidden;
  }
  div.fr-modal-body div.fr-image-list div.fr-image-container:first-child {
    margin-top: 20px;
  }
  div.fr-modal-body div.fr-image-list div.fr-image-container + div {
    margin-top: 20px;
  }
  div.fr-modal-body
    div.fr-image-list
    div.fr-image-container.fr-image-deleting::after {
    position: absolute;
    -webkit-opacity: 0.5;
    -moz-opacity: 0.5;
    opacity: 0.5;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    -webkit-transition: opacity 0.2s ease 0s;
    -moz-transition: opacity 0.2s ease 0s;
    -ms-transition: opacity 0.2s ease 0s;
    -o-transition: opacity 0.2s ease 0s;
    background: #000;
    content: "";
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 2;
  }
  div.fr-modal-body
    div.fr-image-list
    div.fr-image-container.fr-image-deleting::before {
    content: attr(data-deleting);
    color: #fff;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    position: absolute;
    z-index: 3;
    font-size: 15px;
    height: 20px;
  }
  div.fr-modal-body div.fr-image-list div.fr-image-container.fr-empty {
    height: 95px;
    background: #cccccc;
    z-index: 1;
  }
  div.fr-modal-body div.fr-image-list div.fr-image-container.fr-empty::after {
    position: absolute;
    margin: auto;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    content: attr(data-loading);
    display: inline-block;
    height: 20px;
  }
  div.fr-modal-body div.fr-image-list div.fr-image-container img {
    width: 100%;
    vertical-align: middle;
    position: relative;
    z-index: 2;
    -webkit-opacity: 1;
    -moz-opacity: 1;
    opacity: 1;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    -webkit-transition: opacity 0.2s ease 0s, filter 0.2s ease 0s;
    -moz-transition: opacity 0.2s ease 0s, filter 0.2s ease 0s;
    -ms-transition: opacity 0.2s ease 0s, filter 0.2s ease 0s;
    -o-transition: opacity 0.2s ease 0s, filter 0.2s ease 0s;
    -webkit-transform: translateZ(0);
    -moz-transform: translateZ(0);
    -ms-transform: translateZ(0);
    -o-transform: translateZ(0);
  }
  div.fr-modal-body
    div.fr-image-list
    div.fr-image-container.fr-mobile-selected
    img {
    -webkit-opacity: 0.75;
    -moz-opacity: 0.75;
    opacity: 0.75;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  }
  div.fr-modal-body
    div.fr-image-list
    div.fr-image-container.fr-mobile-selected
    .fr-delete-img,
  div.fr-modal-body
    div.fr-image-list
    div.fr-image-container.fr-mobile-selected
    .fr-insert-img {
    display: inline-block;
  }
  div.fr-modal-body div.fr-image-list div.fr-image-container .fr-delete-img,
  div.fr-modal-body div.fr-image-list div.fr-image-container .fr-insert-img {
    display: none;
    top: 50%;
    border-radius: 100%;
    -moz-border-radius: 100%;
    -webkit-border-radius: 100%;
    -moz-background-clip: padding;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    -webkit-transition: background 0.2s ease 0s, color 0.2s ease 0s;
    -moz-transition: background 0.2s ease 0s, color 0.2s ease 0s;
    -ms-transition: background 0.2s ease 0s, color 0.2s ease 0s;
    -o-transition: background 0.2s ease 0s, color 0.2s ease 0s;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    position: absolute;
    cursor: pointer;
    margin: 0;
    line-height: 40px;
    text-decoration: none;
    z-index: 3;
  }
  div.fr-modal-body div.fr-image-list div.fr-image-container .fr-delete-img {
    background: #b8312f;
    fill: #fff;
    padding: 8px;
    left: 50%;
    -webkit-transform: translateY(-50%) translateX(25%);
    -moz-transform: translateY(-50%) translateX(25%);
    -ms-transform: translateY(-50%) translateX(25%);
    -o-transform: translateY(-50%) translateX(25%);
  }
  div.fr-modal-body div.fr-image-list div.fr-image-container .fr-insert-img {
    background: #fff;
    fill: #0098f7;
    padding: 8px;
    left: 50%;
    -webkit-transform: translateY(-50%) translateX(-125%);
    -moz-transform: translateY(-50%) translateX(-125%);
    -ms-transform: translateY(-50%) translateX(-125%);
    -o-transform: translateY(-50%) translateX(-125%);
  }

  .fr-desktop
    .fr-modal-wrapper
    div.fr-modal-body
    div.fr-image-list
    div.fr-image-container:hover
    img {
    -webkit-opacity: 0.75;
    -moz-opacity: 0.75;
    opacity: 0.75;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  }
  .fr-desktop
    .fr-modal-wrapper
    div.fr-modal-body
    div.fr-image-list
    div.fr-image-container:hover
    .fr-delete-img,
  .fr-desktop
    .fr-modal-wrapper
    div.fr-modal-body
    div.fr-image-list
    div.fr-image-container:hover
    .fr-insert-img {
    display: inline-block;
    width: 40px;
    height: 40px;
  }
  .fr-desktop
    .fr-modal-wrapper
    div.fr-modal-body
    div.fr-image-list
    div.fr-image-container
    .fr-delete-img:hover {
    background: #bf4644;
    color: #fff;
  }
  .fr-desktop
    .fr-modal-wrapper
    div.fr-modal-body
    div.fr-image-list
    div.fr-image-container
    .fr-insert-img:hover {
    background: #ebebeb;
  }

  .clearfix::after {
    clear: both;
    display: block;
    content: "";
    height: 0;
  }

  .hide-by-clipping {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
  }

  .fr-line-breaker {
    cursor: text;
    border-top: 1px solid #0098f7;
    position: fixed;
    z-index: 2;
    display: none;
  }
  .fr-line-breaker.fr-visible {
    display: block;
  }
  .fr-line-breaker a.fr-floating-btn {
    position: absolute;
    left: calc(50% - (40px / 2));
    top: -20px;
  }
  .fr-line-breaker a.fr-floating-btn svg {
    margin: 8px;
    height: 24px;
    width: 24px;
  }

  .clearfix::after {
    clear: both;
    display: block;
    content: "";
    height: 0;
  }

  .hide-by-clipping {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
  }

  .fr-quick-insert {
    position: absolute;
    z-index: 2147483639;
    white-space: nowrap;
    padding-right: 10px;
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
  }
  .fr-quick-insert a.fr-floating-btn svg {
    margin: 8px;
    width: 24px;
    height: 24px;
  }
  .fr-quick-insert.fr-on a.fr-floating-btn svg {
    -webkit-transform: rotate(135deg);
    -moz-transform: rotate(135deg);
    -ms-transform: rotate(135deg);
    -o-transform: rotate(135deg);
  }
  .fr-quick-insert.fr-hidden {
    display: none;
  }

  .fr-qi-helper {
    position: absolute;
    z-index: 3;
    padding-left: 20px;
    white-space: nowrap;
  }
  .fr-qi-helper a.fr-btn.fr-floating-btn {
    text-align: center;
    padding: 6px 10px 10px 10px;
    display: inline-block;
    color: #222222;
    background: #fff;
    -webkit-opacity: 0;
    -moz-opacity: 0;
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    -webkit-transform: scale(0);
    -moz-transform: scale(0);
    -ms-transform: scale(0);
    -o-transform: scale(0);
  }
  .fr-qi-helper a.fr-btn.fr-floating-btn svg {
    fill: #222222;
  }
  .fr-qi-helper a.fr-btn.fr-floating-btn.fr-size-1 {
    -webkit-opacity: 1;
    -moz-opacity: 1;
    opacity: 1;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
  }

  .clearfix::after {
    clear: both;
    display: block;
    content: "";
    height: 0;
  }

  .hide-by-clipping {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
  }

  .fr-popup .fr-buttons.fr-tabs .fr-special-character-category {
    padding: 10px 15px;
  }
  .fr-popup .fr-buttons.fr-tabs .fr-special-character-category span {
    font-weight: normal;
    font-size: 16px;
  }
  .fr-popup .fr-special-character {
    width: 24px;
    height: 24px;
  }
  @media screen and (-ms-high-contrast: active) and (min-width: 768px),
    (-ms-high-contrast: none) and (min-width: 768px) {
    .fr-popup .fr-icon-container.fr-sc-container {
      width: 368px;
    }
  }

  .clearfix::after {
    clear: both;
    display: block;
    content: "";
    height: 0;
  }

  .hide-by-clipping {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
  }

  .fr-element table td.fr-selected-cell,
  .fr-element table th.fr-selected-cell {
    border: 1px double #0098f7;
  }
  .fr-element table tr {
    user-select: none;
    -o-user-select: none;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
  }
  .fr-element table td,
  .fr-element table th {
    user-select: text;
    -o-user-select: text;
    -moz-user-select: text;
    -khtml-user-select: text;
    -webkit-user-select: text;
    -ms-user-select: text;
  }
  .fr-element .fr-no-selection table td,
  .fr-element .fr-no-selection table th {
    user-select: none;
    -o-user-select: none;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
  }

  .fr-table-resizer {
    cursor: col-resize;
    position: absolute;
    z-index: 3;
    display: none;
  }
  .fr-table-resizer.fr-moving {
    z-index: 2;
  }
  .fr-table-resizer div {
    -webkit-opacity: 0;
    -moz-opacity: 0;
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    border-right: 1px solid #0098f7;
  }

  .fr-no-selection {
    user-select: none;
    -o-user-select: none;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
  }

  .fr-popup .fr-table-size {
    margin: 20px;
  }
  .fr-popup .fr-table-size .fr-table-size-info {
    text-align: center;
    font-size: 14px;
  }
  .fr-popup .fr-table-size .fr-select-table-size {
    line-height: 0;
    padding: 5px;
    white-space: nowrap;
  }
  .fr-popup .fr-table-size .fr-select-table-size > span {
    display: inline-block;
    padding: 0 4px 4px 0;
    background: transparent;
  }
  .fr-popup .fr-table-size .fr-select-table-size > span > span {
    display: inline-block;
    width: 18px;
    height: 18px;
    border: 1px solid #ddd;
  }
  .fr-popup .fr-table-size .fr-select-table-size > span.hover {
    background: transparent;
  }
  .fr-popup .fr-table-size .fr-select-table-size > span.hover > span {
    background: rgba(0, 152, 247, 0.3);
    border: solid 1px #0098f7;
  }
  .fr-popup .fr-table-size .fr-select-table-size .new-line::after {
    clear: both;
    display: block;
    content: "";
    height: 0;
  }
  .fr-popup.fr-above .fr-table-size .fr-select-table-size > span {
    display: inline-block !important;
  }
  .fr-popup .fr-table-colors {
    display: block;
    padding: 20px;
    padding-bottom: 0;
  }

  .fr-popup.fr-desktop .fr-table-size .fr-select-table-size > span > span {
    width: 12px;
    height: 12px;
  }

  .fr-insert-helper {
    position: absolute;
    z-index: 9999;
    white-space: nowrap;
  }

  .clearfix::after {
    clear: both;
    display: block;
    content: "";
    height: 0;
  }

  .hide-by-clipping {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
  }

  .fr-element .fr-video {
    user-select: none;
    -o-user-select: none;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
  }
  .fr-element .fr-video::after {
    position: absolute;
    content: "";
    z-index: 1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    cursor: pointer;
    display: block;
    background: rgba(0, 0, 0, 0);
  }
  .fr-element .fr-video.fr-active > * {
    z-index: 2;
    position: relative;
  }
  .fr-element .fr-video > * {
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
    max-width: 100%;
    border: none;
  }

  .fr-box .fr-video-resizer {
    position: absolute;
    border: solid 1px #0098f7;
    display: none;
    user-select: none;
    -o-user-select: none;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
  }
  .fr-box .fr-video-resizer.fr-active {
    display: block;
  }
  .fr-box .fr-video-resizer .fr-handler {
    display: block;
    position: absolute;
    background: #0098f7;
    border: solid 1px #fff;
    z-index: 4;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }
  .fr-box .fr-video-resizer .fr-handler.fr-hnw {
    cursor: nw-resize;
  }
  .fr-box .fr-video-resizer .fr-handler.fr-hne {
    cursor: ne-resize;
  }
  .fr-box .fr-video-resizer .fr-handler.fr-hsw {
    cursor: sw-resize;
  }
  .fr-box .fr-video-resizer .fr-handler.fr-hse {
    cursor: se-resize;
  }
  .fr-box .fr-video-resizer .fr-handler {
    width: 12px;
    height: 12px;
  }
  .fr-box .fr-video-resizer .fr-handler.fr-hnw {
    left: -6px;
    top: -6px;
  }
  .fr-box .fr-video-resizer .fr-handler.fr-hne {
    right: -6px;
    top: -6px;
  }
  .fr-box .fr-video-resizer .fr-handler.fr-hsw {
    left: -6px;
    bottom: -6px;
  }
  .fr-box .fr-video-resizer .fr-handler.fr-hse {
    right: -6px;
    bottom: -6px;
  }
  @media (min-width: 1200px) {
    .fr-box .fr-video-resizer .fr-handler {
      width: 10px;
      height: 10px;
    }
    .fr-box .fr-video-resizer .fr-handler.fr-hnw {
      left: -5px;
      top: -5px;
    }
    .fr-box .fr-video-resizer .fr-handler.fr-hne {
      right: -5px;
      top: -5px;
    }
    .fr-box .fr-video-resizer .fr-handler.fr-hsw {
      left: -5px;
      bottom: -5px;
    }
    .fr-box .fr-video-resizer .fr-handler.fr-hse {
      right: -5px;
      bottom: -5px;
    }
  }

  .fr-popup .fr-video-size-layer .fr-video-group .fr-input-line {
    width: calc(50% - 5px);
    display: inline-block;
  }
  .fr-popup
    .fr-video-size-layer
    .fr-video-group
    .fr-input-line
    + .fr-input-line {
    margin-left: 10px;
  }
  .fr-popup .fr-video-upload-layer {
    border: dashed 2px #bdbdbd;
    padding: 25px 0;
    margin: 20px;
    position: relative;
    font-size: 14px;
    letter-spacing: 1px;
    line-height: 140%;
    text-align: center;
  }
  .fr-popup .fr-video-upload-layer:hover {
    background: #ebebeb;
  }
  .fr-popup .fr-video-upload-layer.fr-drop {
    background: #ebebeb;
    border-color: #0098f7;
  }
  .fr-popup .fr-video-upload-layer .fr-form {
    -webkit-opacity: 0;
    -moz-opacity: 0;
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 2147483640;
    overflow: hidden;
    margin: 0 !important;
    padding: 0 !important;
    width: 100% !important;
  }
  .fr-popup .fr-video-upload-layer .fr-form input {
    cursor: pointer;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    width: 500%;
    height: 100%;
    margin: 0;
    font-size: 400px;
  }
  .fr-popup .fr-video-progress-bar-layer > h3 {
    font-size: 16px;
    margin: 10px 0;
    font-weight: normal;
  }
  .fr-popup .fr-video-progress-bar-layer > div.fr-action-buttons {
    display: none;
  }
  .fr-popup .fr-video-progress-bar-layer > div.fr-loader {
    background: #b3e0fd;
    height: 10px;
    width: 100%;
    margin-top: 20px;
    overflow: hidden;
    position: relative;
  }
  .fr-popup .fr-video-progress-bar-layer > div.fr-loader span {
    display: block;
    height: 100%;
    width: 0%;
    background: #0098f7;
    -webkit-transition: width 0.2s ease 0s;
    -moz-transition: width 0.2s ease 0s;
    -ms-transition: width 0.2s ease 0s;
    -o-transition: width 0.2s ease 0s;
  }
  .fr-popup .fr-video-progress-bar-layer > div.fr-loader.fr-indeterminate span {
    width: 30% !important;
    position: absolute;
    top: 0;
    -webkit-animation: loading 2s linear infinite;
    -moz-animation: loading 2s linear infinite;
    -o-animation: loading 2s linear infinite;
    animation: loading 2s linear infinite;
  }
  .fr-popup .fr-video-progress-bar-layer.fr-error > div.fr-loader {
    display: none;
  }
  .fr-popup .fr-video-progress-bar-layer.fr-error > div.fr-action-buttons {
    display: block;
  }

  .fr-video-overlay {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 2147483640;
    display: none;
  }

  .clearfix::after {
    clear: both;
    display: block;
    content: "";
    height: 0;
  }

  .hide-by-clipping {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
  }

  img.fr-rounded,
  .fr-img-caption.fr-rounded img {
    border-radius: 10px;
    -moz-border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-background-clip: padding;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
  }

  img.fr-bordered,
  .fr-img-caption.fr-bordered img {
    border: solid 5px #ccc;
  }

  img.fr-bordered {
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
  }

  .fr-img-caption.fr-bordered img {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }

  .fr-view {
    word-wrap: break-word;
  }
  .fr-view span[style~="color:"] a {
    color: inherit;
  }
  .fr-view strong {
    font-weight: 700;
  }
  .fr-view table {
    border: none;
    border-collapse: collapse;
    empty-cells: show;
    max-width: 100%;
  }
  .fr-view table td {
    min-width: 5px;
  }
  .fr-view table.fr-dashed-borders td,
  .fr-view table.fr-dashed-borders th {
    border-style: dashed;
  }
  .fr-view table.fr-alternate-rows tbody tr:nth-child(2n) {
    background: whitesmoke;
  }
  .fr-view table td,
  .fr-view table th {
    border: 1px solid #ddd;
  }
  .fr-view table td:empty,
  .fr-view table th:empty {
    height: 20px;
  }
  .fr-view table td.fr-highlighted,
  .fr-view table th.fr-highlighted {
    border: 1px double red;
  }
  .fr-view table td.fr-thick,
  .fr-view table th.fr-thick {
    border-width: 2px;
  }
  .fr-view table th {
    background: #ececec;
  }
  .fr-view hr {
    clear: both;
    user-select: none;
    -o-user-select: none;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    page-break-after: always;
  }
  .fr-view .fr-file {
    position: relative;
  }
  .fr-view .fr-file::after {
    position: relative;
    content: "\1F4CE";
    font-weight: normal;
  }
  .fr-view pre {
    white-space: pre-wrap;
    word-wrap: break-word;
    overflow: visible;
  }
  .fr-view[dir="rtl"] blockquote {
    border-left: none;
    border-right: solid 2px #5e35b1;
    margin-right: 0;
    padding-right: 5px;
    padding-left: 0;
  }
  .fr-view[dir="rtl"] blockquote blockquote {
    border-color: #00bcd4;
  }
  .fr-view[dir="rtl"] blockquote blockquote blockquote {
    border-color: #43a047;
  }
  .fr-view blockquote {
    border-left: solid 2px #5e35b1;
    margin-left: 0;
    padding-left: 5px;
    color: #5e35b1;
  }
  .fr-view blockquote blockquote {
    border-color: #00bcd4;
    color: #00bcd4;
  }
  .fr-view blockquote blockquote blockquote {
    border-color: #43a047;
    color: #43a047;
  }
  .fr-view span.fr-emoticon {
    font-weight: normal;
    font-family: "Apple Color Emoji", "Segoe UI Emoji", "NotoColorEmoji",
      "Segoe UI Symbol", "Android Emoji", "EmojiSymbols";
    display: inline;
    line-height: 0;
  }
  .fr-view span.fr-emoticon.fr-emoticon-img {
    background-repeat: no-repeat !important;
    font-size: inherit;
    height: 1em;
    width: 1em;
    min-height: 20px;
    min-width: 20px;
    display: inline-block;
    margin: -0.1em 0.1em 0.1em;
    line-height: 1;
    vertical-align: middle;
  }
  .fr-view .fr-text-gray {
    color: #aaa !important;
  }
  .fr-view .fr-text-bordered {
    border-top: solid 1px #222;
    border-bottom: solid 1px #222;
    padding: 10px 0;
  }
  .fr-view .fr-text-spaced {
    letter-spacing: 1px;
  }
  .fr-view .fr-text-uppercase {
    text-transform: uppercase;
  }
  .fr-view .fr-class-highlighted {
    background-color: #ffff00;
  }
  .fr-view .fr-class-code {
    border-color: #cccccc;
    border-radius: 2px;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    -moz-background-clip: padding;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    background: #f5f5f5;
    padding: 10px;
    font-family: "Courier New", Courier, monospace;
  }
  .fr-view .fr-class-transparency {
    opacity: 0.5;
  }
  .fr-view img {
    position: relative;
    max-width: 100%;
  }
  .fr-view img.fr-dib {
    margin: 5px auto;
    display: block;
    float: none;
    vertical-align: top;
  }
  .fr-view img.fr-dib.fr-fil {
    margin-left: 0;
    text-align: left;
  }
  .fr-view img.fr-dib.fr-fir {
    margin-right: 0;
    text-align: right;
  }
  .fr-view img.fr-dii {
    display: inline-block;
    float: none;
    vertical-align: bottom;
    margin-left: 5px;
    margin-right: 5px;
    max-width: calc(100% - (2 * 5px));
  }
  .fr-view img.fr-dii.fr-fil {
    float: left;
    margin: 5px 5px 5px 0;
    max-width: calc(100% - 5px);
  }
  .fr-view img.fr-dii.fr-fir {
    float: right;
    margin: 5px 0 5px 5px;
    max-width: calc(100% - 5px);
  }
  .fr-view span.fr-img-caption {
    position: relative;
    max-width: 100%;
  }
  .fr-view span.fr-img-caption.fr-dib {
    margin: 5px auto;
    display: block;
    float: none;
    vertical-align: top;
  }
  .fr-view span.fr-img-caption.fr-dib.fr-fil {
    margin-left: 0;
    text-align: left;
  }
  .fr-view span.fr-img-caption.fr-dib.fr-fir {
    margin-right: 0;
    text-align: right;
  }
  .fr-view span.fr-img-caption.fr-dii {
    display: inline-block;
    float: none;
    vertical-align: bottom;
    margin-left: 5px;
    margin-right: 5px;
    max-width: calc(100% - (2 * 5px));
  }
  .fr-view span.fr-img-caption.fr-dii.fr-fil {
    float: left;
    margin: 5px 5px 5px 0;
    max-width: calc(100% - 5px);
  }
  .fr-view span.fr-img-caption.fr-dii.fr-fir {
    float: right;
    margin: 5px 0 5px 5px;
    max-width: calc(100% - 5px);
  }
  .fr-view .fr-video {
    text-align: center;
    position: relative;
  }
  .fr-view .fr-video.fr-rv {
    padding-bottom: 56.25%;
    padding-top: 30px;
    height: 0;
    overflow: hidden;
  }
  .fr-view .fr-video.fr-rv > iframe,
  .fr-view .fr-video.fr-rv object,
  .fr-view .fr-video.fr-rv embed {
    position: absolute !important;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .fr-view .fr-video > * {
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
    max-width: 100%;
    border: none;
  }
  .fr-view .fr-video.fr-dvb {
    display: block;
    clear: both;
  }
  .fr-view .fr-video.fr-dvb.fr-fvl {
    text-align: left;
  }
  .fr-view .fr-video.fr-dvb.fr-fvr {
    text-align: right;
  }
  .fr-view .fr-video.fr-dvi {
    display: inline-block;
  }
  .fr-view .fr-video.fr-dvi.fr-fvl {
    float: left;
  }
  .fr-view .fr-video.fr-dvi.fr-fvr {
    float: right;
  }
  .fr-view a.fr-strong {
    font-weight: 700;
  }
  .fr-view a.fr-green {
    color: green;
  }
  .fr-view .fr-img-caption {
    text-align: center;
  }
  .fr-view .fr-img-caption .fr-img-wrap {
    padding: 0;
    display: inline-block;
    margin: auto;
    text-align: center;
    width: 100%;
  }
  .fr-view .fr-img-caption .fr-img-wrap img {
    display: block;
    margin: auto;
    width: 100%;
  }
  .fr-view .fr-img-caption .fr-img-wrap > span {
    margin: auto;
    display: block;
    padding: 5px 5px 10px;
    font-size: 14px;
    font-weight: initial;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-opacity: 0.9;
    -moz-opacity: 0.9;
    opacity: 0.9;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    width: 100%;
    text-align: center;
  }
  .fr-view button.fr-rounded,
  .fr-view input.fr-rounded,
  .fr-view textarea.fr-rounded {
    border-radius: 10px;
    -moz-border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-background-clip: padding;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
  }
  .fr-view button.fr-large,
  .fr-view input.fr-large,
  .fr-view textarea.fr-large {
    font-size: 24px;
  }

  /**
     * Image style.
     */
  a.fr-view.fr-strong {
    font-weight: 700;
  }
  a.fr-view.fr-green {
    color: green;
  }

  /**
     * Link style.
     */
  img.fr-view {
    position: relative;
    max-width: 100%;
  }
  img.fr-view.fr-dib {
    margin: 5px auto;
    display: block;
    float: none;
    vertical-align: top;
  }
  img.fr-view.fr-dib.fr-fil {
    margin-left: 0;
    text-align: left;
  }
  img.fr-view.fr-dib.fr-fir {
    margin-right: 0;
    text-align: right;
  }
  img.fr-view.fr-dii {
    display: inline-block;
    float: none;
    vertical-align: bottom;
    margin-left: 5px;
    margin-right: 5px;
    max-width: calc(100% - (2 * 5px));
  }
  img.fr-view.fr-dii.fr-fil {
    float: left;
    margin: 5px 5px 5px 0;
    max-width: calc(100% - 5px);
  }
  img.fr-view.fr-dii.fr-fir {
    float: right;
    margin: 5px 0 5px 5px;
    max-width: calc(100% - 5px);
  }

  span.fr-img-caption.fr-view {
    position: relative;
    max-width: 100%;
  }
  span.fr-img-caption.fr-view.fr-dib {
    margin: 5px auto;
    display: block;
    float: none;
    vertical-align: top;
  }
  span.fr-img-caption.fr-view.fr-dib.fr-fil {
    margin-left: 0;
    text-align: left;
  }
  span.fr-img-caption.fr-view.fr-dib.fr-fir {
    margin-right: 0;
    text-align: right;
  }
  span.fr-img-caption.fr-view.fr-dii {
    display: inline-block;
    float: none;
    vertical-align: bottom;
    margin-left: 5px;
    margin-right: 5px;
    max-width: calc(100% - (2 * 5px));
  }
  span.fr-img-caption.fr-view.fr-dii.fr-fil {
    float: left;
    margin: 5px 5px 5px 0;
    max-width: calc(100% - 5px);
  }
  span.fr-img-caption.fr-view.fr-dii.fr-fir {
    float: right;
    margin: 5px 0 5px 5px;
    max-width: calc(100% - 5px);
  }
}
