/* ==========================================================================
#MODAL
========================================================================== */

.modal-wrapper {
  background-color: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(6px);
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: $zIndexModal * 99999999;

  &.full {
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;

    @media #{$md-and-up} {
      display: none;
    }

    .wrapper-full-modal {
      max-width: rem-calc(375);
      width: 100%;
      height: rem-calc(400);
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .wrapper-full-modal__content {
        display: flex;
        justify-content: center;
        margin-top: $sizeFourtyEight;
      }
    }

    .modal-content {
      width: 100%;
      left: 0;
      // transform: translate(0%, -50%);
      margin: 0;

      .modal-body {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .headingH2 {
          margin-right: $sizeSixteen;
          margin-left: $sizeSixteen;
          @media #{$xs-and-up} {
            margin-right: 0;
            margin-left: 0;
          }
        }
      }
    }
  }
}

.zoom-images__tools {
  margin-bottom: $sizeFourty;
  width: 100%;
  display: flex;
  justify-content: flex-end;

  .zoom-images__tools-icon {
    margin-left: $sizeTwenty;
    cursor: pointer;
  }
}

.modal-content {
  // transform: translate(-50%, -50%);
  background-color: white;
  border-radius: $sizeTwelve;
  width: 100%;
  // position: fixed;
  // top: 50%;
  // left: 50%;
  overflow: hidden;

  @media #{$xs-and-up} {
    width: calc(100% - 32px);
    margin: 0 $sizeSixteen;
    // left: calc(50% - 16px);
  }

  @media #{$md-and-up} {
    width: rem-calc(720);
    left: 50%;
    margin: 0;
  }

  @media #{$lg-and-up} {
    left: 50%;
    margin: 0;
  }

  &--small {
    @media #{$md-and-up} {
      width: rem-calc(336) !important;
    }
  }

  &--middle {
    @media #{$md-and-up} {
      width: rem-calc(480) !important;
    }
  }

  &--big {
    @media #{$md-and-up} {
      width: rem-calc(720) !important;
    }
  }

  .title {
    color: $blueDark;
    font-size: $sizeSixteen;
    font-family: $fontAvertaBold;

    &.regular {
      font-family: $fontAvertaRegular;
    }
  }

  &-alert {
    border-radius: $sizeEight;
    padding-top: $sizeTwenty;
    text-align: center;
    width: rem-calc(400);

    .modal-body {
      overflow: initial;
    }

    .msg {
      border-bottom: rem-calc(1) solid $greyDarkMedium;
      padding-top: $sizeTen;
      padding-bottom: $sizeTwentyFour;

      &.bold {
        font-family: $fontAvertaBold;
      }
    }

    .btn-text {
      width: 100%;
      &:first-child {
        &.cancel {
          &.orange {
            color: $orange;
          }
        }
      }

      &:nth-child(2) {
        &.cancel {
          &.orange {
            color: $orange;
          }
        }
      }

      &:nth-child(3) {
        &.cancel {
          &.orange {
            color: $orange;
          }
        }
      }
    }
  }

  &-actionSheet {
    width: rem-calc(400);
    background-color: transparent;
    .modal-body {
      max-height: initial;
    }
  }

  &-notificationsModal {
    .quantity {
      font-size: $sizeFourty;
      line-height: $sizeFourtyTwo;
      font-family: $fontAvertaRegular;
      padding: 0 $sizeSixteen;
    }

    .subwrapper {
      padding: $sizeSixteen;

      &:last-child {
        border-top: rem-calc(1) solid $greyMedium;
      }

      .item {
        margin-bottom: $sizeSix;

        &.semi-bold {
          font-family: $fontAvertaSemiBold;
        }

        &.regular {
          font-family: $fontAvertaRegular;
        }
      }
    }

    .btn {
      margin: $sizeSixteen auto;
    }
  }

  &-zoom-images {
    max-height: rem-calc(750);

    .modal-body {
      height: rem-calc(650);
      max-height: inherit;

      &.height-auto {
        height: auto;
        max-height: rem-calc(650);
      }

      &.padding {
        padding: 0 $sizeSixteen $sizeSixteen $sizeTwentyFour;
      }
    }

    .slick-slider {
      height: rem-calc(650);
      height: 100%;
      margin-left: auto;
      margin-right: auto;

      .slick-list {
        height: 100%;
        .slick-track {
          height: 100%;

          .slick-slide {
            display: flex;
            justify-content: center;

            div {
              display: flex;
              justify-content: center;
              overflow: hidden;
            }

            .img-carousel {
              max-height: rem-calc(642);
              // height: auto;
              width: auto !important;
              padding: $sizeEight $sizeTen 0;
            }
          }
        }
      }
    }


    .zoom-images__wrapper {
      width: 100%;

      .zoom-images__wrapper-img {
        width: 100%;
        height: 100%;
        overflow: hidden;
      }
    }

    .img-carousel {
      width: 100%;
    }

    .slick-dots {
      button::before {
        color: white;
      }

      .slick-active {
        button::before {
          color: white;
        }
      }
    }
  }

  .wrapper-modal-button {
    width: 100%;
    display: flex;
    justify-content: space-around;

    &.has-separation {
      .btn-text:not(:last-child){
        border-right: 1px solid $greyDark;
      }
    }
  }

  .wrapper-form {
    padding-left: $sizeTwentyFour;
    padding-right: $sizeTwentyFour;
    padding-bottom: $sizeTwenty;
    position: relative;
    
    .container__btn {
      display: flex;
      justify-content: center;

      .btn {
        margin-left: $sizeTwelve;
        margin-right: $sizeTwelve;
      }
    }

    .buttons__file {
      display: flex;
      margin-bottom: rem-calc(25);

      .btn {
        min-width: $sizeFiftySix;
        margin: 0 0 0 $sizeTwelve;
        &:first-child {
          margin: 0;
        }
      }
    }
  }
}

.modal-header {
  display: flex;
  align-items: center;
  padding-top: $sizeTwentyEight;
  padding-bottom: $sizeTwentyFour;
  padding-left: $sizeSixteen;
  padding-right: $sizeSixteen;
  background-color: white;
  width: 100%;
  border-top-left-radius: $sizeTwelve;
  border-top-right-radius: $sizeTwelve;
  z-index: 200;

  &.padding-s {
    padding-top: $sizeTen;
    padding-bottom: 0;
  }

  .modal-header-icons {
    display: flex;
    justify-content: space-between;
    width: 100%;
    .header-icons {
      display: flex;
      align-items: center;
      line-height: 0;
    }
  }
}

.modal-body {
  transition: all 300ms ease-out;
  max-height: calc(75vh - 88px);
  overflow-y: auto;

  &.max-height-big {
    max-height: calc(90vh - 88px);
  }
  &.max-height-initial {
    max-height: calc(90vh - 88px);
    height: auto;

    .slick-slider {
      padding-bottom: rem-calc(24);
    }
  }



  .arrow-container-left {
    background-color: $white;
    width: rem-calc(36);
    height: rem-calc(36);
    border-radius: rem-calc(18);
    top: 50%;
    left: $sizeTwelve;
  }

  .arrow-container-right {
    background-color: $white;
    width: rem-calc(36);
    height: rem-calc(36);
    border-radius: rem-calc(18);
    top: 50%;
    right: $sizeTwelve;
  }

  .main-wrapper {
    .contact {
      display: flex;
      align-items: center;
    }

    .wrapper-search {
      margin-top: 0;
    }
  }
}
