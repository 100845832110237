.wrapper-icon {
  width  : $sizeThirtySix;
  height : $sizeThirtySix;
  position: relative;
  @include flex-column-total-centered;

  &.small {
    width: $sizeTwentyFour;
    height: $sizeTwentyFour;
  }

  &.big {
    width  : rem-calc(50);
    height : rem-calc(50);
  }

  &.logo {
    width: 100%;
    height: 100%;
    // width: rem-calc(125);
    // height: $sizeFourtyEight;
  }


  &.logo-small {
    width: $sizeNinetySix;
    height: rem-calc(31);
  }

  &.logo-big {
    width: rem-calc(160);
    height: $sizeFiftyTwo;
  }

  &.rotate {
    transform: rotate(180deg);
  }

  &.opacity {
    opacity: 0.4;
  }

}

.icon-centered {
    display: flex;
    justify-content: center;
    align-items: center;
}

.wrapper-icon-on-input {
  width  : $sizeTwentyFour;
  height : $sizeTwentyFour;
  @include absolute($sizeFive, initial, initial, $sizeSix);
}
