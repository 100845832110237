.wrapper-lineGraphic {
  max-width: rem-calc(800);
  margin-right: auto;
  margin-left: auto;
  margin-bottom: $sizeFourtyTwo;

  .wrapper-text {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    margin-top: $sizeTwentyFour;
    height: rem-calc(42);
  }
}

