.section-item-texts {
  padding-top: $sizeEight;
  padding-left: $sizeSixteen;

  .title {
    color: $blueDark;
    line-height: $sizeTwentyFour;
  }
}

.checked {
  position: absolute;
  right: $sizeTwenty;
  top: 50%;
  transform: translateY(-50%);
}

.wrapper-item {
  display: flex;
  padding: $sizeTen;

  .section-item-texts {
    padding-top: 0;
  }
}
