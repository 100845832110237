.wrapper-badge {
    background-color: $blueLight;
    border-radius: 50%;   
    width: $sizeTwelve;     
    height: $sizeTwelve;
    @include flex-column-total-centered;
    @include absolute(0, 0, initial, initial);
    z-index: 1;  

    .number { 
        color : $greyLighter; 
        font-size: $sizeEight;
    }    
}


