.container-box-link {
  display: flex;
  flex-wrap: wrap;
  
}

.box-link {
  background-color: white;
  width: calc((100% - 32px) / 3);
  display: flex;
  padding: 10px;
  margin-bottom: $sizeEighteen;
  color: $orange;
  cursor: pointer;

  &:not(:nth-child(3n)) {
    margin-right: $sizeSixteen;
  }

  .container-add--icon {
    background-color: $blueLight;
    width: $sizeTwenty;
    height: $sizeTwenty;
    border-radius: $sizeTwenty;
    display: flex;
    align-items: center;
    margin-right: $sizeTen;
  }

  .container-add--text {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

}
