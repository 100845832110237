// body .wrapper-main {
//   padding: 0;
// }
// .main-wrapper.ownership {
//   padding: 0 !important;
// }
.c-gojs {
  display: flex;
  position: relative;
  $gojs: &;

  &__cover {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 9;
  }

  &__diagram-message,
  &__diagram {
    transition: all 300ms ease-in-out;
    background-color: $greyLight;
    border-radius: rem-calc(12);
    width: 100%;
    min-height: rem-calc(500);
    height: 100%;
    overflow: hidden;
    #{$gojs}.is-mobile & {
      min-height: rem-calc(200);
      max-height: rem-calc(200);
    }
  }

  &__diagram-message {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 3;
    &-text {
      width: rem-calc(512);
      text-align: center;
      position: relative;
      z-index: 2;
    }
    &-image {
      position: absolute;
      width: max-content;
      filter: blur(12px);
      opacity: 0.5;
    }
  }

  &__palette {
    &-tabs {
      background-color: $greyLight;
      transition: all 300ms ease-in-out;
      display: flex;
      flex-direction: column;
      width: 0vw;
      height: 100%;
      overflow: hidden;
      /*position: absolute;
      right: 0;
      z-index: 2;

      &::before {
        content: "";
        display: block;
        width: rem-calc(32);
        height: inherit;
        background-color: white;
        position: absolute;
        left: 0;
        top: 0;
      }
      &::after {
        border-top-right-radius: rem-calc(12);
        border-bottom-right-radius: rem-calc(12);
        content: "";
        display: block;
        width: rem-calc(20);
        height: inherit;
        background-color: $greyLight;
        position: absolute;
        left: 0;
        top: 0;
      }
      */
      &--item {
        transition: all 300ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
        cursor: pointer;
        width: 100%;
        height: rem-calc(36);
        max-height: rem-calc(200);
        margin-bottom: rem-calc(8);
        overflow: hidden;
        $item: &;
        &::before {
          background-color: $blueMedium;
          border-radius: rem-calc(12);
          color: white;
          content: attr(data-tab-name);
          width: 100%;
          height: rem-calc(36);
          display: flex;
          align-items: center;
          justify-content: center;
        }
        &-canvas {
          transition: all 300ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
          width: 100%;
          height: calc(100% - 36px);
          #{$item}.is-active & {
            opacity: 1;
          }
        }
      }
    }
  }

  &__buttons-wrapper {
    height: rem-calc(36);
    display: flex;
    justify-content: space-between;
    margin-bottom: $sizeTwelve;
    position: relative;
  }

  &__buttons-dropdown {
    transition: all 250ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
    background-color: $white;
    border-radius: 0 0 0 rem-calc(20);
    display: flex;
    gap: rem-calc(14);
    flex-direction: column;
    position: absolute;
    top: rem-calc(48);
    right: calc(-100% - 1.25rem * 2);
    z-index: 9;
    padding: rem-calc(20);
    &.is-active {
      right: 0;
    }
    #{$gojs}__button {
      background-color: $blueDark;
      margin-left: 0;
    }
  }

  // &__button {
  //   transition: all 250ms ease-in-out;
  //   background-color: $orange;
  //   border-radius: $sizeEighteen;
  //   color: white;
  //   cursor: pointer;
  //   width: rem-calc(36);
  //   height: rem-calc(36);
  //   display: flex;
  //   justify-content: center;
  //   align-items: center;
  //   margin-left: $sizeTwelve;
  //   &[disabled],
  //   &.is-disabled {
  //     background-color: $greyDarkMedium;
  //     opacity: 0.15;
  //   }
  // }

  &__modal-info {
    height: rem-calc(600);
    &-body {
      height: calc(100% - 88px);
      max-height: initial;
      overflow: visible;
    }
    &-tabs {
      overflow-x: hidden;
      &-content {
        height: inherit;
      }
      // &-arrow-right {
      //   right: rem-calc(8);
      // }
      // &-arrow-left {
      //   left: rem-calc(8);
      // }
      &-wrapper {
        margin-bottom: 0;
      }
      &-slide {
        display: flex !important; // override slider library style
        flex-direction: column;
        gap: rem-calc(24);
        padding: 0 rem-calc(24) rem-calc(24);
        img {
          width: max-content;
          height: max-content;
          margin: 0 auto;
        }
      }
    }

    &-text {
      display: flex;
      margin-bottom: rem-calc(24);
      strong {
        display: contents;
      }
    }
  }

  &__layout-switch {
    border: 1px solid $orange;
    border-radius: rem-calc(18);
    cursor: pointer;
    width: rem-calc(118);
    height: rem-calc(32);
    display: flex;
    // gap: rem-calc(16);
    justify-content: space-between;
    align-items: center;
    position: relative;
    $switch: &;
    &.is-active {
      &:before {
        left: -1px;
      }
    }
    &:before {
      transition: all 300ms ease-in-out;
      content: "";
      background-color: $blueDark;
      width:55%;
      height: 100%;
      border-radius: rem-calc(18);
      position: absolute;
      top: 0;
      left: 45.5%;
    }
    &-wrapper {
      display: flex;
      align-items: center;
      gap: rem-calc(8);
    }
    &-label {
      display: block;
    }
    &-text {
      transition: all 300ms ease-in-out;
      color: $orange;
      text-align: center;
      font-size: rem-calc(10); 
      font-family: $fontAvertaSemiBold;
      z-index: 1;
      width: 50%;
    }
  }

}
