.wrapper-multiple-select {
  cursor: pointer;
  width: 100%;
  position: relative;

  &.grey {
    .container-multiple-select,
    .options-multiple-select {
      border: 0;
      background-color: $greyLight;
    }
  }

  &.no-border {
    .container-multiple-select {
      border: 0;
      border-radius: 0;
    }
  }

  &.zIndex {
    z-index: 1;
  }

  .has-tooltip {
    display: flex;
    align-items: center;
  }

  .label-multiple-select {
    font-size: $sizeTwelve;
    color: $greyDark;
    font-family: $fontAvertaRegular;

    .asterisk {
      color: $red;
      font-size: $sizeFourteen;
      margin-left: rem-calc(4);
    }
  }
  .container-multiple-select {
    margin-top: rem-calc(2);
    position: relative;
    width: 100%;
    height: $sizeThirtySix;
    background-color: white;
    border-radius: $sizeEighteen;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: $sizeSixteen;
    padding-right: $sizeSixteen;
    border: rem-calc(2) solid $greyMedium;
    color: $blueDark;

    &.open {
      & ~ .options-multiple-select {
        padding-top: $sizeThirtyTwo;
        z-index: 4;
        visibility: visible;
      }

      //z-index: 10;
    }

    .icons-multiple-select {
      display: flex;
      flex-direction: column;
      transform: rotate(0deg);
      transition: all linear 0.2s;
      margin-left: $sizeTen;

      .icon {
        display: flex;
      }
      &.rotate {
        transform: rotate(180deg);
      }
    }
  }

  .options-multiple-select {
    transition: all linear 0.1s;
    background-color: white;
    border: rem-calc(2) solid $greyMedium;
    border-bottom-right-radius: $sizeEight;
    border-bottom-left-radius: $sizeEight;
    width: 100%;
    height: 0;
    max-height: rem-calc(217);
    padding-top: 0;
    padding-left: $sizeEight;
    padding-bottom: $sizeEight;
    visibility: hidden;
    overflow-x: hidden;
    overflow-y: auto;
    position: absolute;
    top: rem-calc(34);
    left: 0;
    z-index: -1;

    &.top {
      top: $sizeFourteen;
    }

    &.scroll {
      overflow: auto;
      overflow-y: scroll;
      width: calc(100% - 1px);
    }

    &.with-helper {
      width: calc(100% - 48px);
    }
    &.no-absolute {
     position: relative;
     top: rem-calc(-20);
    }

    &::-webkit-scrollbar {
      -webkit-appearance: none;
      width: 3px;
      color: $greyDark;
      background-color: #f5f5f7;
      opacity: 1;
      visibility: visible;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 4px;
      background-color: $greyDark;
    }

    &__input-container {
      display: flex;
    }

    &__input {
      background-color: transparent;
      height: rem-calc(36);
      &[disabled] {
        pointer-events: none;
      }
      &.is-active {
        background-color: $greyMedium;
      }
    }

    &__icon {
      background-color: transparent;
      pointer-events: none;
    }

    .option {
      padding: rem-calc(9);
      display: flex;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;
      color: $blueDark;
      font-size: $sizeFourteen;

      &.selected {
        color: #005774;
        font-weight: bold;
      }
    }

    .options-multiple-select-btn {
      position: absolute;
      width: 100%;
      height: auto;
      bottom: 0;

      .btn {
        position: fixed;
      }
    }
  }
}
