.accordion {

  $b : &;

  &__list {
    position: relative;

    &.is-open {
      #{$b}__list-item {
        height: rem-calc(80);
      }

      #{$b}__list-icon {
        transform: rotate(180deg);
      }
    }
  }

  &__list-title {
    height: $sizeFourtyTwo;
    padding: $sizeTen;
    border-radius: $sizeTwentyFour;
    background-color: $greyLight;
    margin-bottom: $sizeEighteen;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;

    
  }

  &__list-icon {
    transition: all linear .2s;
    pointer-events: none;
  }

  &__list-item {
    transition: all linear 0.2s;
    height: 0;
    overflow: hidden;
    padding: 0 $sizeSixteen;
   
  }
}

