.balance {
  display: flex;
  flex-direction: column;

  .balance-show-title {
    display: block;
    padding-top: $sizeTwentyFour;
    padding-bottom: $sizeEighteen;
  }

  &.balance-show {
    .wrapper-pieGraphic-content {
      @media #{$lg-and-up} {
        display: flex;
        justify-content: space-between;
      }
    }

    .wrapper-pieGraphic-legend {
      @media #{$lg-and-up} {
        width: rem-calc(450);
        padding-top: 0;
        padding-left: $sizeFourtyEight;
      }

      .wrapper-pieGraphic-legend-item {
        width: 100%;
        position: inherit;
        padding: 0;
        padding-left: $sizeTen;

        .title2 {
          max-width: rem-calc(220);
        }
      }
    }

    .wrapper-main-legend-text {
      align-items: center;
    }

    .main-legend-text {
      padding-left: 0;
    }

    .container-btn {
      width: 100%;
      margin-top: $sizeFourty;
    }
  }

  &.balance-new,
  &.balance-edit {
    .main-wrapper {
      border-left: rem-calc(1) solid $greyLighter;
      border-right: rem-calc(1) solid $greyLighter;
      padding: $sizeThirty $sizeFourty;
    }
  }
}
