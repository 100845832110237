.dashboard {

  overflow: hidden;

  &.is-blur {

    .no-blur {
      filter: blur(0px) !important;
      &:before {
        opacity: 0;
        pointer-events: none;
      }
      * {
        filter: blur(0px) !important;
      }
    }

    .wealth-map__wrapper {
      position: relative;
      &:before {
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        backdrop-filter: blur(5px);
        background-color: rgba(255, 255, 255, 0.5);
        position: absolute;
        top: 0;
        left: 0;
        z-index: 6;
      }
    }

    .c-table-dashboard {
      .c-table-dashboard__body {
        .c-table-dashboard__cell {
          span {
            filter: blur(4px);
            cursor: pointer;
            > * {
              pointer-events: none;
              filter: blur(0px);
            }
          }
        }
      }
    }

    .c-charts {
      text {
        filter: blur(8px);
        pointer-events: none;
        > * {
          filter: blur(0px);
          pointer-events: none;
          }
      }
    }

    .c-dashboard-hero__financial-number {
      cursor: pointer;
      filter: blur(8px);
    }

    .wrapper-barsGraphic {
      text ,
      .text-total {
        filter: blur(8px);
        cursor: pointer;
        > * {
          filter: blur(0px);
          pointer-events: none;
        }
      }
    }

    .c-table-recent-orders {
      p {
        filter: blur(8px);
        cursor: pointer;
        > * {
          filter: blur(0px);
          pointer-events: none;
        }
      }
    }

    .nestedlist-ownership {
      p {
        filter: blur(8px);
        cursor: pointer;
        > * {
          filter: blur(0px);
          pointer-events: none;
        }
      }
    }

    .c-table-dashboard__loaded-items {
      strong {
        filter: blur(8px);
        cursor: pointer;
      }
    }

    .table-preheader {
      p {
        filter: blur(8px);
        cursor: pointer;
        > * {
          filter: blur(0px);
          pointer-events: none;
        }
      }
    }
  }
}
