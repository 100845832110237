.wrapper-link-block {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: rem-calc(1) dashed $greyLight;
  min-height: $sizeSeventyTwo;
  padding: $sizeTen $sizeSixteen;
  width: 100%;
  cursor: pointer;
  .balance {
    min-width: $sizeNinetySix;
    text-align: end;
  }

  .date {
    color: $greyDark;
    font-family: $fontAvertaRegular;
    font-size: $sizeFourteen;
    margin-right: $sizeTen;

    &.big {
      min-width: $sizeSeventySix;
    }
  }

  &.new-bulltick-training {
    padding-left: $sizeFourty;
  }

  &.last-activities {
    
    .date {
      margin-right: $sizeEight;
    }
  }

  &.operations {
    .subwrapper-link-block {
      padding-left: $sizeThirtyTwo;
    }
  }
}

.wrapper-link-block__inline {
  border-bottom: 0;
  display: inline-flex;
  width: 50%;
  min-height: initial;

  &.no-padding{
    padding: 0;
  }
}



.subwrapper-link-block {
  display: flex;
  flex: 1;
  margin-left: $sizeTwentyEight;
  align-items: center;
}

.wrapper-description {
  text-align: start;
  display: flex;

  .text {
    color: $greyDark;
    font-family: $fontAvertaRegular;
    font-size: $sizeFourteen;
  }
}

.description-icon {
  padding-right: $sizeSixteen;
}
