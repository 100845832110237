  
.wrapper-loading {
  background-color: rgba($greyLighter, .3);
  backdrop-filter: blur(6px);
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: $zIndexFixed;
  @include flex-row-total-centered;

  &--simple {
    @include flex-row-total-centered;
  }
}

