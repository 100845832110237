.section-list-list {
  width: 100%;
  //@warn: $greyLighter;

  .section-list-item {
    cursor: pointer;
    &:first-child {
      .item-title {
        margin: 0;
      }
    }
  }

  .item-content {
    display: flex;
    padding: $sizeTwelve $sizeSixteen;
  }
}

.section-list-item {
  .item-content {
    display: flex;
    padding: $sizeTwelve $sizeSixteen;
    background-color: #fff;
    position: relative;
    cursor: pointer;

    &.border {
      border-bottom: $sizeFour solid $greyLight;
    }

    &:last-child{
      border-bottom: none;
    }
    
  }

  .item-title {
    background-color: $greyLight;
    height: $sizeFourtyTwo;
    display: flex;
    align-items: center;
    padding-left: $sizeTen;
    margin-top: $sizeFour;
    border-radius: $sizeTwentyFour;

    &.hidden {
      display: none;
    }
  }
}
