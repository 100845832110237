.block__empty-search{
  margin-top: $sizeTwenty;
  display: flex;
  flex-direction: column;
  align-items: center;

  .block_empty-search--text {
    margin-top: $sizeTen;
    font-family: $fontAvertaSemiBold;
    text-align: center;
  }
}