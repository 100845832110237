.list {
  width: 100%;

  .itemList-wrapper {
    display: flex;
    justify-content: space-between;
    width: 100%;
    cursor: pointer;
  }

  .itemList-subwrapper {
    display: flex;
    align-items: center;

    .titles {
      max-width: rem-calc(500);
    }
  }

  .itemList-color-box {
    height: $sizeFourtyEight;
    width: $sizeEight;
    margin-right: $sizeEight;

    &.bg-color-blue {
       background-color: $blueLight;
    }

    &.bg-color-red {
      background-color: $red;
    }
  }

  .level {
    padding-left: $sizeTwenty;
  }

}
